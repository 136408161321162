import rulesConstants from "@/assets/constants/rules.constants";
import { doc, getDoc } from "firebase/firestore";
import Vue from "vue";
import { auth, colUsers, platforms } from "./firebase.utils";
import { useGtm } from "@gtm-support/vue2-gtm";

const moment = require("moment");
export const getDate = (date = new Date()) => {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss");
};

export const formatDate = (date) => {
  return moment(date).format("DD MMM YY h:mm A");
};

export const formatDateDDMMYYYY = (date = new Date()) => {
  if(date.seconds) {
    return moment(date.seconds * 1000).format("DD MMM YYYY");
  }
  return moment(date).format("DD MMM YYYY");
};

export const connectedHandle = async (platform, wid,uid) => {

  if(!uid){
    uid = auth?.currentUser?.uid
  }
  const userDocRef = doc(colUsers, uid , "fly_workspaces", wid);
  // Fetch data from connected-apps-token
  let data = (
    await getDoc(doc(userDocRef, "connected-apps-token", platform))
  ).data();
  let expiryDate = null;
  let autoRefreshToken = null;
  const platformRef = doc(platforms, platform);
  const fly_platform = (await getDoc(platformRef)).data();

  if (fly_platform && fly_platform.auto_refresh_token !== undefined) {
    autoRefreshToken = fly_platform.auto_refresh_token;
  }
  if (data && platform !== "google_business_profile") {
    if (data.expires_at) {
      if (typeof data.expires_at === "string") {
        return null;
      } else {
        let expires_at = data.expires_at.toDate();
        expiryDate = moment(expires_at).format("LL");
        if (autoRefreshToken === true) {
          return null;
        } else return expiryDate;
      }
    }
  }
  return expiryDate; // Don't show otherwise
};

export function getRules(rules) {
  if (!rules) return [];
  rules = rules.split("|");
  let list = [];
  rules.forEach((rule) => {
    if (rulesConstants[rule]) list.push(rulesConstants[rule]);
  });
  return list;
}

export const mapDocs = (docs) => {
  return docs.map((i) => {
    return { ...i.data(), id: i.id };
  });
};
export const mapFacets = (item) => {
  return Object.keys(item).map((facet) => {
    let value = item[facet];
    let name = facet;
    if (!name.startsWith("#")) name = `#${name}`;
    return { name, value, facet };
  });
};
export const formatFileSize = (sizeInBytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (sizeInBytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)));
  const roundedSize = (sizeInBytes / Math.pow(1024, i)).toFixed(2);
  // return Math.round(sizeInBytes / Math.pow(1024, i), 2) + " " + sizes[i];
  return `${roundedSize} ${sizes[i]}`;
};
export const mapFacetSelect = (item) => {
  return Object.keys(item).map((name) => {
    let formatedName = name?.charAt(0).toUpperCase() + name?.slice(1);
    return { name: `${formatedName} (${item[name]})`, value: name };
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      reject(error);
    };
  });
}

export function trackGTM(event, category, action, label, value) {
  const gtm = useGtm();
  gtm.trackEvent({
    event: event,
    category: category,
    action: action,
    label: label,
    value: value,
    noninteraction: false,
  });
  console.log("GTM Trigger : ", event);
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const eventBus = new Vue();
