<template>
  <v-dialog v-model="dialog" width="600px" v-if="currentUID == uid">
    <template v-slot:activator="{ on }">
      <button
        type="button"
        v-on="on"
        class="h-[34px] px-4 py-[7px] bg-[#8056dd] justify-center items-center inline-flex rounded-md"
      >
       
      <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="white" d="m12.89 3l1.96.4L11.11 21l-1.96-.4zm6.7 9L16 8.41V5.58L22.42 12L16 18.41v-2.83zM1.58 12L8 5.58v2.83L4.41 12L8 15.58v2.83z"/></svg>
      
      </button>
    </template>
    <dialog-card title="Embed Widget" @close="dialog = false" :isSubmit="false">
      <please-wait v-if="isLoading"></please-wait>
      <template v-else>
        <div class="pa-3 grey lighten-3 black--text subtitle-2">
          {{ iframe }}
        </div>
        <div class="d-flex mt-2">
          <v-spacer></v-spacer>
          <v-btn @click="copy()" text color="green">Copy Clipboard</v-btn>
        </div>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { addDoc, getDocs, limit, query, where } from "firebase/firestore";
import DialogCard from "./ui/DialogCard.vue";
import PleaseWait from "./ui/PleaseWait.vue";
import { widget_keys } from "@/utils/firebase.utils";
export default {
  components: { DialogCard, PleaseWait },
  props: {
    type: {
      type: String,
      default: "dashboard",
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: true,
      widgetKey: "",
    };
  },
  computed: {
    iframe() {
      let url = `${location.origin}/widgets/${this.type}/${this.widgetKey}?ch=8050DE`;
      return `<iframe src="${url}" width="100%" height="100%" style="border:0px"></iframe>`;
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.iframe);
      this.$snackbar.show("Embed Widget Copied to Clipboard");
    },

    async fetchWidgetKey() {
      try {
        this.isLoading = true;
        let wid = this.WID || "default";
        let uid = this.currentUID;
        let widgetQuery = query(
          widget_keys,
          where("uid", "==", uid),
          where("wid", "==", wid),
          limit(1)
        );
        let snap = (await getDocs(widgetQuery)).docs;
        if (snap.length == 0) {
          let widget = await addDoc(widget_keys, {
            uid: uid,
            wid: wid,
          });
          this.widgetKey = widget.id;
        } else {
          this.widgetKey = snap[0].id;
        }
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchWidgetKey();
  },
};
</script>

<style></style>
