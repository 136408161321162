import Calender from "@/pages/fly/Calender.vue";
import Dashboard from "../pages/fly/Dashboard.vue";
import ConnectedApps from "@/pages/fly/ConnectedApps.vue";

let routes = [
  {
    path: "/widgets/planner/:id",
    name: "planner-widget",
    component: Calender,
  },
  {
    path: "/widgets/dashboard/:id",
    name: "dashboard-widget",
    component: Dashboard,
  },
  {
    path: "/widgets/connected-apps/:id",
    name: "location-connected-apps",
    component: ConnectedApps,
  },
];

export default routes;
