<template>
  <v-container
    v-if="newpost || (existingPost && loaded)"
    style="max-width: 1000px"
    ref="container"
  >
    <v-stepper v-model="step" flat>
      <v-stepper-header class="elevation-0">
        <v-stepper-step
          :complete="step > 1"
          :editable="step > 1 && step < 4"
          step="1"
          color="#8056DE"
        >
          Build
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 2"
          :editable="step > 2 && step < 4 && hasConfigurations"
          step="2"
          color="#8056DE"
          >Configure
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3" color="#8056DE">
          Release
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <create-post-build-step
            v-model="post"
            :postId="postId"
            :post="post"
            :social_media="social_media"
            :isOlxPost="isOlxPost"
            :isCarListingPost="isCarListingPost"
            :imageArray="imageArray"
            :isRepost="existingPost"
            :workspaceRole="workspaceRole"
            :isEditing="scheduledPost"
            :isDraftPost="isDraftPost"
            :draftLoading="draftLoading"
            @connectedApps="connectedApps = $event"
            @submit="handleBuildSubmit()"
            @userData="handleUserData"
            @videoselected="handleVideoSelect"
            @videoremoved="handlVidRemove"
            @duration="handleVidDuration"
            @configureClicked="handleConfigureClicked"
            @validVideoTypes="validVideoTypes = $event"
            @fetchedDuration="handleFetchedDuration"
            @configuration="postTypeConfiguration = $event"
            @savePostToDraft="saveDraftPostFromBuildStep"
            @updateDraft="updateDraft"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <create-post-configure-step
            v-model="configuration"
            :connectedApps="connectedApps"
            ref="configure"
            @back="step = 1"
            :postUID="currentUID"
            @submit="step = 3"
            @thumb="handleDisplayThumb"
            @coverimage="handleDisplayCoveredImage"
            @thumbremoved="handleThumbRemoved"
            @coverimageremoved="handlecoverImageRemoved"
            @savePostToDraft="saveToDraft"
            @updateDraft="updateDraftPost"
            :draftLoading="draftLoading"
            :post="post"
            :workspaceRole="workspaceRole"
            :postTypeConfiguration="postTypeConfiguration"
            :isRepost="existingPost"
            :isEditing="scheduledPost"
            :isDraftPost="isDraftPost"
            :postId="postId"
            :configuration="configuration"
            :isVideoSelected="isVideoSelected"
            :validVideoTypes="validVideoTypes"
            :vidDuration="vidDuration"
            :loadConfigBtn="loadConfigBtn"
            :configureClicked="configureClicked"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="d-flex">
            <v-spacer class="hidden md:block"></v-spacer>
            <v-btn class="mr-3 grey-lighten-5" depressed :small="!isSmallAndUp" :large="isSmallAndUp"
              @click="gotoConfigure()" :disabled="draftLoading">Back</v-btn>
            <v-btn depressed :loading="draftLoading" :disabled="loading" v-if="isDraftPost" :small="!isSmallAndUp"
              :large="isSmallAndUp" @click="updateDraftPost()" class="mr-3 primary">Update draft</v-btn>
            <v-btn depressed :loading="draftLoading" v-if="!isDraftPost" :small="!isSmallAndUp"
              :large="isSmallAndUp" @click="saveToDraft()" class="mr-3 secondary">Save as draft</v-btn>
            <v-btn v-if="workspaceRole === 'Creator'" depressed :loading="loading" :disabled="draftLoading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="sendForApproval()" class="primary">Send for
              Approval</v-btn>
            <v-btn v-else-if="
              post.when_to_post === 'immediate' &&
              (!postData.post_status ||
                (postData.post_status && postData.post_status !== 'draft'))
            " depressed :loading="loading" :disabled="draftLoading" :small="!isSmallAndUp" :large="isSmallAndUp"
              @click="publish()" color="#8056DE"><span class="text-white">Publish</span></v-btn>
            <v-btn v-if="workspaceRole !== 'Creator' && postData.post_status && postData.post_status == 'draft'" depressed :loading="loading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()" :disabled="draftLoading"
              class="primary">Publish</v-btn>
            <v-btn v-else-if="workspaceRole!=='Creator' && post.when_to_post === 'schedule' && !scheduledPost" depressed :loading="loading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()" class="primary">Schedule</v-btn>
            <v-btn v-else-if="
              workspaceRole !== 'Creator' &&
              post.when_to_post === 'schedule' &&
              scheduledPost &&
              (!postData.post_status ||
                (postData.post_status && postData.post_status !== 'draft'))
            " depressed :loading="loading" :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()"
              class="primary">Save</v-btn>
          </div>
          <around
            v-if="
              this.post.social_media &&
              this.post.social_media.includes('around')
            "
            :post="post"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
          />
          <olx
            v-if="
              this.post.social_media && this.post.social_media.includes('olx')
            "
            :post="post"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
          />
          <quikr
            v-if="
              this.post.social_media && this.post.social_media.includes('quikr')
            "
            :post="post"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
          />
          <google-my-business
            v-if="
              (this.post.social_media && this.post.social_media.includes('google_business_profile')) ||
              (this.post.config_social_media && this.post.config_social_media.includes('google_business_profile'))
            "
            :post="post"
            :userData="gb_userData"
            :google_business_profile_text="post.google_business_profile_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <facebook
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('facebook')) || (this.post.config_social_media && this.post.config_social_media.includes('facebook'))
            "
            :post="post"
            :userData="fb_userData"
            :facebook_text="post.facebook_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
            :thumbRemoved="this.thumbRemoved"
          />
          <instagram
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('instagram')) || (this.post.config_social_media && this.post.config_social_media.includes('instagram'))
            "
            :post="post"
            :userData="ig_userData"
            :instagram_text="post.instagram_text"
            :configuration="configuration"
          />
          <tumblr
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('tumblr')) || (this.post.config_social_media && this.post.config_social_media.includes('tumblr'))
            "
            :post="post"
            :userData="tb_userData"
            :tumblr_text="post.tumblr_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <reddit
            v-if="
              this.post.social_media &&
              this.post.social_media.includes('reddit')
            "
            :post="post"
            :userData="rd_userData"
            :reddit_text="post.reddit_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <linkedin
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('linkedin')) || (this.post.config_social_media && this.post.config_social_media.includes('linkedin'))
            "
            :post="post"
            :userData="ld_userData"
            :linkedin_text="post.linkedin_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <mastodon
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('mastodon')) || (this.post.config_social_media && this.post.config_social_media.includes('mastodon'))
            "
            :post="post"
            :userData="ms_userData"
            :mastodon_text="post.mastodon_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <threads
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('threads')) || (this.post.config_social_media && this.post.config_social_media.includes('threads'))
            "
            :post="post"
            :userData="th_userData"
            :threads_text="post.threads_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
          />
          <bluesky
            v-if="
              this.post.social_media &&
              this.post.social_media.includes('bluesky')
            "
            :post="post"
            :userData="bs_userData"
            :bluesky_text="post.bluesky_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
          />
          <pinterest
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('pinterest')) || (this.post.config_social_media && this.post.config_social_media.includes('pinterest'))
            "
            :post="post"
            :userData="pt_userData"
            :pinterest_text="post.pinterest_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <twitter
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('twitter')) || (this.post.config_social_media && this.post.config_social_media.includes('twitter'))
            "
            :post="post"
            :userData="tw_userData"
            :twitter_text="post.twitter_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <youtube
            v-if="
              (this.post.social_media &&
              this.post.social_media.includes('youtube')) || (this.post.config_social_media && this.post.config_social_media.includes('youtube'))
            "
            :post="post"
            :configuration="configuration"
            :youtube_text="post.youtube_text"
            :userData="yt_userData"
            :thumbRemoved="this.thumbRemoved"
          />
          <vimeo
            v-if="
              this.post.social_media && this.post.social_media.includes('vimeo')
            "
            :post="post"
            :userData="tw_userData"
            :vimeo_text="post.vimeo_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <tiktok
            v-if="
              this.post.social_media &&
              this.post.social_media.includes('tiktok')
            "
            :post="post"
            :userData="tt_userData"
            :tiktok_text="post.tiktok_text"
            :detail="detail"
            :hasPreview="hasPreview"
            :url="url"
            :configuration="configuration"
          />
          <div class="flex-wrap items-center justify-center d-flex">
            <v-spacer class="hidden md:block"></v-spacer>
            <v-btn class="mr-3 grey-lighten-5" depressed :small="!isSmallAndUp" :large="isSmallAndUp"
              @click="gotoConfigure()" :disabled="draftLoading">Back</v-btn>
            <v-btn depressed :loading="draftLoading" :disabled="loading" v-if="isDraftPost" :small="!isSmallAndUp"
              :large="isSmallAndUp" @click="updateDraftPost()" class="mr-3 primary">Update draft</v-btn>
            <v-btn depressed :loading="draftLoading" v-if="!isDraftPost" :small="!isSmallAndUp" :large="isSmallAndUp"
              @click="saveToDraft()" class="my-2 mr-3 secondary">Save as draft</v-btn>
            <v-btn v-if="workspaceRole === 'Creator'" depressed :loading="loading" :disabled="draftLoading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="sendForApproval()" class="primary">Send for
              Approval</v-btn>
            <v-btn v-else-if="
              post.when_to_post === 'immediate' &&
              (!postData.post_status ||
                (postData.post_status && postData.post_status !== 'draft'))
            " depressed :loading="loading" :disabled="draftLoading" :small="!isSmallAndUp" :large="isSmallAndUp"
              @click="publish()" color="#8056DE"><span class="text-white">Publish</span></v-btn>
            <v-btn v-if="workspaceRole !== 'Creator' && postData.post_status && postData.post_status == 'draft'" depressed :loading="loading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()" :disabled="draftLoading"
              class="primary">Publish</v-btn>
            <v-btn v-else-if="workspaceRole !== 'Creator' && post.when_to_post === 'schedule' && !scheduledPost" depressed :loading="loading"
              :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()" class="primary">Schedule</v-btn>
            <v-btn v-else-if="
              workspaceRole !== 'Creator' &&
              post.when_to_post === 'schedule' &&
              scheduledPost &&
              (!postData.post_status ||
                (postData.post_status && postData.post_status !== 'draft'))
            " depressed :loading="loading" :small="!isSmallAndUp" :large="isSmallAndUp" @click="publish()"
              class="primary">Save</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CreatePostBuildStep from "@/components/post/CreatePostBuildStep.vue";
import CreatePostConfigureStep from "@/components/post/CreatePostConfigureStep.vue";
import { httpsCallable } from "firebase/functions";
import {
  colUsers,
  colScheduledPostsDelete,
  functions,
  storage,
} from "@/utils/firebase.utils";
import Olx from "@/components/post/PostPreview/Olx.vue";
import Quikr from "@/components/post/PostPreview/Quikr.vue";
import GoogleMyBusiness from "@/components/post/PostPreview/GoogleMyBusiness.vue";
import Around from "@/components/post/PostPreview/Around.vue";
import Facebook from "@/components/post/PostPreview/Facebook.vue";
import Instagram from "@/components/post/PostPreview/Instagram.vue";
import Youtube from "@/components/post/PostPreview/Youtube.vue";
import Twitter from "@/components/post/PostPreview/Twitter.vue";
import Tumblr from "@/components/post/PostPreview/Tumblr.vue";
import Reddit from "@/components/post/PostPreview/Reddit.vue";
import Linkedin from "@/components/post/PostPreview/Linkedin.vue";
import Pinterest from "@/components/post/PostPreview/Pinterest.vue";
import Vimeo from "@/components/post/PostPreview/Vimeo.vue";
import Tiktok from "@/components/post/PostPreview/Tiktok.vue";
import Mastodon from "@/components/post/PostPreview/Mastodon.vue";
import Threads from "@/components/post/PostPreview/Threads.vue";
import Bluesky from "@/components/post/PostPreview/Bluesky.vue";

import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import openaiMixin from "@/mixins/openai.mixin.js";
import {
  deleteDoc,
  doc,
  addDoc,
  getDoc,
  setDoc,
  collection,
  updateDoc,
  getDocs,
  arrayUnion,
} from "firebase/firestore";
import moment from "moment";
import { auth,db } from "../../utils/firebase.utils";

export default {
  components: {
    CreatePostBuildStep,
    CreatePostConfigureStep,
    Olx,
    GoogleMyBusiness,
    Quikr,
    Around,
    Facebook,
    Instagram,
    Youtube,
    Twitter,
    Tumblr,
    Reddit,
    Linkedin,
    Pinterest,
    Vimeo,
    Tiktok,
    Mastodon,
    Threads,
    Bluesky,
  },
  data() {
    return {
      step: 1,
      isOlxPost: false,
      isCarListingPost: false,
      loading: false,
      draftLoading: false,
      loaded: false,
      postId: "",
      postData: {},
      postUID: "",
      yt_title: "",
      post: {
        hashtags: [],
      },
      connectedApps: [],
      social_media: [],
      configuration: {},
      postTypeConfiguration: {
        ig_post_type: "",
        yt_post_type: "",
        fb_post_type: "",
      },
      userData: [],
      yt_userData: [],
      tw_userData: [],
      tb_userData: [],
      gb_userData: [],
      rd_userData: [],
      ld_userData: [],
      pt_userData: [],
      fb_userData: [],
      ig_userData: [],
      tt_userData: [],
      ms_userData: [],
      th_userData: [],
      bs_userData: [],

      isVideoSelected: false,
      vidDuration: 0,
      thumbRemoved: false,
      coverImageRemoved: false,
      validVideoTypes: {},
      url: "",
      detail: null,
      workspaceRole: "",
      messageDialog: false,
      messageTitle: "",
      messageContent: "",
      redirect: false,
      hasPreview: false,
      loadConfigBtn: false,
      configureClicked: false,
      newpost: false,
      existingPost: false,
      scheduledPost: false,
      approvalData: "",
      thumbnail: "",
      coverimage: "",
      draftPostId: "",
      isDraftPost: false,
      imageArray: [],
      initialScheduledDate: "",
      thumbnails: {},
    };
  },
  mixins: [openaiMixin],
  computed: {
    hasConfigurations() {
      let { videos, images } = this.post;
      if (this.isSocialHandle("facebook")) return true;
      if (this.isSocialHandle("tumblr")) return true;
      if (this.isSocialHandle("reddit")) return true;
      if (this.isSocialHandle("pinterest")) return true;
      if (this.isSocialHandle("linkedin")) return true;
      if (this.isSocialHandle("youtube") && (videos?.length ?? 0)) return true;
      if (this.isSocialHandle("tiktok")) return true;
      if (this.isSocialHandle("vimeo")) return true;
      if (this.isSocialHandle("google_business_profile")) return true;
      if (this.isSocialHandle("mastodon")) return true;
      if (this.isSocialHandle("threads")) return true;
      if (
        this.isSocialHandle("instagram") &&
        ((videos?.length ?? 0) || (images?.length ?? 0))
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    // Retrieve the postId from the route parameter
    if (this.$route.name == "draft-post") {
      this.draftPostId = this.$route.params?.postId;
    }
    this.postId = this.$route.params?.postId;
    // if (this.postId && this.$route.name !== "draft-post") {
    if (this.postId) {
      this.existingPost = true;
      this.newpost = false;
    } else {
      this.newpost = true;
      this.existingPost = false;
    }
  },
  watch: {
    step() {
      this.$refs.container.scrollIntoView(true);
    },
    currentUID() {
      this.fetchPost();
    },
  },
  methods: {
    isSocialHandle(name) {
      const inSocialMedia = (this.post.social_media || []).indexOf(name) !== -1;
      const inConfigSocialMedia = (this.post.config_social_media || []).indexOf(name) !== -1;
      if (inConfigSocialMedia) {
        switch(name) {
          case 'youtube':
            return (this.post.videos?.length > 0);
          case 'pinterest':
            return (this.post.videos?.length > 0);            
          case 'google_business_profile':
            return (this.post.images.length > 0);
            
          default:
            return false;
        }
      }
      return inSocialMedia;
    },
    async handleBuildSubmit() {
      const vm = this;
      if (this.hasConfigurations) {
        vm.step = 2;
      } else {
        vm.step = 3;
      }
      let content = this.post.text || "";
      const urlRegex = /(https?:\/\/[^\s]+)/;
      // Match URLs in the text using the regex
      const matches = content.match(urlRegex);
      if (matches && matches?.length > 0) {
        this.loadConfigBtn = true;
        this.hasPreview = true;
        this.url = matches[0];
        await this.postFromURL();

        if (this.detail) {
          this.loadConfigBtn = false;
        }
      } else {
        this.hasPreview = false;
      }
    },
    gotoConfigure() {
      if (this.hasConfigurations) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    },
    handlVidRemove() {
      this.isVideoSelected = false;
    },
    handleVideoSelect() {
      this.isVideoSelected = true;
    },
    handleThumbRemoved(thumbremoved) {
      if (this.existingPost) {
        this.thumbRemoved = thumbremoved;
      }
    },
    handlecoverImageRemoved(val) {
      if (this.isDraftPost) {
        // console.log("rem", val);
        this.coverImageRemoved = val;
      }
    },
    handleVidDuration(duration) {
      this.vidDuration = duration;
    },
    handleConfigureClicked(click) {
      this.configureClicked = click;
    },
    handleFetchedDuration(data) {
      if (this.existingPost) {
        const { videoId, duration } = data;
        this.vidDuration = duration;
      }
    },
    handleDisplayThumb(thumb) {
      if (this.existingPost) {
        this.thumbnail = thumb;
      }
    },
    handleDisplayCoveredImage(img) {
      if (this.existingPost) {
        this.coverimage = img;
      }
    },
    handleUserData(data) {
      this.userData = data;
      // console.log("user data in cp", this.userData);
      data.map((app) => {
        if (app.id === "facebook") {
          (this.fb_userData.name = app.name),
            (this.fb_userData.picture = app.picture);
        } else if (app.id === "youtube") {
          (this.yt_userData.name = app.name),
            (this.yt_userData.picture = app.picture);
        } else if (app.id === "twitter") {
          (this.tw_userData.name = app.name),
            (this.tw_userData.picture = app.picture);
        } else if (app.id === "instagram") {
          (this.ig_userData.name = app.name),
            (this.ig_userData.picture = app.picture);
        } else if (app.id === "tumblr") {
          (this.tb_userData.name = app.name),
            (this.tb_userData.picture = app.picture);
        } else if (app.id === "reddit") {
          (this.rd_userData.name = app.name),
            (this.rd_userData.picture = app.picture);
        } else if (app.id === "mastodon") {
          (this.ms_userData.name = app.name),
            (this.ms_userData.picture = app.picture);
        }else if (app.id === "threads") {
          (this.th_userData.name = app.name),
            (this.th_userData.picture = app.picture);
        }else if (app.id === "bluesky") {
          (this.bs_userData.name = app.name),
            (this.bs_userData.picture = app.picture);
        } else if (app.id === "linkedin") {
          (this.ld_userData.name = app.name),
            (this.ld_userData.picture = app.picture);
        } else if (app.id === "pinterest") {
          (this.pt_userData.name = app.name),
            (this.pt_userData.picture = app.picture);
        } else if (app.id === "vimeo")
          (this.pt_userData.name = app.name),
            (this.pt_userData.picture = app.picture);
        else if (app.id === "tiktok") {
          console.log("TikTok app data:", app);
          this.tt_userData.name = app.name;
          this.tt_userData.picture = app.picture;
        } else if (app.id === "google_business_profile") {
          this.gb_userData.name = app.name;
          this.gb_userData.picture = app.picture;
        }
      });
    },
    handleAssets(assets) {
      const updatedAssets = assets?.map((element) => {
        if (typeof element === "object" && element.url) {
          return element.url;
        }
        return element;
      });
      return updatedAssets;
    },
    handleDraftData(draftData) {
      const vm = this;
      let data = {
        ...draftData,
      };
      if (!data.wid) {
        data.wid = vm.WID;
      }
      if (!this.isDraftPost) {
        data.created_at = new Date();
        data.updated_at = new Date();
        data.post_status = "draft";
        data.uid = vm.currentUID;
      }
      if (data.when_to_post === "immediate") {
        data.scheduled_at = "";
      }
      data.updated_at = new Date();
      return data;
    },
    async handlePinterestCoverImage(image) {
      let vm = this;
      let storageRef;
      if (this.post.social_media.includes("pinterest") || this.post.config_social_media.includes("pinterest")) {
        if (vm.WID === "default") {
          storageRef = ref(
            storage,
            `users/${
              vm.currentUID
            }/pinterest_cover_image/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/${
              vm.WID
            }/pinterest_cover_image/${new Date().getTime()}.png`
          );
        }
        await uploadBytes(storageRef, image);
        return await getDownloadURL(storageRef);
      }
    },
    async handleYoutubeThumbnail(thumbnail) {
      let vm = this;
      let thumbType = typeof thumbnail;
      let storageRef;
      let hasYoutube = vm.post.social_media.includes("youtube");
      if (
        hasYoutube &&
        thumbType === "object" &&
        !this.thumbRemoved &&
        thumbnail &&
        thumbnail instanceof File
      ) {
        if (vm.WID === "default") {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/youtube_thumbs/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/${
              vm.WID
            }/youtube_thumbs/${new Date().getTime()}.png`
          );
        }
        await uploadBytes(storageRef, thumbnail);
        thumbnail = await getDownloadURL(storageRef);
        return thumbnail;
      }
    },
    async handleThumbnail(thumbnail, platform) {
      let vm = this;
      let storageRef;
      let hasPlatform = vm.post.social_media.includes(platform);
      if (hasPlatform && thumbnail) {
        if (vm.WID === "default") {
          storageRef = ref(
            storage,
            `users/${
              vm.currentUID
            }/${platform}_thumbs/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/${
              vm.WID
            }/${platform}_thumbs/${new Date().getTime()}.png`
          );
        }
        if (thumbnail instanceof File) {
          await uploadBytes(storageRef, thumbnail);
        } else {
          await uploadString(storageRef, thumbnail, "data_url");
        }
        thumbnail = await getDownloadURL(storageRef);
        return thumbnail;
      }
    },
    async handleThumbnail(thumbnail, platform) {
      let vm = this;
      let storageRef;
      let hasPlatform = vm.post.social_media.includes(platform);
      if (hasPlatform && thumbnail) {
        if (vm.WID === "default") {
          storageRef = ref(
            storage,
            `users/${
              vm.currentUID
            }/${platform}_thumbs/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/${
              vm.WID
            }/${platform}_thumbs/${new Date().getTime()}.png`
          );
        }
        if (thumbnail instanceof File) {
          await uploadBytes(storageRef, thumbnail);
        } else {
          await uploadString(storageRef, thumbnail, "data_url");
        }
        thumbnail = await getDownloadURL(storageRef);
        return thumbnail;
      }
    },
    deleteUnnecessaryFields(data, social_media) {
      if (!social_media.includes("youtube")) {
        if(!data.config_social_media.includes("youtube")){
          delete data.configuration.yt_thumb;
          delete data.configuration.yt_post_type;
          delete data.configuration.yt_tags;
          delete data.configuration.yt_category;
          delete data.configuration.yt_title;
          delete data.configuration.yt_license;
        }
      }
      if (!social_media.includes("facebook")) {
        delete data.configuration.fb_post_type;
        delete data.configuration.fb_pages;
      }
      if (!social_media.includes("instagram")) {
        delete data.configuration.ig_post_type;
        delete data.configuration.ig_users;
      }
      if (!social_media.includes("tumblr")) {
        delete data.configuration.blog;
      }
      if (!social_media.includes("reddit")) {
        delete data.configuration.subreddit;
      }
      if (!social_media.includes("pinterest")) {
        if(!data.config_social_media.includes("pinterest")){
          delete data.configuration.board;
          delete data.configuration.cover_image_url;
        }
      }
      return data;
    },
    async updateDraftPost() {
      const vm = this;
      let data = {
        ...this.post,
        configuration: { ...vm.configuration, ...this.postTypeConfiguration },
      };
      const draftData = this.handleDraftData(data);
      draftData.domain = window.location.host;
      vm.draftLoading = true;
      if (
        (vm.post.videos ?? []).length &&
        draftData.configuration.cover_image_url &&
        typeof draftData.configuration.cover_image_url == "object"
      ) {
        draftData.configuration.cover_image_url =
          await vm.handlePinterestCoverImage(
            draftData.configuration.cover_image_url
          );
      }
      const thumbnail = await this.handleYoutubeThumbnail(
        draftData.configuration.yt_thumb
      );
      draftData.configuration.yt_thumb = thumbnail;
      let hasYoutube = vm.post.social_media.includes("youtube");
      let hasInstagram = vm.post.social_media.includes("instagram");
      if (
        hasInstagram &&
        draftData.configuration.ig_thumb &&
        !draftData.configuration.ig_thumb.startsWith("https")
      ) {
        let thumbnail = await this.handleThumbnail(
          draftData.configuration.ig_thumb,
          "instagram"
        );
        draftData.configuration.ig_thumb = thumbnail;
      }
      if (
        !hasInstagram ||
        !draftData.configuration.ig_thumb ||
        draftData.configuration.ig_thumb == undefined
      ) {
        delete draftData.configuration.ig_thumb;
      }
      if (!hasYoutube) {
        if (draftData.configuration.yt_thumb) {
          delete draftData.configuration.yt_thumb;
        }
        delete draftData.configuration.yt_tags;
        delete draftData.configuration.yt_post_type;
      }
      if (this.coverImageRemoved || !draftData.configuration.cover_image_url) {
        delete draftData.configuration.cover_image_url;
      }
      if (
        !hasYoutube ||
        this.thumbRemoved ||
        !draftData.configuration.yt_thumb
      ) {
        delete draftData.configuration.yt_thumb;
      }
      if (!hasYoutube || !draftData.configuration.yt_title) {
        delete draftData.configuration.yt_title;
      }
      if (!hasYoutube || !draftData.configuration.yt_category) {
        delete draftData.configuration.yt_category;
      }
      if (!hasYoutube || !draftData.configuration.yt_license) {
        delete draftData.configuration.yt_license;
      }
      console.log("draftData:", draftData);
      await this.updateDraftToDB(draftData);
      vm.draftLoading = false;
    },
    async updateDraft() {
      let data = {
        ...this.post,
      };
      const draftData = this.handleDraftData(data);
      await this.updateDraftToDB(draftData);
    },
    async updateDraftToDB(data) {
      const vm = this;

      await updateDoc(
        doc(
          colUsers,
          vm.currentUID,
          "fly_workspaces",
          vm.WID,
          "social-posts",
          this.draftPostId
        ),
        data
      );
      this.$snackbar.show("Draft updated succcessfully!");
      this.$router.replace(
        `/post/${vm.currentUID}/${vm.WID}/${this.draftPostId}`
      );
    },
    async saveDraftPostFromBuildStep(data) {
      const vm = this;
      data.configuration = { ...vm.postTypeConfiguration };
      const draftData = vm.handleDraftData(data);
      this.saveDraftPostToDB(draftData);
    },
    async saveToDraft() {
      this.$mixpanel.track("Save as Draft Clicked", { step: "Release" });
      const vm = this;
      try {
        let data = {
          configuration: { ...vm.configuration, ...vm.postTypeConfiguration },
          ...vm.post,
        };
        const draftData = this.handleDraftData(data);
        draftData.domain = window.location.host;
        vm.draftLoading = true;
        if (
          (vm.post.videos ?? []).length &&
          draftData.configuration.cover_image_url &&
          typeof draftData.configuration.cover_image_url == "object"
        ) {
          draftData.configuration.cover_image_url =
            await vm.handlePinterestCoverImage(
              draftData.configuration.cover_image_url
            );
        }
        let hasYoutube = vm.post.social_media.includes("youtube");
        const thumbnail = await this.handleYoutubeThumbnail(
          draftData.configuration.yt_thumb
        );
        draftData.configuration.yt_thumb = thumbnail;
        if (!hasYoutube && !draftData.config_social_media.includes("youtube")) {
          delete draftData.configuration.yt_title;
          delete draftData.configuration.yt_tags;
          delete draftData.configuration.yt_category;
        }
        if (
          !hasYoutube ||
          this.thumbRemoved ||
          !draftData.configuration.yt_thumb
        ) {
          delete draftData.configuration.yt_thumb;
        }
        if (this.thumbRemoved) {
          draftData.configuration.yt_thumb = null;
        }
        // console.log("draftData:", draftData);
        const modifiedData = this.deleteUnnecessaryFields(
          draftData,
          vm.post.social_media
        );
        if (this.currentUID !== auth.currentUser.uid) {
          modifiedData.postBy = auth.currentUser.uid;
        }
        // console.log("modifiedData", modifiedData);               
        await this.saveDraftPostToDB(modifiedData);
      } catch (error) {
        vm.draftLoading = false;
        // vm.handleError(error);
      }
    },
    async saveDraftPostToDB(data) {
      delete data?.output;
      const vm = this;
      let socialPostRef = collection(
        colUsers,
        vm.currentUID,
        "fly_workspaces",
        vm.WID,
        "social-posts"
      );
      if (data.configuration?.ig_thumb) {
        let thumbnail = await this.handleThumbnail(
          data.configuration.ig_thumb,
          "instagram"
        );
        data.configuration.ig_thumb = thumbnail;
      }
      const docRef = await addDoc(socialPostRef, data);
      const postId = docRef.id;

      if (vm.currentUID === vm.currentOriginalUID) {
        this.$router.replace(`/post/${vm.currentUID}/${vm.WID}/${postId}`);
      }
      else this.$router.replace(`/posts`);
      

      vm.postId = postId;
      vm.$snackbar.show("Post saved in drafts");
      vm.draftLoading = false;
    },
    async publish() {
      this.$mixpanel.track("Publish the Post Clicked");
      const vm = this;
      // let date = new Date().toISOString().split("T")[0];
      let date = new Date();
      date = moment(date).format('YYYY-MM-DD')
      try {
        let data = {
          ...vm.post,
          configuration: { ...vm.configuration, ...vm.postTypeConfiguration },
          uid: vm.currentUID,
          wid: vm.WID,
          is_cross_post: false,
          cross_post_id:"",
          source_platform:"",
          source_post_link:"",
          source_handle: "",
        };
        let isOlx = this.post.social_media.includes("olx");
        let payload = null;
        // if(isOlx) {
        //   payload = {
        //     make: vm.post.make,
        //     make: vm.post.make,
        //     make: vm.post.make,
        //     make: vm.post.make,

        //   }
        // }
        data.domain = window.location.host;

    if (this.newpost) {
      data.videos = data.videos.map((i) => i.url);
    } else if (this.existingPost || this.isDraftPost) {
      delete data.created_at;
      delete data.output;
      delete data.updated_at;

      const updatedImages = this.post.images?.map((element) => {
        if (typeof element === "object" && element.url) {
          return element.thumbnail;
        }
        return element;
      });
      const updatedOverrideImages = this.post.overrideImages?.map((element) => {
        if (typeof element === "object" && element.url) {
          return element.thumbnail;
        }
        return element;
      });
      data.images = updatedImages;
      data.overrideImages = updatedOverrideImages;

          const updatedVideos = this.post.videos?.map((element) => {
            if (typeof element === "object" && element.url) {
              return element.url;
            }
            return element;
          });
          data.videos = updatedVideos;
          if (data.when_to_post === "immediate") {
            data.scheduled_at = "";
          }
        }
        // console.log("post:", data)
        vm.loading = true;
        if (
          (vm.post.videos ?? []).length &&
          data.configuration.cover_image_url &&
          typeof data.configuration.cover_image_url == "object"
        ) {
          data.configuration.cover_image_url =
            await vm.handlePinterestCoverImage(
              data.configuration.cover_image_url
            );
        }
        let hasYoutube = vm.post.social_media.includes("youtube");
        const thumbnail = await this.handleYoutubeThumbnail(
          data.configuration.yt_thumb
        );
        data.configuration.yt_thumb = thumbnail;
        if (this.thumbRemoved) {
          data.configuration.yt_thumb = null;
        }
        if (this.scheduledPost) {
          let deletedoc = {
            post_id: vm.postId,
            wid: data.wid || vm.WID,
            uid: vm.currentUID,
          };
          if (!hasYoutube) {
            if (data.configuration.yt_thumb) {
              delete data.configuration.yt_thumb;
            }
            delete data.configuration.yt_title;
            delete data.configuration.yt_tags;
            delete data.configuration.yt_category;
          }
          let postRef = doc(
            colUsers,
            vm.currentUID,
            "fly_workspaces",
            vm.WID,
            "social-posts",
            vm.postId
          );
          let deleteScheduledPostData = {
            uid: data.uid,
            wid: data.wid,
            postId: vm.postId,
            deleted_at: new Date(),
            was_scheduled_for: new Date(data.scheduled_at),
            action: "edit",
          };
          date = this.initialScheduledDate || moment(data.scheduled_at).format('YYYY-MM-DD');
          console.log('initialScheduledDate:', vm.initialScheduledDate, date, vm.postId)
          const calendarRef = doc(
            colUsers,
            vm.currentUID,
            "fly_workspaces",
            vm.WID,
            "calendar",
            date,
            'posts',
            vm.postId
          );
          // let dataaa = (await getDoc(calendarRef)).data();
          let postSnapShot = await getDoc(calendarRef);
          const postData = postSnapShot.data();
          console.log('dataaa',calendarRef, postData);
          await deleteDoc(calendarRef);

          await setDoc(
            doc(colScheduledPostsDelete, vm.postId),
            deleteScheduledPostData
          );
        }
        vm.postUID = vm.currentUID;
        // console.log("data", data);
        if (this.isDraftPost) {
          if (data.social_media.includes("olx")) {
            delete data.imageDataArray;
          }
          delete data.post_status;
          // console.log("draftPostId:", this.draftPostId);
        }
        if (data.post_status) {
          delete data.post_status;
        }
        const modifiedData = this.deleteUnnecessaryFields(
          data, 
          vm.post.social_media
        );
        if(modifiedData?.configuration?.yt_thumb == undefined) {
          delete modifiedData.configuration.yt_thumb;
        }
        if(!modifiedData.text || modifiedData.text == undefined){
          modifiedData.text = "";
        }
        console.log("modifiedData", modifiedData);
        if(!modifiedData.configuration.cover_image_url || modifiedData.configuration.cover_image_url == undefined) {
          delete modifiedData.configuration.cover_image_url;
        }

        if(!modifiedData.social_media.length && modifiedData.config_social_media && modifiedData.config_social_media.length){
          modifiedData.post_status = "published";           
        }
        let addSocialPost = httpsCallable(functions, "addSocialPost");
        let { postId } = (await addSocialPost(modifiedData)).data;
        // if(isOlx) {
        //   data.post_id = postId || '';
        //   const url = "https://fly-media-publisher-nfp3rxpvjq-el.a.run.app/postToOlx";
        //   const response = await fetch(url, {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(data),
        //   });

        //   const dataRes = await response.json(); // Parsing JSON from the response
        //   console.log("dataRes:: ",dataRes);
        // }
        this.$router.replace(`/post/${vm.currentUID}/${vm.WID}/${postId}`);
        // vm.$alert.show(message);
        vm.postId = postId;
        vm.step = 4;
        vm.loading = false;
        if (this.isDraftPost) {
          let draftPostRef = doc(
            colUsers,
            vm.currentUID,
            "fly_workspaces",
            vm.WID,
            "social-posts",
            vm.draftPostId
          );
          await deleteDoc(draftPostRef);
        }
        console.log('date bef', date, new Date())
        if(data.scheduled_at) {
          // date = new Date(data.scheduled_at).toISOString().split("T")[0];
          date = moment(data.scheduled_at).format('YYYY-MM-DD');
          
        }
        console.log('date after', date)

    const calendarRef = doc(
      colUsers,
      vm.currentUID,
      "fly_workspaces",
      vm.WID,
      "calendar",
      date
    );
    const calendarSnap = await getDoc(calendarRef);
    if (calendarSnap.exists()) {
      
      await setDoc(doc(calendarRef, "posts", postId), modifiedData); 
    } else {

      await setDoc(doc(calendarRef, "posts", postId), modifiedData);  
    }

 } catch (error) {
    vm.loading = false;
    vm.handleError(error);
  }
},
    async sendForApproval() {
      this.$mixpanel.track("Send for Approval Clicked");
      const vm = this;
      try {
        vm.loading = false;
        this.$loader.show();
        // console.log(this.postId,"post id before")
        const routeName = this.$route.name
        if(routeName=='draft-post'){
          await this.updateDraftPost();
        }
        else{
          await this.saveToDraft();
        }
        // console.log(this.postId,"post id after")
        // console.log(this.currentUID,this.WID,"these are ids")
        const nameRef = doc(colUsers, this.currentOriginalUID);
        const namedoc = await getDoc(nameRef);
        const ownerNameRef = doc(colUsers, this.currentUID);
        const ownerName = await getDoc(ownerNameRef);

        const postRef = doc(colUsers, this.currentUID,"fly_workspaces",this.WID,"social-posts",this.postId);
        const postBy = this.currentOriginalUID
        await updateDoc(postRef,{
          isCurrUser: true,
          approvedStatus:true,
          postBy:postBy,
        });

        const postData = (await getDoc(postRef)).data()
        this.$pushNotification({
          uid: this.currentOriginalUID,
          wid: this.WID,
          payload: {
            message: `Request Sent to ${ownerName.data().display_name}`,
            title: "Post approval",
            token: this.currentOriginalUID,
            url:`/post/${this.currentUID}/${this.WID}/${this.postId}`
          }
        });
        let post_text;
        if(postData.text){
          const temp = postData.text||'';
          post_text = temp;
        }
        this.$pushNotification({
          uid: this.currentUID,
          wid: this.WID,
          payload: {
            reply:false,
            sendPostMail:true,
            recieverUID:this.currentUID,
            name:namedoc.data().display_name,
            image:postData.images[0],
            message: `${namedoc.data().display_name} asked for approval on a post`,
            title: "Post approval",
            token: this.currentUID,
            url:`/post/${this.currentUID}/${this.WID}/${this.postId}`,
            isEmail:true,
            text:post_text,
          }
        });
        this.$loader.hide();
        this.showMessageDialog("Success", "Post sent for Approval, You can edit your post in draft.", true);
        
      } catch (error) {
        vm.handleError(error);
      }
      vm.loading = false;
    },
    async fetchPost() {
      const vm = this;
      if (!vm.currentUID || !vm.WID) {
        console.error("currentUID or WID is not defined.");
        return;
      }
      // Fetch role data
      if(vm.currentUID!==vm.currentOriginalUID){
        // let roleRef = doc(colUsers, vm.currentOriginalUID, "workspaces_granted", `${vm.WID}__${vm.currentUID}`);
        // let getRole = await getDoc(roleRef);
        // let roleData = getRole.data();
  
        this.workspaceRole = this.currentWorkspace?.role||null;
      }
      try {
        if (vm.postId && vm.WID && vm.currentUID) {
          vm.isLoading = true;
          let postRef = doc(
            colUsers,
            vm.currentUID,
            "fly_workspaces",
            vm.WID,
            "social-posts",
            this.postId
          );
          let postSnapShot = await getDoc(postRef);
          const postData = postSnapShot.data();
          if(postData.scheduled_at) {
            this.initialScheduledDate = moment(postData.scheduled_at).format('YYYY-MM-DD');
          }
          if (
            postData.scheduled_at &&
            (!postData.post_status ||
              (postData.post_status && postData.post_status !== "draft"))
          ) {
            this.scheduledPost = true;
          }
          if (
            postData.scheduled_at &&
            (!postData.post_status ||
              (postData.post_status && postData.post_status !== "draft"))
          ) {
            postData.scheduled_at = new Date(postData.scheduled_at);
          }
          if (postData.post_status && postData.post_status === "draft") {
            if (
              postData.isCarListingPost &&
              postData.isCarListingPost === true
            ) {
              this.isCarListingPost = true;
              this.imageArray = postData.imageDataArray;
            }
            console.log("postData.social_media:", postData.social_media);
            if (postData.social_media.includes("olx")) {
              this.isOlxPost = true;
              this.imageArray = postData.imageDataArray;
            }
            this.isDraftPost = true;
            if (postData.scheduled_at && postData.scheduled_at?.seconds) {
              postData.scheduled_at = new Date(
                postData.scheduled_at.seconds * 1000
              );
            } else if (postData.scheduled_at) {
              postData.scheduled_at = new Date(postData.scheduled_at);
            }
          }
          this.post = { ...postData };
          this.postData = { ...postData };
          this.configuration = postData.configuration;
          this.social_media = postData.social_media;
          Object.keys(vm.postTypeConfiguration).forEach((key) => {
            vm.postTypeConfiguration[key] = postData.configuration[key] || "";
          });
          this.loaded = true;
          vm.isLoading = false;
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.fetchPost();
  },
};
</script>

<style></style>