<template>
    <div style="font-family: Inter, sans-serif;">
        <!-- Subscription Section -->
        <section class="flex flex-wrap justify-center gap-6 pb-12">
            <article
                class="w-[280px] max-md:w-[220px] border text-center rounded-2xl bg-white hover:bg-gray-50 border-gray-300 transition-transform transform ">
                <header class="pt-6">
                    <h1 class="text-3xl max-md:text-2xl max font-bold text-gray-800">Free Trial</h1>
                </header>
                <p class="mt-4 text-lg max-md:text-[0.9rem] text-gray-600 px-4">
                    Experience Insta-Everywhere free for 14 days
                </p>
                <ul class="max-md:text-[0.9rem] mt-6 text-left text-gray-700 space-y-2 px-8">
                    <li class="flex items-center">
                        <v-icon color="#8056DE" class="mr-2">mdi-check-circle</v-icon>
                        Unlimited Access to All Features
                    </li>
                    <li class="flex items-center">
                        <v-icon color="#8056DE" class="mr-2">mdi-check-circle</v-icon>
                        Free Cross Posting on all platforms
                    </li>
                </ul>
                <div class="flex flex-col gap-4">
                    <div class="px-9">
                        <button class="rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                            :class="isExpired ? 'bg-red-500' : 'bg-[#8056DE]'"
                            :disabled="isExpired"
                            @click="!isTrialActive && !isExpired ? subscribe() : null">
                            {{ !isTrialActive && !isExpired? 'Start Free Trial' : (isExpired ? 'Expired' : 'Active') }}
                        </button>
                    </div>
                    <p v-if="isTrialActive && !isExpired" class="mb-3">
                        Expires On: {{ trialEndDate }}
                    </p>
                    <p v-else class="mb-8"></p>
                </div>
            </article>
        </section>
    </div>
</template>

<script>
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { colUsers, functions } from '@/utils/firebase.utils';
import { httpsCallable } from 'firebase/functions';

export default {
    props: {
        primaryPlatform: {
            type: String,
            default: '',
        },
        connectionStat: {
            type: Boolean,
            default: false,
        },
    },
    name: 'SubscriptionSection',
    data() {
        return {
            isExpired: false,
            isTrialActive: false,
            trialEndDate: '',
        };
    },
    methods: {
        async subscribe() {
            if(this.primaryPlatform=='youtube'){
                const subscribetoYoutube = httpsCallable(functions, "youtubeCrossPosting");
                subscribetoYoutube({subscription:true,uid:this.uid,wid:'default'});
            }
            this.$emit("subscribed",true);
            const docRef = doc(colUsers, this.uid);
            // Create a new Date object for the current date
            const currentDate = new Date();

            // Add 7 days to the current date
            const validUntilDate = new Date(currentDate.setDate(currentDate.getDate() + 14));

            // Update the document in Firestore
            await updateDoc(docRef, {
                fly_lite_plan: 'trial',
                fly_trial_plan_valid_until: validUntilDate,
            });

            // Set state after subscription
            this.isTrialActive = true;
            this.isExpired = false;
            this.trialEndDate = validUntilDate.toDateString();
        }
    },
    async mounted() {
        // Ensure the UID is set from some source (auth, props, etc.)

        const docRef = doc(colUsers, this.uid);
        const currentDate = new Date();
        const docSnap = await getDoc(docRef);
        // this.isExpired = true
        if (docSnap.exists()) {
            const docData = docSnap.data();
            const validUntil = docData?.fly_trial_plan_valid_until?.toDate();

            // If validUntil exists and is after current date
            if (validUntil && validUntil > currentDate) {
                this.isTrialActive = true;
                this.trialEndDate = validUntil.toDateString();
            } else if (validUntil && validUntil <= currentDate) {
                this.isExpired = true;
                this.isTrialActive = false;
            }
        }
    }
};
</script>

<style scoped>
.subscribe-button {
    cursor: pointer;
}

.subscribe-button:hover {
    background-color: #6f4cc7;
}
</style>
