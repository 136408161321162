import { auth, colUsers } from "@/utils/firebase.utils";
import {
  documentId,
  collection,
  doc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { mapGetters } from "vuex";
import {
  formatDate,
  formatDateDDMMYYYY,
  formatFileSize,
  connectedHandle,
} from "../utils/common.utils.js";
import _ from "lodash";

export default {
  computed: {
    ...mapGetters([
      "isAuth",
      "authUser",
      "currentLocation",
      "currentUID",
      "currentOriginalUID",
      "WID",
      "allWorkspaces",
      "currentWorkspace",
      "flyPlatformsType",
      "flyPlatformsCategoryType",
      "currentUser",
      "isAdmin",
      "carCache",
    ]),
    uid() {
      return auth.currentUser?.uid;
    },
    isSmallAndUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isLiveEnv() {
      let domain = window.location.host;
      return !domain.includes('localhost') && !domain.includes('dev');
    },
    isVasaiVisible() {
      let latlong = this.currentLocation.latlong.split(",");
      let distance = this.calculateDistance(
        19.4003,
        72.7777,
        parseFloat(latlong[0]),
        parseFloat(latlong[1])
      );
      return distance <= 25;
    },
    isINR() {
      let { country_code } = this.authUser;
      return country_code && country_code === "IN";
    },
  },
  methods: {
    formatDate,
    formatDateDDMMYYYY,
    connectedHandle,
    formatFileSize,
    formatTS(ts) {
      return formatDate(new Date(ts.seconds));
    },
    getUserRefId(i) {
      let segments = _.get(i, "_key.path.segments", []);
      return segments[segments.length - 1];
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371;
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },
    confirmLogin(path) {
      let user = auth.currentUser;
      if (user == null) {
        this.$confirm.show({
          message: "Login required to perform this action",
          confirmText: "Goto Login",
          cancelText: "Cancel",
          onConfirm: () => {
            this.$router.push({
              name: "login",
              query: {
                redirect: path || this.$route.fullPath,
              },
            });
          },
        });
        return false;
      }
      return true;
    },
    handleError(e) {
      this.isLoading = false;
      this.loading = false;
      this.$loader.hide();
      this.$alert.show(e);
    },
    getUserProfileUrl({ photo_url, display_name }) {
      if (photo_url) return photo_url;
      return `https://ui-avatars.com/api/?background=random&size=128&rounded=true&name=${display_name}`;
    },
    getPostUsers(posts) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const vm = this;
          posts = posts.map((i) => {
            let segments = _.get(i, "userref._key.path.segments", []);
            i.userId = segments[segments.length - 1];
            return i;
          });
          let userIds = posts.map((i) => i.userId);
          userIds = Array.from(new Set(userIds)).filter((i) => i);
          userIds = userIds.filter((i) => vm.users[i] == undefined);
          let peoples = [];
          if (userIds.length != 0) {
            let userQuery = query(colUsers, where(documentId(), "in", userIds));
            let users = (await getDocs(userQuery)).docs;
            users.forEach((i) => {
              vm.users[i.id] = { ...i.data(), id: i.id };
              peoples.push({
                ...i.data(),
                id: i.id,
              });
            });
          }
          if (vm.isAuth) {
            peoples = await vm.getUsersFollow(peoples);
          }
          posts = posts.map((i) => {
            let user = vm.users[i.userId];
            let followUser = peoples.find(
              (u) => u.id == i.userId && u.isFollowing == true
            );
            user.isFollowing = followUser != null;
            return { ...i, user };
          });
          posts = posts.filter((i) => i.user);
          vm.posts = vm.posts.concat(posts);
          resolve(posts);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    getUsersFollow(peoples) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!this.isAuth) {
            peoples = peoples.map((i) => ({ ...i, isFollowing: false }));
            resolve(peoples);
          }
          let requests = [];
          for (let people of peoples) {
            let userRef = doc(colUsers, people.id);
            let followingRef = collection(colUsers, this.uid, "followings");
            let q = query(
              followingRef,
              where("user_ref", "==", userRef),
              limit(1)
            );
            requests.push(
              getDocs(q)
                .then((snap) => {
                  return { id: people.id, value: snap.docs.length == 1 };
                })
                .catch(() => {
                  return { id: people.id, value: false };
                })
            );
          }
          let responses = await Promise.all(requests);
          peoples = peoples.map((i) => {
            let follow = responses.find((r) => r.id == i.id);
            i.isFollowing = follow ? follow.value : false;
            return i;
          });
          resolve(peoples);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
