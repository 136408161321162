import axios from "axios";
import { colUsers, flyplans } from "@/utils/firebase.utils";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useGtm } from "@gtm-support/vue2-gtm";

export default {
  methods: {
    async completeSignup(authUser) {
      try {
        let ipinfo = null;
        for (let i = 0; i < 5; i++) {
          if (Math.random() < 0.2) {
            const ipinfo_url = "https://ipinfo.io/?token=56f9ff0203be46";
            ipinfo = (await axios.get(ipinfo_url)).data;
            break;
          }
        }

        let userDocRef = doc(colUsers, authUser.uid);
        let userWorkspaceRef = doc(
          colUsers,
          authUser.uid,
          "fly_workspaces",
          "default"
        );
        let userDocSnap = await getDoc(userDocRef);
        let userWorkSnap = await getDoc(userWorkspaceRef);
        if (userDocSnap.exists() && userWorkSnap.exists()) {
          let userData = userDocSnap.data();
          if (!userData.country_code && ipinfo) {
            await setDoc(
              userDocRef,
              { country_code: ipinfo.country },
              { merge: true }
            );
          }
        } else {
          let plan = (await getDoc(doc(flyplans, "free"))).data();
          const refCode = sessionStorage.getItem("ref_code");
          let referral_code = "";
          if(refCode){
            const parsedRefCode = JSON.parse(refCode);
            if (parsedRefCode.ref_code) {
              referral_code = parsedRefCode.ref_code;
            }
          }
          let newUserDoc = {
            display_name: authUser.displayName || "",
            email: authUser.email || "",
            photo_url: authUser.photoURL || "",
            phone_number: authUser.phoneNumber,
            web_signup: true,
            created_time: new Date(),
            fly_plan: plan && plan.id ? plan.id : "free",
            fly_storage: 0,
            fly_ai_image_credits: plan.limits.ai_image_actions || 0,
            fly_ai_text_credits: plan.limits.ai_text_actions || 0,
            fly_rzpay_subscribed: false,
            fly_stripe_subscribed: false,
            fly_credits_upd_at: new Date(),
            fly_credits: plan.limits.posts || 0,
            country_code: ipinfo ? ipinfo.country : null,
            is_test_user: false,
            ref_code: referral_code,
          };
          await setDoc(userDocRef, newUserDoc);
          await setDoc(userWorkspaceRef, { name: "default" });
          this.$mixpanel.identify(authUser.email || authUser.phoneNumber);
          this.$mixpanel.track("New Signup");
          const gtm = useGtm();
          gtm.trackEvent({
            event: "Signup Complete",
            category: "conversion",
            action: "sign-up",
            label: "Sign up completed",
            value: 0,
            noninteraction: false,
          });

          // Set flag for new user
          localStorage.setItem('isNewUser', 'true');
        }
                 // Check if the user should be redirected to /fly-lite-go
                 if (sessionStorage.getItem('redirectPath') === 'lite') {
                  localStorage.setItem('lite', 'true');
                }
        this.redirectToCalendar(); // Redirect after setting the flag
      } catch (error) {
        console.error("Signup or login failed:", error);
      }
    },
    // Don't push to calendar immediately, wait for dialog to be closed
    redirectToCalendar() {
      const redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        sessionStorage.removeItem('redirectPath');
        window.location.href = redirectPath;
      } else {
        window.location.href = '/calendar';
      }
    },
  
    getLandingPageURL() {
      return process.env.VUE_APP_FLY_HOST;
    },

    redirectAfterSignup() {
      this.$router.push('/calendar');
    },
    getErrorMessage(error) {
      let message = error.message;
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-credential":
          message = "The credential is invalid or expired. Please try again.";
          break;
        case "auth/invalid-login-credentials":
          message = "The credential is invalid or expired. Please try again.";
          break;
        case "auth/user-disabled":
          message = "The user account has been disabled by an administrator.";
          break;
        case "auth/user-not-found":
          message = "There is no user corresponding to the given identifier.";
          break;
        case "auth/wrong-password":
          message =
            "The password is invalid or the user does not have a password.";
          break;
        case "auth/popup-closed-by-user":
          message =
            "The popup has been closed by the user before completing the sign in.";
          break;
        case "auth/cancelled-popup-request":
          message =
            "This operation has been cancelled due to another conflicting popup being opened.";
          break;
        case "auth/network-request-failed":
          message = "A network error has occurred. Please try again.";
          break;
        case "auth/invalid-verification-code":
          message = "The verification code is invalid. Please try again.";
          break;
        case "auth/invalid-verification-id":
          message = "The verification ID is invalid. Please try again.";
          break;
        case "auth/missing-phone-number":
          message =
            "The phone number is missing. Please enter a valid phone number.";
          break;
        case "auth/quota-exceeded":
          message = "SMS quota exceeded. Please try again later.";
          break;
        case "auth/code-expired":
          message =
            "The verification code has expired. Please request a new code.";
          break;
        case "auth/email-already-in-use":
          return "The email address is already in use by another account.";
        default:
          message = error.message;
          break;
      }
      return message;
    },
  },
};
