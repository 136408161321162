<template>
  <div class="w-full md:min-w-[700px]">
    <div class="w-full">
      <v-card class="w-full">
        <div class="flex">
          <div class="w-full h-auto bg-white rounded-3xl">
            <div class="flex flex-col items-center px-4 py-5 md:pl-0 lg:px-5">
              <div class="flex items-center justify-between w-full">
                <div class="w-full text-sm font-medium text-neutral-900">
                  {{ post.name }}
                </div>
                <div class="flex gap-[14px] items-center mr-4">
                  <div v-if="checkUID && (!post.post_status || (post.post_status && post.post_status !== 'deleted'))"
                    @click="deletePost(post)" class="bg-[#F2F6FA] rounded-md p-[7px] cursor-pointer">
                    <img src="../../assets/img/post-icons/deleteicon.svg" alt="delete" class="w-6" />
                  </div>
                  <div class="bg-[#F2F6FA] cursor-pointer rounded-md p-[7px]" @click="copyLink()">
                    <img src="../../assets/img/post-icons/copyicon.svg" alt="copy" class="w-6" />
                  </div>
                </div>
              </div>
              <div class="text-xs font-normal text-justify w-full px text-slate-500 mt-[10px]">
                {{ post.text }}
              </div>
              <div class="text-slate-500 mt-[14px] text-xs font-normal">
                {{ formatHashtags(post.hashtags) }}
              </div>
              <v-carousel hide-delimiters v-model="carousel" class="mt-1 rounded-lg" v-if="carousels.length"
                :show-arrows="carousels.length > 1" :height="isMobile ? '310px' : 'auto'">
                <v-carousel-item v-for="(item, index) in carousels" :key="index">
                  <div class="h-full d-flex align-center">
                    <v-img :src="item.src" v-if="item.type == 'image'" style="height: 345px; object-fit: cover" />
                    <video-auto-play v-if="item.type == 'video'" :autoplay="false" :videoSrc="item.src"
                      style="height: 345px; object-fit: cover" />
                  </div>
                </v-carousel-item>
              </v-carousel>

              <div class="flex flex-wrap items-center justify-between w-full mt-4 md:px-4">
                <div class="flex gap-[3px]">
                  <v-avatar tile size="24" class="" v-for="(socialMedia, index) in post.social_media" :key="index">
                    <v-img :src="socialMedia.logo_url" :alt="socialMedia.name"></v-img>
                  </v-avatar>
                </div>

                <div class="mt-2 ml-1 text-xs font-normal md:ml-0 text-slate-500 md:mt-0">
                  <p class="" v-if="!post.scheduled_at && post.created_at">
                    <span class="tooltip" data-tooltip="Sent"> </span>
                    {{ formatDate(post.created_at.seconds * 1000) }}
                  </p>
                  <p class="" v-if="post.when_to_post != 'immediate'">
                    <span class="tooltip" data-tooltip="Schedule"> </span>
                    {{
                      post.scheduled_at.seconds
                        ? formatDate(post.scheduled_at.seconds * 1000)
                        : formatDate(post.scheduled_at)
                    }}
                  </p>
                </div>
              </div>
              <div class="status-container mr-7">
                <span v-if="post.post_status === 'deleted'" class="status-deleted text-xs">Deleted</span>
                <span v-else-if="post.post_status === 'draft'" class="status-draft text-xs">Draft</span>
                <span v-else-if="post.when_to_post === 'schedule' && !isPostLive(post)"
                  class="status-scheduled text-xs">Scheduled</span>
                <span v-else-if="post.when_to_post === 'immediate' || (post.scheduled_at && isPostLive(post))"
                  class="status-published text-xs">Published</span>
              </div>
              <div v-if="postedLocationCount" class="text-xs font-medium text-slate-600 mb-2 w-full">
                Posted to Locations: {{ postedLocationCount }} | Break-up below:
                <table class="platform-status-table mt-2 w-full">
                  <thead>
                    <tr>
                      <th class="text-left px-2 py-1">Platform</th>
                      <th class="text-center px-2 py-1">
                        Published
                        <span class="text-xs text-gray-500">
                          ({{ Object.values(platformStatusMap).reduce((sum, stats) => sum + (stats.published || 0), 0)
                          }})
                        </span>
                      </th>
                      <th class="text-center px-2 py-1">
                        Failed
                        <span class="text-xs text-gray-500">
                          ({{ Object.values(platformStatusMap).reduce((sum, stats) => sum + (stats.failed || 0), 0) }})
                        </span>
                      </th>
                      <th class="text-center px-2 py-1">
                        Pending
                        <span class="text-xs text-gray-500">
                          ({{ Object.values(platformStatusMap).reduce((sum, stats) => sum + (stats.pending || 0), 0) }})
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(stats, platform) in platformStatusMap" :key="platform">
                      <td class="px-2 py-1 capitalize">{{ formatPlatformName(platform) }}</td>
                      <td class="text-center px-2 py-1">
                        <span :class="{ 'text-green-600': stats.published > 0 }">{{ stats.published || 0 }}</span>
                      </td>
                      <td class="text-center px-2 py-1">
                        <span :class="{ 'text-red-600': stats.failed > 0 }">{{ stats.failed || 0 }}</span>
                      </td>
                      <td class="text-center px-2 py-1">
                        <div v-if="stats.pending > 0" class="pending-loader-container"
                          :class="{ 'text-blue-600': stats.pending > 0 }">
                          {{ stats.pending || 0 }}
                          <div class="pending-loader"></div>
                        </div>
                        <span v-else :class="{ 'text-blue-600': stats.pending > 0 }">{{ stats.pending || 0 }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex items-center justify-center mt-6">
                <div class="w-[550px] flex gap-[20px] flex-col">
                  <div class="flex gap-10">
                    <button
                      class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                      @click="post.post_status === 'draft'
                        ? editDraft(post.id)
                        : post.when_to_post !== 'immediate' && !isPostLive(post)
                          ? editPost(post.id)
                          : repost(post.id)" :disabled="loading">
                      <img src="../../assets/img/post-icons/repost.svg" alt="" />
                      {{
                        post.post_status === "draft"
                          ? "Edit"
                          : post.when_to_post !== "immediate" && !isPostLive(post)
                            ? "Edit"
                            : "Repost"
                      }}
                    </button>

                    <button v-if="!post.post_status || (post.post_status && post.post_status !== 'deleted')"
                      class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                      @click="showOutput = !showOutput" :disabled="loading">
                      <img src="../../assets/img/post-icons/graph.svg" alt="" />
                      {{ "Stats" }}
                      <v-icon v-if="showOutput">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </button>
                  </div>
                  <div class="flex gap-10">
                    <button
                      v-if="(!post.post_status || (post.post_status && post.post_status !== 'deleted')) && post?.isCurrUser && checkUser && post?.approvedStatus"
                      :class="['w-[100px] flex items-center justify-center gap-2 xl:w-[184px] py-[11px] px-[10px] font-semibold rounded-full', loading ? 'bg-gray-400 text-gray-600' : 'bg-[#1773ea] text-[#e9e9e9]']"
                      @click="approve" :disabled="loading">
                      {{ approveButtonText }}
                    </button>
                    <button
                      v-if="(!post.post_status || (post.post_status && post.post_status !== 'deleted')) && post?.isCurrUser && checkUser && post?.approvedStatus"
                      :class="['w-[100px] flex items-center justify-center gap-2 xl:w-[184px] py-[11px] px-[10px] font-semibold rounded-full', loading ? 'bg-gray-400 text-gray-600' : 'bg-[#FF3E3E] text-[#e9e9e9]']"
                      @click="openRejectDialog = true" :disabled="loading">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showOutput" class="mt-2">
              <post-output :scheduled_at="post.scheduled_at
                ? post.scheduled_at.seconds
                  ? formatDate(post.scheduled_at.seconds * 1000)
                  : post.scheduled_at
                : ''" :postUID="post.uid" :postId="post.id" :postwid="post.wid" :post="post" />
            </div>
          </div>
        </div>
      </v-card>
      <div class="mt-6 w-full">
        <h3 class="text-base font-bold mb-2">Comments</h3>
        <!-- Display Comments Grouped by Date -->
        <div v-if="Object.keys(groupedComments).length"
          class="comment-box h-72 overflow-auto bg-gray-100 p-3 rounded-md" ref="commentBox">
          <!-- Loop through grouped comments by date -->
          <div v-for="(commentsGroup, date) in groupedComments" :key="date" class="flex flex-col mb-4">
            <h4 class="sticky-date">{{ headerDate(date) }}</h4> <!-- Date Header -->
            <div v-for="comment in commentsGroup" :key="comment.id" class="mb-4">
              <span :class="`font-semibold ${checkCommentName(comment) ? 'text-blue-600' : ''}`">{{
                checkCommentName(comment) ? "You" : comment.userName }}: </span>
              <span>{{ comment.text }}</span>
              <small class="text-gray-500 block">{{ commentDate(comment.created_at) }}</small>
            </div>
          </div>
        </div>


        <div v-else class="text-gray-500 mb-4">No comments yet. Be the first to comment.</div>

        <!-- Add Comment -->
        <div class="flex items-end gap-2 mt-4">
          <v-textarea v-model="newComment" label="Add a comment" rows="2" outlined dense></v-textarea>
          <v-icon class="mb-7 send-icon" @click="postComment" :disabled="loading || !newComment">mdi-send</v-icon>
        </div>
      </div>

    </div>
    <v-dialog v-model="openRejectDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Reject Comment</span>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="rejectComment" label="Comment" rows="3" outlined></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="handleReject">Reject</v-btn>
          <v-btn class="primary" text @click="openRejectDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth, colUsers, colScheduledPostsDelete, colSocialPostsDelete, storage, db } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import VideoAutoPlay from "../public/VideoAutoPlay.vue";
import PostOutput from "./PostOutput.vue";
import { deleteDoc, doc, getDoc, updateDoc, setDoc, collection, query, addDoc, onSnapshot, orderBy, limit, startAfter } from "firebase/firestore";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import moment from "moment";
import { formatDistanceToNow, isToday, isYesterday } from "date-fns";
export default {
  components: { VideoAutoPlay, PostOutput },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    openOutput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newComment: "", // For adding a new comment
      groupedComments: {},
      autoScroll: false,
      comments: [], // List of comments
      lastVisible: null, // to track the last visible comment for pagination
      lastScrollHeight: null,
      isFetching: false,
      intervalId: null,
      carousel: 0,
      showOutput: false,
      draftLoading: false,
      checkUser: false,
      loading: false,
      approveButtonText: "",
      openRejectDialog: false,
      rejectComment: "",
      route_postId: "",
      route_wid: "",
      route_uid: "",
      unsubscribers: [], // Add this to store cleanup functions
      platformStatusMap: {},
    };
  },
  watch: {
    comments: {
      immediate: true,
      handler(newComments) {
        this.groupedComments = this.groupCommentsByDate(newComments);
      },
    },
  },
  computed: {
    postedLocationCount() {
      return this.post.location_postIds ? this.post.location_postIds.length : 0;
    },
    checkUID() {
      if (auth.currentUser.uid == this.$route.params.uid || (this.post.post_status == 'draft' && (this.post.postBy == auth.currentUser.uid))) {
        return true;
      }
      else return false;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.thumbnail || item.url || item,
            type: "image",
          });
        });
      }
      if (this.$route.params?.uid === this.currentOriginalUID) {
        this.checkUser = true;
        if (this.post.scheduled_at) this.approveButtonText = "Approve and Schedule"
        else this.approveButtonText = "Approve and Publish"
      }
      return list;
    },
    hasIg() {
      return (
        this.post.social_media?.includes("instagram") ||
        this.post.social_media?.some((social) => social.name === "instagram")
      );
    },
    hasTh() {
      return (
        this.post.social_media?.includes("threads") ||
        this.post.social_media?.some((social) => social.name === "threads")
      );
    },
    platformStats() {
      if (!this.post.location_postIds) return null;

      const stats = {};
      console.log("Fetching location stats..");
      this.post.location_postIds.forEach(locationPost => {

        if (locationPost.platform) {
          if (!stats[locationPost.platform]) {
            stats[locationPost.platform] = {
              total: 0,
              published: 0,
              failed: 0,
              pending: 0
            };
          }

          stats[locationPost.platform].total++;

          // Use the fetched status from platformStatusMap
          const status = this.platformStatusMap[`${locationPost.location_id}_${locationPost.platform}`];
          // if (status) {
          // switch (status.toLowerCase()) {
          // case 'published':
          // stats[locationPost.platform].published++;
          // break;
          // case 'posted':
          // stats[locationPost.platform].published++;
          // break;
          // case 'failed':
          // stats[locationPost.platform].failed++;
          // break;
          // default:
          // //stats[locationPost.platform].pending++;
          // }
          // } else {
          // //stats[locationPost.platform].pending++;
          // }
        }
      });

      return Object.keys(stats).length ? stats : null;
    },
  },
  methods: {
    deleteUnnecessaryFields(data, social_media) {
      if (!social_media) return data;
      if (!social_media.includes("youtube")) {
        delete data.configuration.yt_thumb;
        delete data.configuration.yt_post_type;
        delete data.configuration.yt_tags;
        delete data.configuration.yt_category;
        delete data.configuration.yt_title;
        delete data.configuration.yt_license;
      }
      if (!social_media.includes("facebook")) {
        delete data.configuration.fb_post_type;
        delete data.configuration.fb_pages;
      }
      if (!social_media.includes("instagram")) {
        delete data.configuration.ig_post_type;
        delete data.configuration.ig_users;
      }
      if (!social_media.includes("tumblr")) {
        delete data.configuration.blog;
      }
      if (!social_media.includes("reddit")) {
        delete data.configuration.subreddit;
      }
      if (!social_media.includes("pinterest")) {
        delete data.configuration.board;
        delete data.configuration.cover_image_url;
      }
      return data;
    },
    async handleYoutubeThumbnail(thumbnail) {
      let vm = this;
      let thumbType = typeof thumbnail;
      let storageRef;
      let hasYoutube = vm.post.social_media.includes("youtube");
      if (
        hasYoutube &&
        thumbType === "object" &&
        thumbnail &&
        thumbnail instanceof File
      ) {
        if (vm.route_wid === "default" && vm.currentOriginalUID === vm.route_uid) {
          storageRef = ref(
            storage,
            `users/${vm.route_uid}/youtube_thumbs/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.route_uid}/${vm.route_wid}/youtube_thumbs/${new Date().getTime()}.png`
          );
        }
        await uploadBytes(storageRef, thumbnail);
        thumbnail = await getDownloadURL(storageRef);
        return thumbnail;
      }
    },
    handleScheduleAt(scheduledAt) {
      if (typeof scheduledAt === 'string') {
        return scheduledAt.trim();
      } else if (typeof scheduledAt === 'object' && scheduledAt.seconds) {
        return new Date(scheduledAt.seconds * 1000).toISOString();
      } else if (typeof scheduledAt === 'number') {
        return new Date(scheduledAt).toISOString();
      }
      return '';
    },
    async approve() {
      let date = new Date();
      date = moment(date).format("YYYY-MM-DD");
      this.$loader.show()
      this.loading = true;
      const vm = this;
      try {
        const data = { ...this.post, id: this.route_postId };
        const dataId = data.id
        const updatedImages = this.post.images?.map((element) => {
          if (typeof element === "object" && element.url) {
            return element.thumbnail;
          }
          return element;
        });
        data.images = updatedImages;
        const updatedVideos = this.post.videos?.map((element) => {
          if (typeof element === "object" && element.url) {
            return element.url;
          }
          return element;
        });
        data.videos = updatedVideos;
        if (data.when_to_post === "immediate") {
          data.scheduled_at = "";
        }

        let hasYoutube = data.social_media.includes("youtube");
        const thumbnail = await this.handleYoutubeThumbnail(data.configuration.yt_thumb);

        if (data.scheduled_at) {
          // console.log("this is before")
          data.scheduled_at = await this.handleScheduleAt(data.scheduled_at);
          if (!hasYoutube) {
            if (data.configuration.yt_thumb) {
              delete data.configuration.yt_thumb;
            }
            delete data.configuration.yt_title;
            delete data.configuration.yt_tags;
            delete data.configuration.yt_category;
          }
          let deleteScheduledPostData = {
            uid: data.uid,
            wid: data.wid,
            postId: vm.route_postId,
            deleted_at: new Date(),
            was_scheduled_for: new Date(data.scheduled_at),
            action: "edit",
          };
          date = moment(data.scheduled_at).format("YYYY-MM-DD");
          const calendarRef = doc(
            colUsers,
            vm.route_uid,
            "fly_workspaces",
            vm.route_wid,
            "calendar",
            date,
            "posts",
            vm.route_postId
          );

          await deleteDoc(calendarRef);
          await setDoc(
            doc(colScheduledPostsDelete, vm.route_postId),
            deleteScheduledPostData
          );
        }
        if (data.approvedStatus) {
          data.approvedStatus = false;
        }

        if (data?.post_status == 'draft') {
          if (data.when_to_post == 'immediate') data.post_status = 'published'
          else data.post_status = 'scheduled'
        }
        const modifiedData = this.deleteUnnecessaryFields(data, data.social_media);
        let addSocialPost = httpsCallable(functions, "addSocialPost");
        let { postId } = (await addSocialPost(modifiedData)).data;
        vm.route_postId = postId
        let draftPostRef = doc(colUsers, vm.route_uid, "fly_workspaces", this.route_wid, "social-posts", dataId);
        let draftSnap = await getDoc(draftPostRef);
        if (draftSnap.exists()) {
          await deleteDoc(draftPostRef);
        }
        const calendarRef = doc(
          colUsers,
          vm.route_uid,
          "fly_workspaces",
          this.route_wid,
          "calendar",
          date
        );
        const postDocData = (
          await getDoc(
            doc(colUsers, vm.route_uid, "fly_workspaces", vm.route_wid, "social-posts", postId)
          )
        ).data();
        const calendarSnap = await getDoc(calendarRef);
        if (calendarSnap.exists()) {
          await setDoc(doc(calendarRef, "posts", postId), postDocData);
        } else {
          await setDoc(doc(calendarRef, "posts", postId), postDocData);
        }
        let nameRef = doc(colUsers, this.currentOriginalUID);
        let namedoc = await getDoc(nameRef);

        let postRef = doc(colUsers, this.route_uid, "fly_workspaces", this.route_wid, "social-posts", postId);
        let postData = (await getDoc(postRef)).data();

        this.$pushNotification({
          uid: this.post.postBy,
          wid: this.route_wid,
          payload: {
            reply: true,
            sendPostMail: true,
            recieverUID: this.post.postBy,
            name: namedoc.data().display_name,
            image: postData.images[0],
            message: `${namedoc.data().display_name} approved your request on a post`,
            title: "Post approved",
            token: this.post.postBy,
            url: `/post/${this.route_uid}/${this.route_wid}/${postId}`,
            isEmail: true,
            status: true,
            text: data.text,
          }
        });

        this.$router.replace(`/post/${this.route_uid}/${this.route_wid}/${postId}`);
        this.$nextTick(() => {
          location.reload();
        });
        this.$loader.hide();
        vm.loading = false;
      } catch (error) {
        this.$router.push(`/`);
        vm.loading = false;
        vm.handleError(error);
      }
      // this.draftLoading = false;
    },
    async handleReject() {
      this.newComment = this.rejectComment;
      await this.postComment();
      const data = { ...this.post, id: this.route_postId };
      const dataId = data.id

      let nameRef = doc(colUsers, this.currentOriginalUID);
      let namedoc = await getDoc(nameRef);
      this.openRejectDialog = false;

      this.$loader.show();

      this.openRejectDialog = false;

      let draftPostRef = doc(colUsers, this.route_uid, "fly_workspaces", this.route_wid, "social-posts", dataId);
      let draftSnap = await getDoc(draftPostRef);
      if (draftSnap.exists()) {
        this.$pushNotification({
          uid: this.post.postBy,
          wid: this.route_wid,
          payload: {
            reply: true,
            sendPostMail: true,
            recieverUID: this.post.postBy,
            name: namedoc.data().display_name,
            image: this.post.images[0],
            message: `${namedoc.data().display_name} Rejected approval on a post`,
            title: "Post Rejected",
            token: this.route_uid,
            url: `/post/${this.route_uid}/${this.route_wid}/${this.route_postId}`,
            isEmail: true,
            comment: this.rejectComment,
            text: this.post.post_text,
          }
        });
      }
      this.$loader.hide();
      this.$router.push(`/`);

    },
    formatHashtags(tags) {
      if (tags) {
        return tags
          .map((i) => {
            if (i.toString().startsWith("#")) return i;
            return `#${i}`;
          })
          .map((i) => i.split(" ").join(""))
          .toString()
          .split(",")
          .join(" ");
      }
      return '';
    },
    copyLink() {
      let uid = this.post.uid || this.$route.params.uid;
      let wid = this.post.wid || this.$route.params.wid || this.WID;
      let id = this.post.id || this.$route.params.id;
      const route = this.$router.resolve({
        name: "post",
        params: {
          uid: uid,
          wid: wid,
          id: id,
        },
      });
      let url = `${location.origin}${route.href}`;
      navigator.clipboard.writeText(url);
      this.$snackbar.show("URL Copied.");
    },
    isPostLive(post) {
      const currentDate = new Date();
      const scheduledDate = new Date(post.scheduled_at);
      if (post.scheduled_at) {
        return scheduledDate.getTime() < currentDate.getTime();
      }
      return true;
    },
    editPost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "edit-post",
        params: {
          postId: id,
        },
      });

    },
    getScheduledDate(scheduledDate) {
      if (typeof scheduledDate == "object" && scheduledDate.seconds) {
        return this.formatDate(scheduledDate.seconds * 1000);
      } else if (typeof scheduledDate === "string") {
        return this.formatDate(scheduledDate);
      } else {
        return scheduledDate;
      }
    },
    editDraft(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "draft-post",
        params: {
          postId: id,
        },
      });
    },
    repost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "repost",
        params: {
          postId: id,
        },
      });
    },
    deletePost(post) {
      let postId = this.$route.params.id ? this.$route.params.id : post.id;
      let data = {
        uid: post.uid,
        wid: post.wid,
        postId: postId,
        deleted_at: new Date()
      };
      let message = '';
      let isPostPublished = this.isPostLive(post);
      try {
        if (this.hasIg && isPostPublished) {
          message = 'Instagram: Post needs to be deleted manually';
        }
        if (this.hasTh && isPostPublished) {
          message = 'Threads: Post needs to be deleted manually';
        }
        this.$confirm.show({
          message: `Are you sure you want to delete this post?`,
          note: message,
          onConfirm: async () => {
            const postRef = doc(colUsers, post.uid, "fly_workspaces", post.wid, "social-posts", postId);
            try {
              if (post.post_status === "draft") {
                this.$loader.show(`Deletion in progress`);
                updateDoc(postRef, {
                  post_status: 'deleted',
                });
                this.$snackbar.show("Post deleted successfully!");
                this.$emit("onPostDelete");
                this.$loader.hide();
                return;
              } else if (post.scheduled_at && !isPostPublished) {
                data.was_scheduled_for = new Date(post.scheduled_at);
                data.action = 'delete';
                this.$snackbar.show("Your scheduled posts have been marked for deletion.");
                await updateDoc(postRef, {
                  post_status: 'deleted',
                });
                await setDoc(doc(colScheduledPostsDelete, postId), data);
              } else {
                this.$snackbar.show("Your published posts have been marked for deletion.");
                await updateDoc(postRef, {
                  post_status: 'deleted',
                });
                await setDoc(doc(colSocialPostsDelete, postId), data);
              }
            } catch (error) {
              this.$loader.hide();
              console.error("Error calling Cloud Function:", error);
              this.$alert.show("Something went wrong");
            }
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
    fetchComments() {
      // pagination optimize of scroll comments

      // if (this.isFetching) return; // prevent multiple requests
      // this.isFetching = true;

      // const commentsRef = collection(db, 'users', this.route_uid, 'fly_workspaces', this.route_wid, 'social-posts', this.route_postId, 'comments');
      // let q;

      // if (initial) {
      // // For initial fetch, get the latest 15 comments
      // q = query(commentsRef, orderBy('created_at', 'desc'), limit(15));
      // } else if (this.lastVisible) {
      // // Fetch more comments starting after the last visible comment
      // q = query(commentsRef, orderBy('created_at', 'desc'), startAfter(this.lastVisible), limit(15));
      // }

      // onSnapshot(q, (snapshot) => {
      // const fetchedComments = snapshot.docs.map((doc) => ({
      // id: doc.id,
      // ...doc.data(),
      // }));

      // if (initial) {
      // // For the initial fetch, replace the existing comments
      // this.comments = fetchedComments.reverse(); // Reverse to show oldest at the top
      // } else {
      // // For additional comments, prepend to the existing list
      // this.comments = [...fetchedComments.reverse(), ...this.comments];
      // }

      // // Track the last visible comment for pagination
      // if (snapshot.docs.length > 0) {
      // this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
      // }

      // this.isFetching = false;

      // if (initial && this.autoScroll) {
      // this.$nextTick(() => {
      // this.scrollToBottom(); // Scroll to the bottom after DOM updates
      // });
      // }
      // });
      const commentsRef = collection(db, 'users', this.route_uid, 'fly_workspaces', this.route_wid, 'social-posts', this.route_postId, 'comments');
      const q = query(commentsRef, orderBy('created_at', 'asc'));

      onSnapshot(q, (snapshot) => {
        this.comments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (this.autoScroll) {
          this.$nextTick(() => {
            this.scrollToBottom(); // Scroll to the bottom after DOM updates
          });
        }
      });
    },

    // Scroll event to detect when the user scrolls up and load more comments
    // onScroll() {
    // const container = this.$refs.commentBox;
    // // console.log(container.scrollTop,"scroll top this is");
    // if (container.scrollTop === 0 && !this.isFetching) {
    // // When user scrolls to the top, fetch the next batch of comments
    // this.fetchComments(false); // Fetch more comments
    // }
    // },
    groupCommentsByDate(comments) {
      const grouped = {};
      comments.forEach(comment => {
        const commentDate = new Date(comment.created_at.seconds * 1000); // Convert Firestore timestamp
        const dateKey = isToday(commentDate)
          ? "Today"
          : isYesterday(commentDate)
            ? "Yesterday"
            : commentDate.toLocaleDateString(); // Get date in 'mm/dd/yyyy'

        if (!grouped[dateKey]) {
          grouped[dateKey] = [];
        }

        grouped[dateKey].push(comment);
      });

      return grouped;
    },
    headerDate(date) {
      if (date == 'Yesterday' || date == 'Today') return date;
      const [month, day, year] = date.split('/');
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      // Ensure the day is always two digits
      const formattedDay = ("0" + day).slice(-2);

      // Convert the month number to month name (subtract 1 since months array is 0-indexed)
      const formattedMonth = months[month - 1];

      return `${formattedDay} ${formattedMonth} ${year}`;
    },
    async postComment() {
      try {
        this.loading = true;
        this.autoScroll = true;
        const commentsRef = collection(db, 'users', this.route_uid, "fly_workspaces", this.route_wid, "social-posts", this.route_postId, "comments");
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        // Get the document ID
        const userId = userDocSnapshot.id;

        // Get the document data
        const userData = userDocSnapshot.data();
        const currUserName = userData?.display_name || "Anonymous";
        await addDoc(commentsRef, {
          uid: userId,
          text: this.newComment,
          userName: currUserName, // Assuming you have the user's name in Vuex store
          created_at: new Date(), // Firebase timestamp
        });

        this.newComment = ""; // Clear the input
      } catch (error) {
        console.error("Error adding comment: ", error);
      } finally {
        this.loading = false;
      }
    },
    checkCommentName(comment) {
      if (auth.currentUser.uid == comment.uid) return true;
      else return false;
    },
    scrollToBottom() {
      // Ensure that the commentBox ref exists
      this.autoScroll = false;
      const commentBox = this.$refs.commentBox;
      if (commentBox) {
        commentBox.scrollTop = commentBox.scrollHeight; // Scroll to the bottom
      }
    },
    commentDate(timestamp) {
      // Check if the timestamp is a Firestore Timestamp and convert it to JS Date
      if (timestamp && typeof timestamp.seconds === 'number') {
        timestamp = new Date(timestamp.seconds * 1000);
      }

      const now = new Date();
      const secondsDiff = (now - timestamp) / 1000; // Get the time difference in seconds

      // Show granular "x seconds ago" for comments within the last minute
      if (secondsDiff < 60) {
        // Round to the nearest 5 seconds
        const roundedSeconds = Math.round(secondsDiff / 5) * 5;
        return `${roundedSeconds} seconds ago`;
      }

      // If the comment is from today, show the time in "HH:mm" format
      if (isToday(timestamp)) {
        return timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }

      // If the comment is from yesterday, show "Yesterday HH:mm"
      if (isYesterday(timestamp)) {
        return timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }

      // For older comments, show the date and time in "DD/MM/YYYY HH:mm" format
      return timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    updateCommentTimes() {
      this.comments = [...this.comments]; // Trigger reactivity for comment timestamps
    },
    async setupLocationStatusListeners() {
      this.cleanupListeners();

      if (!this.post.location_postIds || !this.post.uid) return;

      for (const locationPost of this.post.location_postIds) {
        try {
          const socialPostRef = doc(
            colUsers,
            this.post.uid,
            "fly_workspaces",
            locationPost.location_id,
            "social-posts",
            locationPost.post_id
          );

          const unsubscribe = onSnapshot(socialPostRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const postData = docSnapshot.data();

              const perPlatformCounts = {};

              if (postData.output) {
                Object.keys(postData.output).forEach((platform) => {
                  if (!perPlatformCounts[platform]) {
                    perPlatformCounts[platform] = {
                      published: 0,
                      failed: 0,
                      pending: 0,
                    };
                  }

                  const platformData = postData.output[platform];

                  if (Array.isArray(platformData?.data)) {
                    platformData.data.forEach((item) => {
                      const status = item?.status?.toLowerCase();
                      if (status === "published" || status === "posted") {
                        perPlatformCounts[platform].published += 1;
                      } else if (status === "error") {
                        perPlatformCounts[platform].failed += 1;
                      } else {
                        perPlatformCounts[platform].pending += 1;
                      }
                    });
                  } else {
                    const status = platformData?.status?.toLowerCase();
                    if (status === "published" || status === "posted") {
                      perPlatformCounts[platform].published += 1;
                    } else if (status === "error") {
                      perPlatformCounts[platform].failed += 1;
                    } else {
                      perPlatformCounts[platform].pending += 1;
                    }
                  }
                });
              }

              // Update the per-post platform counts
              this.$set(
                this.platformStatusPerPost,
                locationPost.post_id,
                perPlatformCounts
              );

              // Recompute the overall platformStatusMap
              this.recomputePlatformStatusMap();
            }
          });

          // Store the unsubscribe function
          this.unsubscribers.push(unsubscribe);
        } catch (error) {
          console.error(
            "Error setting up listener for location:",
            locationPost.location_id,
            error
          );
        }
      }
    },
    recomputePlatformStatusMap() {
      const combinedPlatformCounts = {};

      // Loop over each post's platform counts
      Object.values(this.platformStatusPerPost).forEach((perPlatformCounts) => {
        // For each platform in this post
        Object.keys(perPlatformCounts).forEach((platform) => {
          if (!combinedPlatformCounts[platform]) {
            combinedPlatformCounts[platform] = {
              published: 0,
              failed: 0,
              pending: 0,
            };
          }

          combinedPlatformCounts[platform].published +=
            perPlatformCounts[platform].published;
          combinedPlatformCounts[platform].failed +=
            perPlatformCounts[platform].failed;
          combinedPlatformCounts[platform].pending +=
            perPlatformCounts[platform].pending;
        });
      });

      // Update the platformStatusMap with the combined counts
      this.platformStatusMap = combinedPlatformCounts;
    },
    cleanupListeners() {
      // Cleanup all existing listeners
      this.unsubscribers.forEach((unsubscribe) => unsubscribe());
      this.unsubscribers = [];
      this.platformStatusPerPost = {};
      this.platformStatusMap = {};
    },
    formatPlatformName(platform) {
      if (platform === 'google_business_profile') {
        return 'Google Business Profile';
      }
      return platform;
    },
  },
  mounted() {
    const { uid, wid, id } = this.$route.params
    this.route_uid = uid;
    this.route_wid = wid;
    this.route_postId = id;
    this.autoScroll = true;
    this.fetchComments(true);
    this.intervalId = setInterval(() => {
      this.updateCommentTimes();
    }, 30000);
  },
  watch: {
    post: {
      immediate: true,
      deep: true,
      handler(newPost) {
        console.log('Post data changed:', newPost);
        if (newPost && newPost.location_postIds) {
          this.setupLocationStatusListeners();
        }
      }
    }
  },
  beforeMount() {
    this.showOutput = this.openOutput == true;
  },
  beforeDestroy() {
    // Clean up listeners when component is destroyed
    this.cleanupListeners();
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
.status-published {
  color: #8056DE;
}

.status-deleted {
  color: red;
}

.status-scheduled {
  color: green;
}

.status-draft {
  color: orange;
}

.status-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-disabled {
  background-color: #BDBDBD !important;
  color: #757575 !important;
}

.v-icon.send-icon {
  color: #1773ea !important;
  /* Use !important to force override */
}

.sticky-date {
  display: inline-block;
  /* Makes the width adjust dynamically based on text */
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  padding: 0.2rem 1rem;
  /* Padding for better appearance */
  border-radius: 20px;
  /* Rounded corners */
  text-align: center;
  /* Center the text inside */
  z-index: 10;
  font-size: 0.9rem;
  margin: 0 auto;
  /* Center it horizontally within the comment box */
}

.platform-stats {
  border-top: 1px solid #eee;
  padding-top: 0.5rem;
}

.platform-stat-item {
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: #f8f9fa;
}

.platform-status-table {
  border-collapse: collapse;
  font-size: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 0.375rem;
}

.platform-status-table th {
  background-color: #edf2f7;
  font-weight: 600;
}

.platform-status-table td,
.platform-status-table th {
  border: 1px solid #e2e8f0;
}

.pending-loader-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.pending-loader {
  width: 24px;
  height: 2px;
  background-color: #8056DE;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.pending-loader::after {
  content: '';
  position: absolute;
  width: 30%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  animation: pendingAnimation 1s infinite ease-in-out;
}

@keyframes pendingAnimation {
  0% {
    left: -30%;
  }

  100% {
    left: 100%;
  }
}
</style>