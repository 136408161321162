<template>
    <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
      <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
        <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
          <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
            <img src="../../assets/img/sidebar/post.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
            <div class="flex items-center gap-1">
              <p class="text-base md:text-[22px] font-bold">
                Posts <span class="md:hidden">></span>
              </p>
            </div>
          </div>
        </div>
        <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10">
          <div class="bg-[#F2F6FA] pb-2 pt-2 md:pt-[33px] px-2 lg:px-5 md:rounded-3xl">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4 posts">
              <post-card v-for="(post, i) in posts" :post="post" :key="i"></post-card>
            </div>
            <infinite-loading ref="loader" @infinite="fetchPosts">
              <div slot="spinner">
                <v-skeleton-loader class="mx-auto" type="image,article"></v-skeleton-loader>
              </div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  import InfiniteLoading from "vue-infinite-loading";
  import { auth, platforms } from "@/utils/firebase.utils.js";
  import {
    startAfter,
  } from "firebase/firestore";
  import { colUsers } from "@/utils/firebase.utils";
  import moment from "moment";
  import PostCard from "@/components/post/Pc.vue";
  import { functions } from "@/utils/firebase.utils";
  import { httpsCallable } from "firebase/functions";
  export default {
    components: {
      PostCard,
      InfiniteLoading,
    },
    data() {
      return {
        posts: [],
        isLoading: false,
        startAfter: null,
      };
    },
    methods: {
      async fetchPosts($state) {
        const vm = this;
        let currentUID = this.currentUID || vm.uid;
        if (!vm.currentUID && !vm.WID) return;
        try {
          vm.isLoading = true;
          let fetchPostss = httpsCallable(functions, "instagramCrossPostsFetch");
          let result = (
            await fetchPostss({
              uid: this.uid,
              wid: this.WID,
              since: "test_user",
            })
          ).data;
          let posts = result;
          vm.posts = [...posts];
          console.log("vm.posts:", vm.posts);
          $state.complete();
          vm.isLoading = false;
        } catch (error) {
          $state.complete();
          vm.handleError(error);
        }
      },
    },
    mounted() {
      this.fetchPosts()
    },
  };
  </script>
  <style scoped>
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
    z-index: 1;
  }
  
  .tooltip:hover::before {
    opacity: 1;
  }
  
  .custom-tab {
    min-width: 0;
    /* Allow the tabs to shrink */
    padding: 8px 16px;
    /* Adjust the padding to make them smaller */
    white-space: nowrap;
    /* Prevent text from wrapping */
    text-overflow: ellipsis;
    /* Add ellipsis if the text overflows */
    overflow: hidden;
    /* Hide any overflow */
  }
  
  .rounded-btn {
    border-radius: 50%;
    position: fixed;
    bottom: 16px;
    right: 16px;
    /* width:50px;
      height: 50px; */
  }
  
  .onboarding-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  
  .onboarding-step {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .onboarding-step .number {
    width: 24px;
    /* Adjust the width to make the circle smaller */
    height: 24px;
    /* Adjust the height to make the circle smaller */
    border-radius: 50%;
    background-color: #2196F3;
    /* Blue color for the numbers */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /* Adjust the font size if needed */
    margin-right: 12px;
  }
  
  .onboarding-step span {
    font-size: 18px;
    margin-right: 12px;
    /* Add margin to create space between the circle and text */
  }
  </style>