<template>
  <div>
    <div class="p-4 pb-0 flex">
      <p class="mt-2 text-lg font-semibold text-[#121212]">Cumulated Stats</p>
    </div>
    <div
      class="bg-white p-4 rounded-lg shadow-md mt-4 max-w-[1400px] w-[98%] mx-2"
    >
      <div class="overflow-x-auto">
        <div class="min-w-[1000px]">
          <div class="flex justify-between">
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Maps
                <tool-tip :message="getTooltipMessage('maps', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("maps") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Search
                <tool-tip :message="getTooltipMessage('search', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("search") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Direction Requests
                <tool-tip
                  :message="getTooltipMessage('directions', 'combined')"
                >
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{
                  getTotalMetric("directions")
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Call Clicks
                <tool-tip :message="getTooltipMessage('calls', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("calls") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Website Clicks
                <tool-tip :message="getTooltipMessage('website', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("website") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Bookings
                <tool-tip :message="getTooltipMessage('bookings', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("bookings") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Food Orders
                <tool-tip
                  :message="getTooltipMessage('food_orders', 'combined')"
                >
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{
                  getTotalMetric("food_orders")
                }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
            <div class="border-l border-gray-300 mx-4"></div>
            <div class="flex-1">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                Food Menu Clicks
                <tool-tip :message="getTooltipMessage('food_menu', 'combined')">
                  <v-icon small>mdi-information</v-icon>
                </tool-tip>
              </p>
              <p class="text-lg font-bold whitespace-nowrap">
                <span v-if="!isLoading">{{ getTotalMetric("food_menu") }}</span>
                <span v-else-if="isLoading" class="skeleton-loader"></span>
                <span v-else>--</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="flex flex-col items-center justify-center w-full pb-20">
      <section
        class="flex flex-col items-center w-full justify-center xl:w-[100%]"
      >
        <div
          class="mt-[18px] w-full xl:w-[100%] flex justify-center items-center gap-4"
        >
          <div class="w-full bg-white border md:rounded-3xl">
            <div class="flex flex-col justify-between w-full px-5 py-2 mx-auto">
              <p class="mt-2 text-lg font-semibold text-[#121212]">
                Store Performance
              </p>

              <div class="relative flex items-center gap-2 grow">
                <button
                  @click="toggleStoreDropdown($event)"
                  class="flex items-center gap-2 p-2 border border-gray-300 rounded"
                >
                  Locations
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  ref="showStoreDropdown"
                  v-if="showStoreDropdown"
                  class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
                >
                  <div
                    v-for="profile in stores"
                    :key="profile.wid"
                    class="platform p-2"
                  >
                    <div class="flex items-center gap-2">
                      <input
                        type="checkbox"
                        v-model="profile.checked"
                        @change="fetchStorePosts(profile)"
                      />
                      <!-- <span v-if="profile?.type == 'admin'" class="font-semibold"
                        >Admin (You)</span
                      >
                      <div v-else> -->
                      <div
                        v-if="profile?.type != 'admin'"
                        class="flex flex-col"
                      >
                        <span class="font-medium">{{ profile.name }}</span>
                        <span>{{ profile.city }}</span>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="p-4 bg-white shadow-lg rounded-lg max-w-[1500px] my-4"
              >
                <div class="overflow-x-auto w-full">
                  <div v-if="isLoading">
                    <v-skeleton-loader
                      type="table"
                      class="mb-4"
                      boilerplate
                    ></v-skeleton-loader>
                  </div>
                  <div
                    v-else-if="!metricsData || metricsData?.length === 0"
                    class="flex justify-center items-center py-8"
                  >
                    <p class="text-gray-500">No data available</p>
                  </div>
                  <div v-else class="">
                    <div
                      class="bg-gray-100 min-w-[1000px] rounded-t-lg text-xs w-full"
                    >
                      <div
                        class="flex items-center justify-between text-gray-600 p-2 flex-wrap"
                      >
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Location
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Maps <v-icon small class="pr-1">mdi-web</v-icon>
                          <tool-tip
                            :message="getTooltipMessage('maps', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Maps <v-icon small>mdi-cellphone</v-icon>
                          <tool-tip
                            :message="getTooltipMessage('maps', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Search <v-icon small class="pr-1">mdi-web</v-icon>
                          <tool-tip
                            :message="getTooltipMessage('search', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Search
                          <v-icon small class="pr-1">mdi-cellphone</v-icon>
                          <tool-tip
                            :message="getTooltipMessage('search', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div
                          class="w-1/12 lg:w-1/6 text-center whitespace-nowrap px-2"
                        >
                          Direction<br class="d-md-none" />
                          Requests
                          <tool-tip :message="getTooltipMessage('directions')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Call<br class="d-sm-none" />
                          Clicks
                          <tool-tip :message="getTooltipMessage('calls')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Website <br class="d-sm-none" />
                          Clicks
                          <tool-tip :message="getTooltipMessage('website')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Bookings
                          <tool-tip :message="getTooltipMessage('bookings')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Food <br class="d-sm-none" />Orders
                          <tool-tip :message="getTooltipMessage('food_orders')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                        <div class="w-1/12 text-center whitespace-nowrap px-2">
                          Food Menu <br class="d-md-none" />Clicks
                          <tool-tip :message="getTooltipMessage('food_menu')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                        </div>
                      </div>
                    </div>

                    <div class="min-w-[1000px]">
                      <div
                        v-for="metric in metricsData"
                        :key="metric.location.wid"
                        class="border-b border-gray-200"
                      >
                        <div
                          v-if="metric.location.name"
                          class="flex justify-between items-center w-full text-xs bg-white hover:bg-gray-100 py-2"
                        >
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.location.name }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_maps_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_maps_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_search_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_search_impressions }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.direction_requests }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.call_clicks }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.website_clicks }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.bookings }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_orders }}
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_menu_clicks }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";

export default {
  components: { ToolTip },
  props: {},
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
    };
  },
  methods: {
    getTotalMetric(metricType) {
      if (!this.metricsData || !Array.isArray(this.metricsData)) return 0;

      return this.metricsData.reduce((total, metric) => {
        switch (metricType) {
          case "maps":
            return (
              total +
              (metric.desktop_maps_impressions || 0) +
              (metric.mobile_maps_impressions || 0)
            );
          case "search":
            return (
              total +
              (metric.desktop_search_impressions || 0) +
              (metric.mobile_search_impressions || 0)
            );
          case "directions":
            return total + (metric.direction_requests || 0);
          case "calls":
            return total + (metric.call_clicks || 0);
          case "website":
            return total + (metric.website_clicks || 0);
          case "bookings":
            return total + (metric.bookings || 0);
          case "food_orders":
            return total + (metric.food_orders || 0);
          case "food_menu":
            return total + (metric.food_menu_clicks || 0);
          default:
            return total;
        }
      }, 0);
    },
    getTooltipMessage(metric, type = "default") {
      const messages = {
        maps: {
          desktop: "Business impressions on Google Maps on Desktop devices",
          mobile: "Business impressions on Google Maps on Mobile devices",
          combined: "Total Maps impressions across all locations",
        },
        search: {
          desktop: "Business impressions on Google Search on Desktop devices",
          mobile: "Business impressions on Google Search on Mobile devices",
          combined: "Total Search impressions across all locations",
        },
        directions: {
          default: "Total direction requests across all locations",
          combined: "Total direction requests across all locations",
        },
        calls: {
          default:
            "The number of times the business profile call button was clicked",
          combined: "Total call clicks across all locations",
        },
        website: {
          default:
            "The number of times the business profile website was clicked",
          combined: "Total website clicks across all locations",
        },
        bookings: {
          default:
            "The number of bookings made from the business profile via Reserve with Google",
          combined: "Total bookings across all locations",
        },
        food_orders: {
          default:
            "The number of food orders received from the business profile",
          combined: "Total food orders across all locations",
        },
        food_menu: {
          default:
            "The number of clicks to view or interact with the menu content on the business profile",
          combined:
            "Total number of clicks to interact with the menu content across all locations",
        },
      };

      // Return appropriate message based on metric and type
      if (type === "desktop") {
        return messages[metric]?.desktop || messages[metric]?.default;
      } else if (type === "mobile") {
        return messages[metric]?.mobile || messages[metric]?.default;
      } else if (type === "combined") {
        return messages[metric]?.combined || messages[metric]?.default;
      } else {
        return messages[metric]?.default;
      }
    },
    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStorePosts(profile) {
      if (profile.checked) {
        this.selectedWIDs.push(profile.wid);
      } else {
        this.selectedWIDs = this.selectedWIDs.filter(
          (wid) => wid !== profile.wid
        );
      }
      this.selectedWID = profile.wid;
      this.showStoreDropdown = false;
      await this.fetchMetricsForAllLocations();
    },
    async fetchMetricsForAllLocations() {
      try {
        const payloads = [];
        this.isLoading = true;
        // Create payloads for all selected workspaces
        for (const wid of this.selectedWIDs) {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );

          if (!gbpDoc.empty) {
            const gbpData = gbpDoc.docs[0].data();
            // Extract account ID from the full path
            const accountId = gbpData.accounts?.[0]?.id || "";
            // Create a payload for each location in the accounts array
            if (gbpData.accounts && Array.isArray(gbpData.accounts)) {
              gbpData.accounts.forEach((location) => {
                // Extract location ID from the full path
                const locationId = location.locations?.[0]?.name || "";
                payloads.push({
                  uid: this.currentUID,
                  wid: wid,
                  platform: "google_business_profile",
                  location: locationId, // Using extracted location ID
                  account: accountId,
                  days: 30,
                  resource_type: "detailed",
                });
              });
            }
          }
        }
        // Make parallel API calls
        const fetchPlatformMetrics = httpsCallable(
          functions,
          "fetchPlatformMetrics"
        );
        const promises = payloads.map((payload) => {
          // console.log("payload:", payload);
          return fetchPlatformMetrics(payload);
        });

        const results = await Promise.all(promises);
        // console.log("results:", results);

        // Process the results into an array of metrics
        this.metricsData = results
          .map((result, index) => {
            const metrics = result.data;

            // Check if the result has any metric parameters
            const hasMetrics =
              metrics?.desktop_maps_impressions !== undefined ||
              metrics?.mobile_maps_impressions !== undefined ||
              metrics?.desktop_search_impressions !== undefined ||
              metrics?.mobile_search_impressions !== undefined ||
              metrics?.direction_requests !== undefined ||
              metrics?.call_clicks !== undefined ||
              metrics?.website_clicks !== undefined ||
              metrics?.bookings !== undefined ||
              metrics?.food_orders !== undefined ||
              metrics?.food_menu_clicks !== undefined;

            // Only return the metrics object if it has data
            if (hasMetrics) {
              const locationWid = payloads[index].wid;
              const locationName =
                this.stores.find((store) => store.wid === locationWid)?.name ||
                "";

              return {
                location: {
                  wid: locationWid,
                  name: locationName,
                },
                desktop_maps_impressions:
                  metrics?.desktop_maps_impressions || 0,
                mobile_maps_impressions: metrics?.mobile_maps_impressions || 0,
                desktop_search_impressions:
                  metrics?.desktop_search_impressions || 0,
                mobile_search_impressions:
                  metrics?.mobile_search_impressions || 0,
                direction_requests: metrics?.direction_requests || 0,
                call_clicks: metrics?.call_clicks || 0,
                website_clicks: metrics?.website_clicks || 0,
                bookings: metrics?.bookings || 0,
                food_orders: metrics?.food_orders || 0,
                food_menu_clicks: metrics?.food_menu_clicks || 0,
                total_impressions: metrics?.total_impressions || 0,
                total_clicks: metrics?.total_clicks || 0,
                total_posts: metrics?.total_posts || 0,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove any null entries

        // console.log("Processed metrics:", this.metricsData);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        this.selectedWIDs = [];
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          // storeData.checked = true;
          if (storeData.storeWorkspace && storeData.admin === this.WID) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.checked = true;
            this.stores.push(storeData);
            this.selectedWIDs.push(doc.id);
          }
        });
        // Fetch metrics for all initially selected stores
        if (this.selectedWIDs.length > 0) {
          await this.fetchMetricsForAllLocations();
        }
      });
    },
    async fetchMetrics(w_id = null) {
      try {
        let wid = w_id || this.WID;
        this.isMetricsLoading = true;
        let subscribe = httpsCallable(functions, "fetchPlatformMetrics");
        let payload = {
          widget_key: this.isWidget ? this.$route.params.id : undefined,
          uid: this.currentUID,
          response_type: "all",
          wid,
        };
        let result = (await subscribe(payload)).data;
        this.processedData = result.result;
        // console.log(result);
        this.isMetricsLoading = false;
      } catch (error) {
        this.isMetricsLoading = false;
        this.handleError(error);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchStores();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
