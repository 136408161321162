import Vue from "vue";
import VueRouter from "vue-router";
import flyRoutes from "./fly.routes.js";
import websiteRoutes from "./website.routes.js";
import widgetRoutes from "./widget.routes";
Vue.use(VueRouter);

const routes = [...widgetRoutes, ...flyRoutes, ...websiteRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.name === "planner") {
    // Replace 'planner' with your planner route name
    document.body.classList.add("planner-page");
  } else {
    document.body.classList.remove("planner-page");
  }
  next();
});

export default router;
