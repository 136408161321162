<template>
  <div v-if="hashtags.length" class="mb-3">
    <label class="input-label">Suggested Hashtags:</label>
    <br />
    <v-chip
      outlined
      class="mr-3 mt-2 primary primary--text min-h-[42px]"
      v-for="(item, i) in hashtags"
      :key="i"
      @click="$emit('insertHashtag', item)"
      @click:close="$emit('insertHashtag', item)"
      close-icon="mdi-plus"
      close
    >
      <div class="d-flex flex-col p-2 py-4">
        <div>
          {{ item }}
        </div>
        <!-- fetching from ig hashtag page - IG has removed count from meta tag -->
        <!-- <div class="d-flex items-center">
          <img
            v-if="!postCount(item)"
            src="../../assets/img/loading.gif"
            width="30px"
            height="30px"
            max-height="100%"
            class="pr-2"
          />
          {{ postCount(item) }}
        </div> -->
      </div>
    </v-chip>
  </div>
</template>

<script>
import openaiMixin from "@/mixins/openai.mixin.js";

export default {
  props: {
    hashtags: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [openaiMixin],
  data() {
    return {
      hashtagReachLoading: {},
      hashtagReachDescription: {},
    };
  },
  watch: {
    hashtags() {
      this.genReach();
    },
  },
  methods: {
    postCount(item) {
      if (item) {
        let remHash = item.replace("#", "").toLowerCase();
        return this.hashtagReachDescription[remHash]
          ? this.hashtagReachDescription[remHash]
          : "";
      }
      return "";
    },
    async genReach() {
      let vm = this;
      const hashtagPromises = vm.hashtags.map(async (hashtag) => {
        let remHash = hashtag.replace("#", "").toLowerCase();
        const hashtagUrl = `https://www.instagram.com/explore/tags/${remHash}`;
        this.$set(vm.hashtagReachLoading, remHash, true);
        const result = await vm.hashtagReachFromURL(hashtagUrl, remHash);
        this.$set(vm.hashtagReachDescription, remHash, result);
        this.$set(vm.hashtagReachLoading, remHash, false);
      });
      await Promise.all(hashtagPromises);
    },

  },
};
</script>

<style></style>
