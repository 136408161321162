<template>
  <div>
    <div
      class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
    >
      <section
        class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
      >
        <!-- Header Section -->
        <div
          class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"
        >
          <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
            <img
              src="@/assets/img/redirect/social-tracker-main.png"
              class="cursor-pointer w-6 md:w-[28px]"
              alt="post"
            />
            <div class="flex items-center gap-1">
              <p class="text-base md:text-[22px] font-bold">Locations</p>
            </div>
            <!-- Add Statistics Summary -->
            <div class="stats-summary p-4 border-b">
              <div class="flex flex-wrap gap-4 items-center">
                <div class="stat-item">
                  <span class="font-semibold">Total:</span>
                  <span class="ml-2">{{ invitedMembers.length }}</span>
                </div>
                <div class="stat-item" v-if="platformStats.length">
                  <span class="font-semibold">Connected Accounts:</span>
                  <div class="flex items-center gap-2 ml-2">
                    <div 
                      v-for="stat in platformStats" 
                      :key="stat.platform"
                      class="flex items-center gap-1"
                    >
                      <img 
                        :src="platformLogoCache[stat.platform] || ''" 
                        :alt="stat.platform"
                        class="w-5 h-5 object-contain"
                      />
                      <span>{{ stat.count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- New Post Button -->
          <div
            class="h-[34px] pl-3 pr-4 py-[7px] bg-white rounded-md border border-[#e6e8ec] justify-center items-center gap-2 inline-flex cursor-pointer mt-4"
            @click="showInviteForm = !showInviteForm"
          >
            <div class="w-4 h-4 relative">
              <img src="@/assets/img/plus_c.svg" alt="Create Post" />
            </div>
            <!-- Show the text only on larger screens -->
            <div
              class="text-black text-sm font-medium font-['Inter'] leading-tight hidden md:inline-flex"
            >
              New Location
            </div>
          </div>
          <!-- Upload CSV Button -->
          <div
            class="h-[34px] pl-3 pr-4 py-[7px] bg-white rounded-md border border-[#e6e8ec] justify-center items-center gap-2 inline-flex cursor-pointer mt-4 ml-2"
            @click="showUploadDialog = true"
          >
            <div class="w-4 h-4 relative">
              <img src="@/assets/img/gallery-icons/upload.svg" alt="Upload Locations" />
            </div>
            <div class="text-black text-sm font-medium font-['Inter'] leading-tight hidden md:inline-flex">
              Upload Locations
            </div>
          </div>
        </div>
        

        <!-- Main Content Section -->
        <div
          class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl min-h-[150vh]"
        >
          <div class="flex flex-col gap-4">
            

            <!-- Invite Member Form -->
            <section class="invite-section" v-show="showInviteForm">
              <form @submit.prevent="addMember" class="invite-form">
                <div class="form-group">
                  <input
                    ref="autocomplete"
                    type="text"
                    placeholder="Enter store location"
                    v-model="storeLocation"
                    class="input-field"
                    required
                  />
                </div>
                <div class="form-row">
                  <input
                    type="text"
                    v-model="city"
                    class="input-field"
                    placeholder="City"
                    required
                  />
                  <input
                    type="text"
                    v-model="state"
                    class="input-field"
                    placeholder="State"
                    required
                  />
                  <input
                    type="text"
                    v-model="zipcode"
                    class="input-field"
                    placeholder="Zip Code"
                    required
                  />
                </div>
                <div class="form-row">
                  <input
                    type="email"
                    v-model="email"
                    class="input-field"
                    placeholder="Email"
                    required
                  />
                  <input
                    type="tel"
                    v-model="phone"
                    class="input-field"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div class="form-group">
                  <v-btn type="submit" class="btn-primary">Add Location</v-btn>
                </div>

                <!-- Success Message and Options as Text Links -->
                <div
                  v-if="locationAdded"
                  class="success-options mt-4 flex gap-2 items-center"
                >
                  <span class="text-black-500 font-semibold"
                    >Location successfully added</span
                  >
                  <span
                    class="text-red-600 cursor-pointer font-semibold"
                    @click="closeSection"
                    >OK</span
                  >
                  <span
                    class="text-[#8050DE] cursor-pointer font-semibold"
                    @click="addMore"
                    >Add More</span
                  >
                </div>
              </form>
            </section>

            <!-- Invited Members Table -->
            <section class="table-section">
              <div class="header-container">
                <!-- <h2 class="table-title">Added Branches</h2> -->
                <section class="search-section">
                  <div class="search-filters-container">
                    <div class="search-container">
                      <input
                        type="text"
                        v-model="searchQuery"
                        placeholder="Search Location"
                        class="search-input"
                      />
                    </div>
                    <!-- Status Filters -->
                    <div class="status-filters">
                      <button 
                        class="status-filter-btn"
                        :class="{ active: selectedStatus === null }"
                        @click="selectedStatus = null"
                      >
                        All ({{ invitedMembers.length }})
                      </button>
                      <button 
                        v-for="status in availableStatuses" 
                        :key="status"
                        class="status-filter-btn"
                        :class="{ 
                          active: selectedStatus === status,
                          'status-invited': status === 'invited',
                          'status-accepted': status === 'accepted',
                          'status-connected': status === 'connected',
                          'status-disconnected': status === 'disconnected'
                        }"
                        @click="selectedStatus = status"
                      >
                        {{ toTitleCase(status) }} ({{ getStatusCount(status) }})
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              
              <div class="table-responsive flex-grow overflow-x-auto">
                <table class="members-table w-full table-fixed" v-if="filteredMembers.length">
                  <thead>
                    <tr>
                      <th class="w-[20%]">Location</th>
                      <th class="w-[20%]">Address</th>
                      <th class="w-[20%]">Email</th>
                      <th class="w-[20%]">Phone</th>
                      <th class="w-[15%]">Status</th>
                      <th class="w-[5%]">
                        <v-icon
                          small
                          color="white"
                          title="Actions"
                        >
                          mdi-cog
                        </v-icon>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(member, index) in filteredMembers"
                      :key="member.id"
                    >
                      <td class="location-cell">
                        <!-- <a 
                          href="#" 
                          @click.prevent="openEditDialog(member)"
                          class="text-[#8050DE] hover:text-[#6040AE] hover:underline line-clamp-2"
                        > -->
                          {{ member.storeLocation }}
                        <!-- </a> -->
                      </td>
                      <td class="address-cell">
                        <div class="flex flex-wrap items-center gap-x-1">
                          <a 
                            href="#"
                            @click.prevent="addFilter('city', member.city)"
                            class="text-[#8050DE] hover:text-[#6040AE] hover:underline"
                            :class="{ 'font-semibold': activeFilters.city === member.city }"
                            :title="`Filter by city: ${member.city}`"
                          >
                            {{ member.city }}
                          </a>
                          <span class="text-gray-400">·</span>
                          <a 
                            href="#"
                            @click.prevent="addFilter('state', member.state)"
                            class="text-[#8050DE] hover:text-[#6040AE] hover:underline"
                            :class="{ 'font-semibold': activeFilters.state === member.state }"
                            :title="`Filter by state: ${member.state}`"
                          >
                            {{ member.state }}
                          </a>
                          <span class="text-gray-400">·</span>
                          <span class="text-gray-600" :title="member.zipcode">
                            {{ member.zipcode }}
                          </span>
                          <span class="text-gray-400">·</span>
                          <a 
                            href="#"
                            @click.prevent="addFilter('country', member.country)"
                            class="text-[#8050DE] hover:text-[#6040AE] hover:underline"
                            :class="{ 'font-semibold': activeFilters.country === member.country }"
                            :title="`Filter by country: ${member.country}`"
                          >
                            {{ member.country }}
                          </a>
                        </div>
                      </td>
                      <td>
                        <a 
                          :href="`mailto:${member.email}`"
                          class="text-[#8050DE] hover:text-[#6040AE] hover:underline flex items-center gap-1"
                          title="Send email"
                        >
                          <span class="truncate">{{ member.email }}</span>
                          <img 
                            src="@/assets/img/email.svg" 
                            alt="Email" 
                            class="w-4 h-4 opacity-70 flex-shrink-0"
                          />
                        </a>
                      </td>
                      <td>
                        <a 
                          :href="`https://wa.me/${formatPhoneForWhatsApp(member.phone)}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-[#8050DE] hover:text-[#6040AE] hover:underline flex items-center gap-1"
                          title="Send WhatsApp message"
                        >
                          <span class="truncate">{{ member.phone }}</span>
                          <img 
                            src="@/assets/img/whatsapp.svg" 
                            alt="WhatsApp" 
                            class="w-4 h-4 opacity-70 flex-shrink-0"
                          />
                        </a>
                      </td>
                      <td>
                        <div class="flex items-center gap-2">
                          <span
                            :class="{
                              'status-badge': true,
                              'status-invited': member.status === 'invited',
                              'status-accepted': member.status === 'accepted',
                              'status-connected': member.status === 'connected',
                              'status-disconnected': member.status === 'disconnected'
                            }"
                          >
                            {{ getDisplayStatus(member.status) }}
                          </span>
                          <!-- Replace img with v-icon -->
                          <v-icon v-if="member.status!=='connected'"
                            small
                            class="cursor-pointer hover:text-[#8050DE] transition-colors"
                            @click="openInvitationLink(member)"
                            title="Open invitation link"
                          >
                            mdi-plus-circle-outline
                          </v-icon>
                        </div>
                        <div 
                          v-if="member.status === 'connected'" 
                          class="flex gap-2 mt-1 flex-wrap"
                        >
                          <img
                            v-for="platform in member.connected_apps"
                            :key="platform"
                            :src="platformLogoCache[platform] || ''"
                            :alt="platform"
                            class="w-5 h-5 object-contain"
                            :title="platform"
                          />
                          <v-icon
                            small
                            class="cursor-pointer hover:text-[#8050DE] transition-colors"
                            @click="openInvitationLink(member)"
                            title="Open invitation link"
                          >
                            mdi-plus-circle-outline
                          </v-icon>
                        </div>
                      </td>
                      <td>
                        <div
                          class="flex gap-2 flex-wrap items-center justify-center"
                        >
                          <img
                            @click="resendInvitation(member)"
                            src="@/assets/img/reconnect.svg"
                            class="cursor-pointer w-4 h-4"
                          />
                          <v-icon
                            small
                            color="red"
                            @click="deleteMember(member)"
                            class="action-icon"
                            aria-label="Delete Member"
                            title="Delete Member"
                          >
                            mdi-delete-outline
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- No Results Found -->
                <div v-else class="no-results">
                  No locations found matching your search criteria.
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <!-- Upload Dialog -->
    <v-dialog v-model="showUploadDialog" max-width="500px">
      <div class="bg-white p-6 rounded-lg">
        <h3 class="text-xl font-bold mb-4">Upload Locations CSV</h3>
        
        <p class="text-gray-600 mb-4">
          Upload a CSV file containing your locations. The file should have the following columns:
          location, city, state, zip code, country, email, phone
        </p>

        <div class="flex flex-col gap-4">
          <input 
            type="file" 
            ref="fileInput"
            accept=".csv"
            class="hidden"
            @change="handleFileUpload"
          />

          <div class="flex flex-col gap-2">
            <v-btn 
              color="primary" 
              @click="$refs.fileInput.click()"
              :loading="isUploading"
            >
              Upload Locations File
            </v-btn>
            
            <a 
              href="#" 
              class="text-[#8050DE] text-sm text-center"
              @click.prevent="downloadSampleCSV"
            >
              Download Sample CSV
            </a>
          </div>

          <!-- Upload Progress -->
          <div v-if="uploadProgress" class="mt-4">
            <div class="text-sm text-gray-600 mb-2">
              Processing: {{ uploadProgress.current }} of {{ uploadProgress.total }}
            </div>
            <v-progress-linear
              :value="(uploadProgress.current / uploadProgress.total) * 100"
              color="primary"
            ></v-progress-linear>
          </div>

          <!-- Upload Results -->
          <div v-if="uploadResults" class="mt-4 p-4 bg-gray-50 rounded">
            <h4 class="font-bold mb-2">Upload Complete</h4>
            <p>Successfully uploaded: {{ uploadResults.success }}</p>
            <p>Skipped (duplicates): {{ uploadResults.skipped }}</p>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { auth, colUsers, db, functions } from "@/utils/firebase.utils";
import {
  getDoc,
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
// import ToolTip from "./"
export default {
  data() {
    return {
      showInviteForm: false,
      locationAdded: false,
      storeLocation: "",
      state: "",
      city: "",
      email: "",
      phone: "",
      zipcode: "",
      country: "",
      name: "",
      invitationStatus: "invited",
      invitedMembers: [], // Array to hold invited members
      dialog: false, // Control dialog visibility
      editedMember: {}, // Member being edited
      editedIndex: null, // Index of member being edited
      statusOptions: ["invited", "accepted"],
      geoLocation: "",
      addingNew: false,
      unsubscribeStore: null,
      searchQuery: "", // Search query input by the user
      platformLogoCache: {}, // Cache for platform logos
      selectedStatus: null,
      availableStatuses: ['invited', 'accepted', 'connected', 'disconnected'],
      showUploadDialog: false,
      isUploading: false,
      uploadProgress: null,
      uploadResults: null,
      showEditDialog: false,
      editedLocation: {},
      isSaving: false,
      activeFilters: {
        city: null,
        state: null,
        country: null
      },
    };
  },
  computed: {
    // Computed property to filter invited members based on the search query
    filteredMembers() {
      let filtered = this.invitedMembers;
      
      // Apply location filters
      if (this.activeFilters.city) {
        filtered = filtered.filter(member => member.city === this.activeFilters.city);
      }
      if (this.activeFilters.state) {
        filtered = filtered.filter(member => member.state === this.activeFilters.state);
      }
      if (this.activeFilters.country) {
        filtered = filtered.filter(member => member.country === this.activeFilters.country);
      }
      
      // Apply status filter
      if (this.selectedStatus) {
        filtered = filtered.filter(member => member.status === this.selectedStatus);
      }

      // Apply search filter
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter((member) => {
          return (
            (member.storeLocation &&
              member.storeLocation.toLowerCase().includes(query)) ||
            (member.city && member.city.toLowerCase().includes(query)) ||
            (member.state && member.state.toLowerCase().includes(query)) ||
            (member.zipcode && member.zipcode.toLowerCase().includes(query)) ||
            (member.country && member.country.toLowerCase().includes(query)) ||
            (member.email && member.email.toLowerCase().includes(query)) ||
            (member.phone && member.phone.toLowerCase().includes(query)) ||
            (member.status && member.status.toLowerCase().includes(query))
          );
        });
      }

      return filtered;
    },
    platformStats() {
      const stats = {};
      this.invitedMembers.forEach(member => {
        if (member.connected_apps && Array.isArray(member.connected_apps)) {
          member.connected_apps.forEach(platform => {
            stats[platform] = (stats[platform] || 0) + 1;
          });
        }
      });
      
      return Object.entries(stats)
        .map(([platform, count]) => ({ platform, count }))
        .sort((a, b) => b.count - a.count);
    },
    hasActiveFilters() {
      return Object.values(this.activeFilters).some(value => value !== null);
    },
  },
  methods: {
    initAutocomplete() {
      const input = this.$refs.autocomplete;
      console.log(this.storeLocation, "this is entered input");

      // Store the input value when user types manually
      input.addEventListener('input', (e) => {
        this.storeLocation = e.target.value;
        this.name = e.target.value;
      });

      // Initialize Google Places Autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocomplete.setFields(["address_components", "geometry", "name"]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        // If no place details found, keep the manually entered value
        if (!place.geometry) {
          return;
        }

        // Reset values for Google Places result
        this.city = "";
        this.state = "";
        this.zipcode = "";
        this.country = "";
        this.storeLocation = "";
        this.geoLocation = "";

        // Extract address components
        const addressComponents = place.address_components;
        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            this.city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            this.state = component.long_name;
          }
          if (types.includes("postal_code")) {
            this.zipcode = component.long_name;
          }
          if (types.includes("country")) {
            this.country = component.long_name;
          }
        });

        // Get and store latitude and longitude
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.geoLocation = `${lat},${lng}`;
        this.name = place?.name;

        console.log("This is place:", place);
        console.log("GeoLocation:", this.geoLocation);

        // Format store location by removing redundant address components
        let formattedStoreLocation = place.name;
        const regex = new RegExp(
          `\\b(${this.city}|${this.state}|${this.zipcode}|${this.country})\\b`,
          "gi"
        );
        
        formattedStoreLocation = formattedStoreLocation
          .replace(regex, "")
          .trim();

        // Construct final store location string
        this.storeLocation = [
          formattedStoreLocation,
          this.city,
          this.state,
          this.zipcode,
          this.country
        ]
          .filter(Boolean) // Remove empty values
          .join(", ")
          .replace(/,{2,}/g, ",") // Replace multiple commas with single comma
          .replace(/(^,)|(,$)/g, "") // Remove leading/trailing commas
          .trim();

        console.log("Final Store Location:", this.storeLocation);
      });
    },
    // Function to generate an invitation token and store it in Firestore
    async generateInvitationToken(uid, wid) {
      const token = `${uid}-${wid}-${Date.now()}`; // Unique token
      const expiresAt = Timestamp.fromDate(
        new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) // Expires in 7 days
      );
      const docRef = doc(db, "users", uid, "fly_workspaces", wid);

      await updateDoc(docRef, { token_expires_at: expiresAt });

      return token;
    },
    async addMember() {
      // let userDoc = (await getDoc(doc(colUsers, this.currentUID))).data();
      const created_at = new Date();
      // Add new member with initial fields
      const docRef = await addDoc(
        collection(db, "users", this.currentUID, "fly_workspaces"),
        {
          created_at,
          storeWorkspace: true,
          storeLocation: this.storeLocation,
          geoLocation: this.geoLocation,
          city: this.city,
          state: this.state,
          zipcode: this.zipcode,
          name: this.name || this.storeLocation,
          country: this.country,
          email: this.email,
          phone: this.phone,
          status: this.invitationStatus,
          admin: this.WID,
        }
      );

      let storeUserWID = docRef.id;
      // // Update member ID directly

      // invite store
      const token = await this.generateInvitationToken(this.currentUID, storeUserWID);
      // let admin = this.currentUser?.email || this.currentUser?.display_name || this.currentUser?.phone_number || ''
      await updateDoc(docRef, { id: docRef.id });
      // this.$router.push("/invitation?uid=" + this.currentUID + "&wid=" + storeUserWID + "&token=" + token + '&workspace_name=' + this.name + '&sender=' + admin);
      const sendMemberInvitation = httpsCallable(
        functions,
        "sendInvitationToStore"
      );
      try {
        sendMemberInvitation({
          email: this.email,
          phone: this.phone,
          userName: this.name || this.storeLocation,
          workspaceName: this.name || this.storeLocation,
          wid: storeUserWID,
          uid: this.currentUID,
          token,
          admin:
            this.currentUser?.display_name ||
            this.currentUser?.email ||
            this.currentUser?.phone_number ||
            "",
          domain: window.location.host,
        });
        this.$snackbar.show("Invitation sent successfully");
      } catch (error) {
        console.error("Error sending invitation email:", error);
        this.$alert.show("Failed to send invitation email. Please try again.");
      }

      this.resetForm();
    },
    async openInvitationLink(member) {
      const baseUrl = window.location.origin;
      const formattedWorkspaceName = member.name?.replace(/\s+/g, '_') || '';
      let admin = this.currentUser?.display_name || 
                  this.currentUser?.email || 
                  this.currentUser?.phone_number || '';
      
      let formattedAdmin = admin;
      if (!admin.includes('@') && !admin.match(/^\+?[0-9\s]+$/)) {
        formattedAdmin = admin.replace(/\s+/g, '_');
      } else if (admin.match(/^\+?[0-9\s]+$/)) {
        formattedAdmin = admin.replace(/\s+/g, '');
      }
      const token = await this.generateInvitationToken(this.currentUID, member.id);
      console.log("entetereed",token);
      const workspaceDocRef = doc(db, "users", this.currentUID, "fly_workspaces", member.id);
      const workspaceSnap = await getDoc(workspaceDocRef);
      if(workspaceSnap.exists && workspaceSnap.data().status=='invited'){
        await updateDoc(workspaceDocRef, {
          status: "accepted"
        });
      }
      window.location.href = '/store-invitation-accepted?uid=' + this.currentUID + '&wid=' + member.id + '&self=true&token=' + token + '&workspace_name=' + formattedWorkspaceName + '&sender=' + formattedAdmin;
      // this.$router.push(
      //     "/store-invitation-accepted?uid=" +
      //       this.currentUID +
      //       "&wid=" +
      //       member.id +
      //       "&self=true" +
      //       "&token=" +
      //       token +
      //       "&workspace_name=" +
      //       formattedWorkspaceName +
      //       "&sender=" +
      //       formattedAdmin
      //   );
      // const invitationLink = `${baseUrl}/accept-invite?self=true&uid=${this.currentUID}&wid=${member.id}&workspace_name=${formattedWorkspaceName}&sender=${formattedAdmin}&token=${token}`;
      
      // Open in new tab
      // window.location.href = invitationLink;
      // window.open(invitationLink, '_blank');
    },
    async resendInvitation(member) {
      const token = await this.generateInvitationToken(this.currentUID, member.id);
      const sendMemberInvitation = httpsCallable(
        functions,
        "sendInvitationToStore"
      );
      try {
        sendMemberInvitation({
          email: member.email,
          phone: member.phone,
          userName: member.name,
          workspaceName: member.name || "",
          wid: member.id,
          uid: this.currentUID,
          token,
          admin:
            this.currentUser?.display_name ||
            this.currentUser?.email ||
            this.currentUser?.phone_number ||
            "",
          domain: window.location.host,
        });
        this.$snackbar.show("Invitation sent successfully");
      } catch (error) {
        this.$alert.show("Failed to send invitation email. Please try again later.");
      }
    },

    resetForm() {
      this.storeLocation = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.country = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.invitationStatus = "invited";
      this.$refs.autocomplete.value = "";
      this.geoLocation = "";
      this.addingNew = false;
    },
    async deleteMember(member) {
      let uid = this.currentUID || this.currentUID;
      this.$confirm.show({
        message: `Are you sure you want to delete this location?`,
        onConfirm: async () => {
          const workspaceDocRef = doc(
            db,
            "users",
            uid,
            "fly_workspaces",
            member.id
          );
          // Now delete the workspace document itself
          await deleteDoc(workspaceDocRef);
        },
      });
    },
    editMember(index) {
      this.editedIndex = index;
      this.editedMember = { ...this.invitedMembers[index] };
      this.dialog = true;
    },
    updateMember() {
      if (this.editedIndex !== null) {
        this.invitedMembers.splice(this.editedIndex, 1, this.editedMember);
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.editedMember = {};
      this.editedIndex = null;
    },
    fetchStores() {
      if (this.unsubscribeStore) {
        this.unsubscribeStore();
      }

      const collectionRef = query(
        collection(db, "users", this.currentUID, "fly_workspaces"),
        where("storeWorkspace", "==", true),
        where("admin", "==", this.WID)
      );

      // Set up onSnapshot listener with optimized query and data handling
      this.unsubscribeStore = onSnapshot(collectionRef, (snap) => {
        this.invitedMembers = snap.docs.map((doc) => doc.data());
        console.log("Filtered invited members:", this.invitedMembers);
      });
    },
    async fetchPlatformLogo(platform) {
      if (this.platformLogoCache[platform]) {
        return;
      }

      try {
        const platformDoc = await getDoc(doc(db, 'fly_platforms', platform.toLowerCase()));
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.$set(this.platformLogoCache, platform, logoUrl);
        }
      } catch (error) {
        console.error('Error fetching platform logo:', error);
      }
    },
    toTitleCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getStatusCount(status) {
      return this.invitedMembers.filter(member => member.status === status).length;
    },
    getDisplayStatus(status) {
      return this.toTitleCase(status);
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.isUploading = true;
      this.uploadProgress = { current: 0, total: 0 };
      this.uploadResults = null;

      try {
        const text = await file.text();
        const rows = text.split('\n').map(row => row.split(',').map(cell => cell.trim()));
        const headers = rows[0].map(header => header.toLowerCase());
        const locations = rows.slice(1).filter(row => row.length === headers.length).map(row => {
          const location = {};
          headers.forEach((header, index) => {
            location[header] = row[index];
          });
          return location;
        });

        this.uploadProgress.total = locations.length;
        let successCount = 0;
        let skipCount = 0;

        for (const [index, location] of locations.entries()) {
          this.uploadProgress.current = index + 1;

          // Check for duplicate location
          const existingLocations = this.invitedMembers.filter(
            member => member.storeLocation.toLowerCase() === location.location.toLowerCase()
          );

          if (existingLocations.length > 0) {
            skipCount++;
            continue;
          }

          // Add the location
          const created_at = new Date();
          const docRef = await addDoc(
            collection(db, "users", this.currentUID, "fly_workspaces"),
            {
              created_at,
              storeWorkspace: true,
              storeLocation: location.location,
              city: location.city,
              state: location.state,
              zipcode: location.zipcode,
              country: location.country,
              email: location.email,
              phone: location.phone,
              status: 'invited',
              admin: this.WID,
            }
          );

          // Generate and send invitation
          const token = await this.generateInvitationToken(this.currentUID, docRef.id);
          await updateDoc(docRef, { id: docRef.id });

          const sendMemberInvitation = httpsCallable(functions, "sendInvitationToStore");
          await sendMemberInvitation({
            email: location.email,
            phone: location.phone,
            userName: location.location,
            workspaceName: location.location,
            wid: docRef.id,
            uid: this.currentUID,
            token,
            admin: this.currentUser?.display_name || this.currentUser?.email || this.currentUser?.phone_number || '',
            domain: window.location.host,
          });

          successCount++;
        }

        this.uploadResults = {
          success: successCount,
          skipped: skipCount
        };

      } catch (error) {
        console.error('Upload error:', error);
        this.$alert.show('Error processing file. Please check the format and try again.');
      } finally {
        this.isUploading = false;
        event.target.value = ''; // Reset file input
      }
    },

    downloadSampleCSV() {
      const headers = ['location', 'city', 'state', 'zipcode', 'country', 'email', 'phone'];
      const sampleData = [
        [
          'Acme Supermarket Bandra West',
          'Mumbai',
          'Maharashtra',
          '400050',
          'India',
          'abc@xyz.com',
          '+919881474184'
        ],
        [
          'Acme Supermarket Koramangala',
          'Bangalore',
          'Karnataka',
          '560034',
          'India',
          'abc@xyz.com',
          '+919881474184'
        ],
        [
          'Acme Supermarket Rohini',
          'New Delhi',
          'Delhi',
          '110085',
          'India',
          'abc@xyz.com',
          '+919881474184'
        ],
        [
          'Acme Supermarket Kukatpally',
          'Hyderabad',
          'Telangana',
          '500072',
          'India',
          'abc@xyz.com',
          '+919881474184'
        ],
        [
          'Acme Supermarket Kothrud',
          'Pune',
          'Maharashtra',
          '411038',
          'India',
          'abc@xyz.com',
          '+919881474184'
        ]
      ];

      const csvContent = [
        headers.join(','),
        ...sampleData.map(row => row.join(','))
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', 'sample_locations.csv');
      document.body.appendChild(a); // Append to the body
      a.click(); // Trigger the download
      document.body.removeChild(a); // Remove the link after triggering
      window.URL.revokeObjectURL(url); // Clean up
    },
    formatPhoneForWhatsApp(phone) {
      // Remove all non-numeric characters
      const cleanPhone = phone.replace(/\D/g, '');
      
      // If the number doesn't start with '+' or country code, assume it's Indian
      if (!cleanPhone.startsWith('91') && !cleanPhone.startsWith('1')) {
        return '91' + cleanPhone;
      }
      
      return cleanPhone;
    },
    addFilter(type, value) {
      // If clicking the same filter value, remove it
      if (this.activeFilters[type] === value) {
        this.removeFilter(type);
        return;
      }
      
      // Add new filter
      this.$set(this.activeFilters, type, value);
    },
    removeFilter(type) {
      this.$set(this.activeFilters, type, null);
    },
  },
  watch: {
    'invitedMembers': {
      immediate: true,
      handler(members) {
        members.forEach(member => {
          if (member.connected_apps) {
            member.connected_apps.forEach(platform => {
              this.fetchPlatformLogo(platform);
            });
          }
        });
      }
    }
  },
  mounted() {
    // console.log("this is currentUid",this.currentUID);
    if (
      (auth.currentUser && auth.currentUser.uid !== this.currentUID) ||
      this.currentWorkspace?.storeWorkspace
    ) {
      this.$snackbar.show("Direct access is prohibited");
      this.$router.push("/calendar");
    }
    this.initAutocomplete();
    this.fetchStores();
  },
};
</script>

<style scoped>
/* Container Styling */
.container {
  padding: 20px;
  background-color: #f2f6fa;
  min-height: 100vh;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  margin-right: 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #8056dd;
}

.invite-section {
  margin-top: 3rem;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.invite-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group,
.form-row {
  width: 100%;
}

.form-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  word-wrap: break-word;
  /* Allow long words to break */
  white-space: normal;
  /* Allow text to wrap */
}

.btn-primary {
  background-color: #4316a2;
  color: #000000;
  border-radius: 7px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-self: flex-start;
}

.btn-primary:hover {
  color: #fff;
  background-color: #5b29c1;
}

.table-section {
  margin-top: 3rem;
  width: 100%;
  /* Ensure full width */
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* Align items to the left */
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.table-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #8056dd;
}

.search-section {
  width: 100%;
  margin-bottom: 1rem;
}

.search-filters-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.search-container {
  flex: 0 0 300px; /* Fixed width for search box */
}

.status-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  flex: 1;
}

.search-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #8056dd;
  outline: none;
}

.table-responsive {
  height: calc(150vh - 300px);
  min-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.members-table {
  border-collapse: collapse;
  font-size: 0.875rem;
  background-color: white;
  table-layout: fixed;
}

.members-table th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #8056dd;
  color: white;
  font-weight: 500;
  text-align: left;
  padding: 0.75rem 1rem;
  border-right: 1px solid rgba(255, 255, 255, 0.2); /* Subtle separator for headers */
}

.members-table th:last-child {
  border-right: none; /* Remove border from last header */
}

.members-table td {
  padding: 0.625rem 1rem;
  border-bottom: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb; /* Vertical separator for cells */
  background-clip: padding-box; /* Ensures background doesn't show through border */
}

.members-table td:last-child {
  border-right: none; /* Remove border from last cell */
}

/* Add subtle hover effect for rows */
.members-table tbody tr:hover td {
  background-color: #f8fafc;
}

/* Ensure the header stays visible with a subtle shadow */
.members-table thead {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Optional: Add zebra striping for better row distinction */
.members-table tbody tr:nth-child(even) td {
  background-color: #fafafa;
}

.members-table tbody tr:nth-child(even):hover td {
  background-color: #f8fafc;
}

.location-cell {
  white-space: normal;
  line-height: 1.2;
  vertical-align: top;
}

.location-cell a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

/* Responsive styles */
@media (max-width: 1280px) {
  .members-table {
    font-size: 0.813rem;
  }
  
  .members-table th,
  .members-table td {
    padding: 0.5rem 0.375rem;
  }
}

/* Show horizontal scroll only on mobile */
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }
  
  .members-table {
    font-size: 0.75rem;
  }
  
  .members-table th,
  .members-table td {
    padding: 0.375rem 0.25rem;
  }
}

/* Ensure icons don't shrink */
.members-table img,
.members-table .v-icon {
  flex-shrink: 0;
}

/* Status styles */
.status-invited,
.status-accepted,
.status-connected,
.status-disconnected {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-results {
  text-align: center;
  /* Align text to the left */
  padding: 20px;
  color: #555;
  font-size: 16px;
}

/* Action Icons Styling */
.action-icon {
  cursor: pointer;
  font-size: 20px;
}

.action-icon:hover {
  opacity: 0.7;
}

/* Responsive Styling */

/* Tablet and Below */
@media (max-width: 1024px) {
  .title {
    font-size: 22px;
  }

  .input-field {
    font-size: 15px;
    padding: 8px;
  }

  .btn-primary {
    padding: 8px 16px;
    font-size: 15px;
  }

  .members-table th,
  .members-table td {
    padding: 10px 12px;
    font-size: 14px;
  }

  .action-icon {
    font-size: 18px;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .invite-form {
    gap: 10px;
  }

  .input-field {
    font-size: 14px;
    padding: 8px;
  }

  .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  .members-table th,
  .members-table td {
    padding: 8px 10px;
    font-size: 13px;
  }

  .action-icon {
    font-size: 16px;
  }

  .search-filters-container {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }

  .search-container {
    flex: none;
    width: 100%;
  }

  .status-filters {
    justify-content: flex-start;
  }
}

/* Small Mobile Devices */
@media (max-width: 450px) {
  .members-table th,
  .members-table td {
    padding: 6px 8px;
    font-size: 12px;
  }

  .members-table th:nth-child(3),
  .members-table td:nth-child(3),
  .members-table th:nth-child(4),
  .members-table td:nth-child(4),
  .members-table th:nth-child(5),
  .members-table td:nth-child(5) {
    display: none;
    /* Hide less important columns on very small screens */
  }

  .table-responsive {
    font-size: 14px;
  }

  .btn-primary {
    font-size: 14px;
    padding: 8px;
  }

  .search-container {
    max-width: 100%;
    padding: 0 10px;
  }
}

/* Extra Small Mobile Devices */
@media (max-width: 400px) {
  .members-table th,
  .members-table td {
    font-size: 10px;
    padding: 4px 6px;
  }

  .members-table th:nth-child(2),
  .members-table td:nth-child(2),
  .members-table th:nth-child(3),
  .members-table td:nth-child(3),
  .members-table th:nth-child(4),
  .members-table td:nth-child(4),
  .members-table th:nth-child(5),
  .members-table td:nth-child(5),
  .members-table th:nth-child(6),
  .members-table td:nth-child(6) {
    display: none;
    /* Further hide columns on very small screens */
  }

  .table-responsive {
    font-size: 12px;
  }

  .btn-primary {
    font-size: 12px;
    padding: 6px;
  }
}

.platform-logos img {
  transition: transform 0.2s;
}

.platform-logos img:hover {
  transform: scale(1.1);
}

.stats-summary {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.stat-item {
  display: flex;
  align-items: center;
  color: #4a5568;
}

.status-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.status-filter-btn {
  padding: 6px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.status-filter-btn:hover {
  background-color: #f7fafc;
}

.status-filter-btn.active {
  background-color: #edf2f7;
  border-color: #cbd5e0;
}

.status-filter-btn.status-invited.active {
  background-color: #f3f0ff;
  border-color: #8050DE;
  color: #8050DE;
}

.status-filter-btn.status-accepted.active {
  background-color: #fff3e0;
  border-color: #ff9800;
  color: #ff9800;
}

.status-filter-btn.status-connected.active {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: #4caf50;
}

.status-filter-btn.status-disconnected.active {
  background-color: #ffebee;
  border-color: #f30000;
  color: #f30000;
}

.v-dialog {
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-progress {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.active-filters {
  background-color: #f8fafc;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.filter-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: #8050DE;
  color: white;
  border-radius: 9999px;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.filter-chip button {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.filter-chip button:hover {
  opacity: 1;
}

.filter-chip:hover {
  background-color: #6040AE;
}

/* Status Filter Buttons */
.status-filter-btn {
  padding: 0.375rem 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;
  background-color: white;
}

.status-filter-btn:hover {
  background-color: #f7fafc;
}

.status-filter-btn.active {
  font-weight: 600;
}

/* Status colors for filter buttons */
.status-filter-btn.status-invited {
  &.active {
    background-color: #f3f0ff;
    border-color: #8050DE;
    color: #8050DE;
  }
}

.status-filter-btn.status-accepted {
  &.active {
    background-color: #fff3e0;
    border-color: #ff9800;
    color: #ff9800;
  }
}

.status-filter-btn.status-connected {
  &.active {
    background-color: #e8f5e9;
    border-color: #4caf50;
    color: #4caf50;
  }
}

.status-filter-btn.status-disconnected {
  &.active {
    background-color: #ffebee;
    border-color: #f30000;
    color: #f30000;
  }
}

/* Status Badge Styles */
.status-badge {
  display: inline-block;
  padding: 0.25rem 0.625rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.status-invited {
  background-color: #f3f0ff;
  color: #8050DE;
  border: 1px solid #8050DE;
}

.status-accepted {
  background-color: #fff3e0;
  color: #ff9800;
  border: 1px solid #ff9800;
}

.status-connected {
  background-color: #e8f5e9;
  color: #4caf50;
  border: 1px solid #4caf50;
}

.status-disconnected {
  background-color: #ffebee;
  color: #f30000;
  border: 1px solid #f30000;
}

/* Ensure status badges don't wrap */
td .status-badge {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add some spacing between status and platform icons */
td .status-badge + div {
  margin-top: 0.375rem;
}

/* Make sure platform icons stay on one line if possible */
td .platform-icons {
  display: flex;
  gap: 0.375rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

td .platform-icons::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera */
}

/* Responsive adjustments */
@media (max-width: 1280px) {
  .status-filter-btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.813rem;
  }
  
  .status-badge {
    padding: 0.188rem 0.5rem;
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .status-filter-btn {
    padding: 0.188rem 0.375rem;
    font-size: 0.75rem;
  }
}

.address-cell {
  white-space: normal;
  line-height: 1.4;
}

.address-cell .flex {
  gap: 0.25rem;
}

.address-cell a {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

/* Separator dot styling */
.address-cell .text-gray-400 {
  display: inline-block;
  margin: 0 0.125rem;
  font-weight: bold;
  line-height: 1;
}

/* Responsive adjustments */
@media (max-width: 1280px) {
  .address-cell a {
    max-width: 120px;
  }
  
  .address-cell {
    font-size: 0.813rem;
  }
}

@media (max-width: 1024px) {
  .address-cell a {
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .address-cell .flex {
    flex-direction: column;
    gap: 0.125rem;
  }
  
  .address-cell .text-gray-400 {
    display: none;
  }
  
  .address-cell a {
    max-width: none;
  }
}
</style>
