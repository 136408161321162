<template>
    <div class="flex-d justify-center gap-11 md:px-12 mt-18">
        <div class="font-bold leading-4 gap-9 flex-d items-center">
            <!-- <p class="linear-gradient p-3">Quick Steps</p> -->
            <!-- <div v-if="!checkAuth">
                <div
                    class="text-center text-[#22262E] text-5xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-2xl">
                    Get
                    Started in 4 Simple Steps</div>
            </div> -->
            <p v-if="checkAuth" class="text-center text-5xl max-md:text-2xl">Welcome, {{ authUserName }} 👋</p>
        </div>
        <p v-if="checkAuth" class="text-center text-xl text-gray-500 -mt-6">
            {{ email || phone_number }}
        </p>

        <div v-if="!checkAuth"
            class="flex-d bg-[#F0F8F3] text-center gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
            <div class="font-bold leading-4 gap-9 flex-d items-center">
                <p class="linear-gradient p-3">Quick Steps</p>
            </div>
            <div
                class="text-center text-[#22262E] text-5xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-2xl">
                Get
                Started in 4 Simple Steps</div>
            <!-- For desktop view -->
            <img v-show="isDesktop" class=" w-[800px] ml-auto mr-auto" src="@/assets/img/design_n3.png" />

            <!-- For mobile view -->
            <img v-show="!isDesktop" class=" ml-auto mr-auto" src="@/assets/img/design_ph1.png" />



            <div class="text-3xl flex justify-center fly-lite-text font-semibold">
                <p class="w-[20rem] md:w-[31rem]">Sign up for 14 day Free Trial!</p>
            </div>
            <div>
                <button class="fly-lite-btn" @click="loginHandle">
                    Sign Up Now
                    <v-icon class="ml-2" color="white">mdi-arrow-right</v-icon>
                </button>
            </div>
        </div>

        <!-- Instagram Link Section -->

        <div v-else class="flex-d justify-center gap-11 md:p-12">
            <!-- When isConnected is False -->
            <div v-if="!checkIsConnected"
                class="flex-d bg-[#ECF7FF] text-center gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
                <div class="flex flex-col">
                    <div>
                        <p class="text-3xl md:text-3xl sm:text-xl font-semibold text-center">Choose your primary
                            platfrom</p>
                    </div>
                    <div class="flex justify-center mt-4">
                        <v-radio-group v-model="temp_platform" @change="triggerPlatformChange" class="mt-3" row>
                            <v-radio label="Instagram" value="instagram" />
                            <v-radio label="YouTube" value="youtube" />
                        </v-radio-group>
                    </div>
                </div>
                <div class="flex justify-center">
                    <img v-if="primary_platform == 'youtube'" src="@/assets/img/flylite-yt.png" width="80" alt="">
                    <img v-else-if="primary_platform == 'instagram'" src="@/assets/img/flylite-insta.svg" alt="">
                </div>
                <div class="fly-lite-text">
                    <p class="text-3xl font-semibold">Link Your
                        {{ primary_platform == 'instagram' ? 'Instagram ' : 'YouTube' }}</p>
                    <p class="pt-5 text-[#777E90] font-normal">Fly will use this account to source content for cross
                        posting</p>
                </div>
                <div>
                    <button v-if="primary_platform == 'instagram'" class="fly-lite-btn max-md:px-6"
                        @click="connectingHandle('instagram')">
                        Connect Instagram
                        <v-icon class="ml-2" color="white">mdi-arrow-right</v-icon>
                    </button>
                    <button v-else-if="primary_platform == 'youtube'" class="fly-lite-btn max-md:px-6"
                        @click="connectingHandle('youtube')">
                        Connect YouTube
                        <v-icon class="ml-2" color="white">mdi-arrow-right</v-icon>
                    </button>
                </div>
            </div>

            <!-- When isConnected is True -->
            <div v-else
                class="flex-d bg-[#ECF7FF] items-center text-center gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
                <div class="flex flex-col">
                    <div>
                        <p class="text-3xl md:text-3xl sm:text-xl font-semibold text-center">Choose your primary
                            platfrom</p>
                    </div>
                    <div class="flex justify-center mt-4">
                        <v-radio-group v-model="temp_platform" @change="triggerPlatformChange" class="mt-3" row>
                            <v-radio label="Instagram" value="instagram" />
                            <v-radio label="YouTube" value="youtube" />
                        </v-radio-group>
                    </div>

                </div>
                <!-- Instagram Connected Section -->
                <div class="flex justify-center relative">
                    <img v-if="primary_platform == 'youtube'" src="@/assets/img/flylite-yt.png" class="h-[5rem]"
                        width="74" alt="">
                    <img v-else src="@/assets/img/flylite-insta.svg" alt="">
                    <div class="green-check absolute ml-15 flex items-center justify-center">
                        <v-icon color="white" class="text-sm">mdi-check</v-icon>
                    </div>
                </div>
                <div class="fly-lite-text">
                    <p class="text-2xl font-semibold">
                        {{ primary_platform == 'instagram' ? 'Instagram Connected' : 'YouTube Connected' }}</p>
                    <p class="pt-3 text-lg text-[#777E90] font-normal">You are all set to cross-post from
                        {{ primary_platform == 'instagram' ? 'Instagram' : 'YouTube' }}!</p>
                </div>
                <div>
                    <button class="fly-lite-btn-disconnect" @click="messageDialog = true">
                        Disconnect
                        <v-icon class="ml-2" color="white">mdi-link-off</v-icon>
                    </button>
                </div>

                <!-- Instagram Pages Section -->
                <div v-if="primary_platpages.length > 0" :class="['gap-5 mt-8', dynamicGridClass]">
                    <div v-for="page in primary_platpages" :key="page.user_id"
                        @click="selectPage(page.user_id, page.user_name)"
                        :class="['bg-white cursor-pointer p-4 rounded-lg shadow-md text-center flex flex-col items-center gap-2 w-[14rem] justify-center', isSelected(page.user_id) ? 'selected-card' : 'disabled-card']">
                        <!-- Profile Picture -->
                        <div v-if="isSelected(page.user_id)"
                            class="green-check absolute ml-15 flex items-center justify-center">
                            <v-icon color="white" class="text-sm">mdi-check</v-icon>
                        </div>
                        <div class="rounded-full w-20 h-20 overflow-hidden flex items-center justify-center">
                            <img :src="page.picture" alt="profile picture" class="w-full h-full object-cover" />
                        </div>
                        <!-- User Handle -->
                        <p class="text-sm text-gray-500 truncate">@{{ page.user_name }}</p>
                    </div>
                </div>
                <div v-if="!selectedPage" class="flex items-center gap-2 text-red-600">
                    <v-icon color="red">mdi-alert-circle</v-icon>
                    <span v-if="primary_platform == 'instagram'">Select an Instagram profile from above to
                        continue</span>
                    <span v-else-if="primary_platform == 'youtube'">Select the channel from above to continue</span>
                </div>
                <div v-else class="flex max-sm:flex-col items-center gap-8 text-gray-500 ">
                    <div class=" flex gap-2 items-center">
                        <img v-if="validPlan" src="@/assets/img/fly-lite/eyeanimation.gif" width="30" height="30"
                            alt="">
                        <span v-if="validPlan">Watching
                            <span class="text-blue-500 font-semibold">@{{ primary_pagename }}</span> for new posts to
                            cross-post
                        </span>
                        <div v-else class="flex items-center gap-2 text-red-600">
                            <v-icon color="red">mdi-alert-circle</v-icon>
                            <span>
                                Subcribe to any lite plan to continue cross-posting
                            </span>
                        </div>
                    </div>
                    <div class="flex items-center justify-center" v-if="validPlan">
                        <label class="flex items-center cursor-pointer">
                            <div class="relative">
                                <input type="checkbox" class="sr-only" @change="pauseCrossPosting(isPaused = !isPaused)"
                                    :checked="!isPaused">

                                <div class="block w-[5.2rem] h-7 rounded-full transition-colors duration-300"
                                    :class="isPaused ? 'bg-[#bcbcbf]' : 'bg-[#8050DE]'">
                                </div>

                                <div class="dot absolute top-1 left-1 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300"
                                    :class="{ 'transform translate-x-[3.3rem]': !isPaused }">
                                </div>

                                <div class="absolute inset-0 flex items-center"
                                    :class="isPaused ? 'justify-end pr-[0.4rem]' : 'justify-start pl-[0.8rem]'">
                                    <span class="text-xs font-semibold" :class="isPaused ? 'text-white' : 'text-white'">
                                        {{ isPaused ? 'Resume' : 'Pause' }}
                                    </span>
                                </div>

                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <!-- Cross Posting Settings -->
            <!-- Cross Posting Settings Section -->
            <div class="relative">
                <div class="flex-d1 bg-[#F2F2F2] gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto"
                    :class="!checkIsConnected || !selectedPage ? 'disabled' : ''">
                    <p class="text-3xl md:text-3xl sm:text-xl font-semibold text-center">Choose the
                        {{ primary_platform == 'instagram' ? 'Instagram' : 'YouTube' }} content to
                        posts to other platforms?</p>
                    <div class="flex w-full md:w-[70%] relative">
                        <div class="list-decimal ml-5 flex flex-col gap-5">
                            <div class="fly-lite-setting">
                                <p class="md:text-base sm:text-sm max-md:text-[1rem]">1. All Posts</p>
                                <span class="toggle-wrapper" role="checkbox" :aria-checked="isActive1.toString()"
                                    tabindex="0" @click="toggle(1)" @keydown.space.prevent="toggle(1)">
                                    <span class="toggle-background"
                                        :class="{ 'gold-mid': isActive1, 'gray-lighter': !isActive1 }"></span>
                                    <span class="toggle-indicator" :style="indicatorStyles1"></span>
                                </span>
                            </div>
                            <div class="fly-lite-setting">
                                <p class="md:text-base sm:text-sm max-md:text-[1rem]">2.
                                    {{ primary_platform == 'instagram' ? 'Reels' : primary_platform == 'youtube' ?
                                    'Shorts' : '' }}
                                    Only</p>
                                <span class="toggle-wrapper" role="checkbox" :aria-checked="isActive2.toString()"
                                    tabindex="2" @click="toggle(2)" @keydown.space.prevent="toggle(2)"
                                    :class="!checkIsConnected && !selectedPage ? 'disabled' : ''">
                                    <span class="toggle-background"
                                        :class="{ 'gold-mid': isActive2, 'gray-lighter': !isActive2 }"></span>
                                    <span class="toggle-indicator" :style="indicatorStyles2"></span>
                                </span>
                            </div>
                            <div class="fly-lite-setting">
                                <p class="md:text-base sm:text-sm max-md:text-[1rem]">3. Post with particular hashtags
                                </p>
                                <span class="toggle-wrapper" role="checkbox" :aria-checked="isActive3.toString()"
                                    tabindex="2" @click="toggle(3)" @keydown.space.prevent="toggle(3)"
                                    :class="!checkIsConnected && !selectedPage ? 'disabled' : ''">
                                    <span class="toggle-background"
                                        :class="{ 'gold-mid': isActive3, 'gray-lighter': !isActive3 }"></span>
                                    <span class="toggle-indicator" :style="indicatorStyles3"></span>
                                </span>
                            </div>
                            <!-- Show input field and chips for hashtags when isActive3 is selected -->
                            <div v-if="isActive3" class="flex flex-col mt-4">
                                <div class="flex gap-2 items-center justify-between">
                                    <!-- Input Field for Hashtags (only shown when OK button is visible) -->
                                    <input v-if="showHashtagInput" v-model="newHashtag" @keyup.enter="addHashtag"
                                        class="bg-gray-200 p-2 rounded-lg w-full"
                                        :placeholder="!isDesktop ? 'Enter hashtag' : 'Enter hashtag and press Enter'" />
                                    <v-icon v-if="showHashtagInput && !isDesktop" @click="addHashtag" color="blue"
                                        small>mdi-plus</v-icon>

                                    <!-- Toggle between OK Button and Pencil Icon -->
                                    <button v-if="showHashtagInput" @click="confirmHashtags"
                                        class="bg-blue-500 text-white px-4 py-2 rounded-lg">
                                        Update
                                    </button>
                                </div>
                                <div class="mt-1 flex gap-2 flex-wrap items-center">
                                    <div class="chip">
                                        <span v-for="(hashtag, index) in hashtags" :key="index"
                                            class="bg-gray-300 text-sm px-4 py-2 rounded-full mb-2">
                                            {{ hashtag }}
                                            <v-icon v-if="showHashtagInput" @click="removeHashtag(index)" color="red"
                                                small>mdi-close</v-icon>
                                        </span>
                                        <div class="ml-4" v-if="!showHashtagInput">
                                            <v-icon @click="toggleHashtagEdit">mdi-pencil</v-icon> <!-- Pencil Icon -->
                                        </div>
                                    </div>

                                    <!-- Show pencil icon when input is hidden -->
                                    <!-- Error message if no hashtags are provided -->
                                    <div v-if="errorMessage" class="text-red-600 mt-2">
                                        {{ errorMessage }}
                                    </div>
                                </div>

                            </div>
                            <div v-if="primary_platform == 'instagram'" class="fly-lite-setting">
                                <p class="md:text-base sm:text-sm max-md:text-[1rem]">4. Single Image Posts Only</p>
                                <span class="toggle-wrapper" role="checkbox" :aria-checked="isActive4.toString()"
                                    tabindex="1" @click="toggle(4)" @keydown.space.prevent="toggle(4)"
                                    :class="!checkIsConnected && !selectedPage ? 'disabled' : ''">
                                    <span class="toggle-background"
                                        :class="{ 'gold-mid': isActive4, 'gray-lighter': !isActive4 }"></span>
                                    <span class="toggle-indicator" :style="indicatorStyles4"></span>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Small text in top left corner -->
                <div v-if="!checkIsConnected || !selectedPage"
                    class="disabled_class absolute top-4 left-4 flex text-center items-center justify-center text-red-600">
                    <p class="font-semibold md:text-sm sm:text-xs opacity-100">
                        {{ !checkIsConnected ? `${primary_platform == 'instagram' ? 'Instagram' : 'YouTube'} Connection
                        Required`: (primary_platform == 'instagram' ? 'Select an Insta page' :
                            'Select the YouTube Channel') }}
                    </p>
                </div>
            </div>


            <!-- Social Platforms Section -->
            <div class="flex-d1 bg-[#F3F7F5] gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
                <div class="flex-d1 bg-[#F3F7F5] gap-11 font-poppins rounded-3xl w-full md:w-auto"
                    :class="!checkIsConnected || !selectedPage ? 'disabled' : ''">
                    <div class="text-center">
                        <p class="text-3xl font-semibold text-center">Select the pages and profiles to cross-post on</p>
                        <p class="pt-5 text-[#777E90] text-lg font-normal max-md:text-center max-md:p-2">Link your other
                            social
                            accounts and watch the magic
                            happen</p>
                    </div>
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 relative">
                        <div v-for="platform in filteredPlatforms" :key="platform.id"
                            class="bg-white p-6 rounded-lg shadow-md items-center text-center flex flex-col gap-4">
                            <div class="flex flex-col items-center">
                                <div v-if="platform.isConnected" class="relative">
                                    <div
                                        class="rounded-full w-24 h-24 overflow-hidden flex items-center justify-center">
                                        <img class="w-full h-full object-cover" :src="platform.picture"
                                            @error="setDefaultImage($event)" alt="">
                                    </div>
                                    <img class="plat-icon w-7 h-7" :src="getPlatformIcon(platform.id)" alt="">
                                    <div v-if="platform.isConnected"
                                        class="green-check absolute top-1 right-1 flex items-center justify-center">
                                        <v-icon color="white" class="text-sm">mdi-check</v-icon>
                                    </div>
                                </div>
                                <div v-else class="w-24 h-24">
                                    <img :src="getPlatformIcon(platform.id)" alt="">
                                </div>
                                <p class="mt-5 text-lg fontFamily-poppins font-bold">{{
                                    platform.isConnected ? trimText(platform.user_name) : trimText(platform.name) }}</p>
                            </div>
                            <v-combobox v-if="platform.pages?.length" v-model="platform.selectedProfiles"
                                :items="platform.pages" :label="fetchLabel(platform.id)" @input="updateCrossPlatforms"
                                :item-text="item => getTrimmedText(item, platform.id)" class="mt-4 w-full sm:w-[14rem]"
                                :multiple="!checkMultiPlatform(platform.id)" :persistent-placeholder="false"
                                item-value="name" return-object dense chips deletable-chips>
                                <!-- Only show non-null profiles in the selection -->
                                <template v-if="platform.selectedProfiles?.length > 0"
                                    v-slot:selection="{ item, index }">
                                    <!-- Check if the item is valid (non-null/undefined) before displaying -->
                                    <span v-if="item && index < maxDisplay">{{ item.title || item.name }},</span>
                                    <span v-if="index === maxDisplay" class="text-blue-600">
                                        (+{{ platform.selectedProfiles.length - maxDisplay }} more)
                                    </span>
                                </template>
                            </v-combobox>
                            <button @click="manageConnection(platform)"
                                :class="platform.isConnected ? 'flylite-connected' : 'flylite-not-connected'">
                                {{ platform.isConnected ? 'Disconnect' : 'Connect' }}
                            </button>
                        </div>
                    </div>
                    <!-- Small text in top left corner -->
                    <!-- <button 
                        class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out md:text-md "
                        @click="updateCrossPlatforms()">
                        {{ 'Update Settings' }}
                    </button> -->
                </div>
                <div v-if="!checkIsConnected || !selectedPage"
                    class="disabled_class absolute top-4 left-4 flex text-center items-center justify-center text-red-600">
                    <p class="font-semibold text-sm opacity-100">
                        {{ !checkIsConnected ? `${primary_platform == 'instagram' ? 'Instagram' : 'YouTube'} Connection
                        Required`:
                            (primary_platform == 'instagram' ? 'Select an Insta page' : 'Select the YouTube Channel') }}
                    </p>
                </div>
            </div>
            <div v-if="checkIsConnected && checkAuth"
                class="text-center text-[#22262e] text-3xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-3xl ">
                Notifications to be sent to
            </div>

            <!-- Properly centered subline with corrected spelling -->
            <p v-if="checkIsConnected && checkAuth" class="text-center text-[#667790] text-lg -mt-8">
                Notifications are sent after every post, weekly stats, and subscription renewals.
            </p>

            <!-- Contact Information Inputs -->
            <div v-if="checkIsConnected && checkAuth"
                class="flex justify-center max-md:ml-0 max-md:justify-center -mt-4">
                <div class="gap-6 flex flex-wrap justify-center items-center">
                    <!-- Phone Number Input with vue-tel-input-vuetify -->
                    <vue-tel-input-vuetify v-model="newPhoneNumber"
                        class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3 max-w-[309px]"
                        mode="international">
                        <input type="text"
                            class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
                            placeholder="Phone Number" />
                    </vue-tel-input-vuetify>

                    <!-- Email Input (using same styles) -->
                    <input type="email" v-model="newEmail" placeholder="Email"

                        class="bg-[#f2f6fa] flex flex-row gap-3 pl-9 w-full outline-[#8056DE] md:w-[309px] h-[3.3rem] items-start rounded-[26px] max-w-[309px]" />
                    <!-- Save Button -->
                    <button @click="saveContactInfo"
                        class="px-8 py-4 bg-[#8056DE] text-white rounded-full text-sm max-md:text-xs max-md:w-full font-semibold max-w-[309px]">
                        Save
                    </button>
                </div>
            </div>

            <div class="flex-d bg-[#ECF7FF] font-poppins justify-center rounded-3xl w-full md:w-auto items-center py-9 flex-col" v-if="checkIfTestBtnValid">

                <h1 class="flex flex-col justify-center items-center text-center w-full text-xl md:text-2xl mb-6">Test cross posting manually using the button below. <br /> <span v-if="!is_test_user">This button will be active for the 48 hours after your signup.</span></h1>
                <button @click="fetchInstaPost()" 
                class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out md:text-md" >
                    Test Cross Posting
                </button>
                <v-dialog v-model="igPostDialog" max-width="900">
                    <v-card scrollable v-if="igPosts">
                        <v-card-title>Select a post to cross post</v-card-title>
                        <div class="flex justify-end w-full mt-4 mr-4">
                            <v-icon color="red" class="absolute right-4 cursor-pointer" first-letter:
                                @click="igPostDialog = false">mdi-close
                            </v-icon>
                        </div>
                        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 posts p-4">
                            <post-card v-for="(post, i) in igPosts" :post="post" :key="i"
                                @closeDialog="igPostDialog = false"></post-card>
                        </div>
                    </v-card>
                </v-dialog>
            </div>

            <!-- Subscription Section -->
            <div class="flex-d1 bg-[#F7F6FF] gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
                <div :class="!checkIsConnected ? 'disabled' : ''" class="flex flex-col gap-7">

                    <!-- <p class="text-3xl font-semibold text-center">Subscription</p> -->

                    <div>
                        <p class="text-3xl font-semibold text-center">Subscribe to get started</p>
                        <p class="text-lg pt-4 px-3 text-center">You need to choose one of the plans to activate cross posting on your account.</p>
                    </div>

                    <div class="grid gap-8 max-md:grid-cols-1" :class="annualSubs ? 'grid-cols-1' : 'grid-cols-2'">
                        <lite-trial :primaryPlatform="primary_platform" :connectionStat="checkIsConnected"
                            @subscribed="checkTrial($event)" v-if="!annualSubs" />
                        <lite-subscription @subscribed="checkPlan($event)" />
                    </div>
                </div>
                <div v-if="!checkIsConnected"
                    class="disabled_class absolute top-4 left-4 flex text-center items-center justify-center text-red-600">
                    <p class="font-semibold text-sm opacity-100">{{primary_platform}} Connection Required</p>
                </div>
            </div>
            <v-dialog v-model="messageDialog" persistent max-width="500px">
                <v-card>
                    <!-- Updated Heading -->
                    <v-card-title class="headline">Disconnect from cross-posting</v-card-title>
                    <!-- Updated Text -->
                    <v-card-text>
                        <strong>Are you sure you want to disconnect from cross posting? All the saved configurations
                            will be
                            disabled.</strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- Cancel Button -->
                        <v-btn color="grey" text @click="messageDialog = false">Cancel</v-btn>
                        <!-- Confirm Button -->
                        <v-btn color="primary" @click="disconnectPrimary(primary_platform)">Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="confirmPrimaryChange" persistent max-width="500px">
                <v-card>
                    <!-- Updated Heading -->
                    <v-card-title class="headline">Change primary platform</v-card-title>
                    <!-- Updated Text -->
                    <v-card-text>
                        <strong>
                            Are you sure you want to change the primary platform from {{ primary_platform }} to {{
                                temp_platform }},
                            current platform will be disconnected and all the saved configuration will be lost
                        </strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- Cancel Button -->
                        <v-btn color="grey" text
                            @click="confirmPrimaryChange = false, temp_platform = primary_platform">Cancel</v-btn>
                        <!-- Confirm Button -->
                        <v-btn color="primary" @click="changePrimaryPlatform">Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showDialogConnection" persistent max-width="600px">
                <mastodon-connection v-if="mastodonBlueskyConnection === 'mastodon'"
                    @close-dialog="showDialogConnection = false" />
                <bluesky-connection v-else-if="mastodonBlueskyConnection === 'bluesky'"
                    @close-dialog="showDialogConnection = false" />
            </v-dialog>
        </div>
        <!-- <testing-post/> -->
        <LitePage3 v-if="!checkAuth" :handleLogin="handleLogin" />
        <div v-if="!checkAuth"
            class="text-center text-[#22262e] text-5xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-3xl">
            Supported networks</div>

        <div v-if="!checkAuth"
            class="flex flex-wrap justify-center gap-4 md:grid md:grid-cols-[repeat(3,1fr)_auto_repeat(3,1fr)_auto_repeat(3,1fr)] md:gap-0 lg:flex lg:justify-between lg:gap-8">
            <img src="@/assets/img/x_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/insta_n2.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/fb_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/gmb_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/pint_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/tb_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/yt_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/tiktok_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/linkedin_n.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/social-logo/mastodon.png" class="w-[48.04px] h-[48px]" alt="">
            <img src="@/assets/img/social-logo/bluesky.png" class="w-[48.04px] h-[48px]" alt="">
            <!-- <img src="@/assets/img/social-logo/snapchat.png" class="w-[48.04px] h-[48px] rounded-full" alt=""> -->

        </div>


    </div>
</template>

<script>
import LitePage3 from "./LitePage3.vue";
import LiteTrial from "./LiteTrial.vue"
import LiteSubscription from '@/pages/fly-lite/LiteSubcription.vue';
import { auth, db, flyCrossPlatformInstagram, colUsers } from '@/utils/firebase.utils';
import { collection, deleteDoc, doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import MastodonConnection from "@/components/connectedApps/mastodon/mastodon.connection.vue";
import BlueskyConnection from "@/components/connectedApps/bluesky/bluesky.connection.vue";
import { onAuthStateChanged } from 'firebase/auth';
import PostCard from "@/components/post/Pc.vue";
import TestingPost from "@/components/post/TestingPost.vue";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
export default {
    props: {
        checkAuth: {
            type: Boolean,
            default: false,
        },
        handleLogin: {
            type: Function,
            required: true
        }
    },
    components: {
        LiteSubscription,
        PostCard,
        TestingPost,
        LitePage3,
        LiteTrial,
        MastodonConnection,
        BlueskyConnection,
    },
    data() {
        return {
            showDialogConnection:false,
            mastodonBlueskyConnection:'',
            annualSubs: false,
            validPlan: false,
            temp_platform: '',
            changingPrimary: false,
            messageDialog: false,
            confirmPrimaryChange: false,
            newPrimary: null,
            phone_number: null,
            email: null, // Store email         
            newEmail: "",
            newPhoneNumber: "",
            unsubscribeFetching: null,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
            isPaused: false,
            isDesktop: false,
            platforms: [
                { name: 'Facebook', picture: '', user_name: '', isConnected: false, id: 'facebook', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Threads', picture: '', user_name: '', isConnected: false, id: 'threads', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Instagram', picture: '', user_name: '', isConnected: false, id: 'instagram', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'YouTube', picture: '', user_name: '', isConnected: false, id: 'youtube', user_id: null, pages: [], selectedProfiles: null },
                { name: 'Google My Business', picture: '', user_name: '', isConnected: false, user_id: null, id: 'google_business_profile', pages: [], selectedProfiles: [] },
                { name: 'Pinterest', picture: '', user_name: '', isConnected: false, id: 'pinterest', user_id: null, pages: [], selectedProfiles: null },
                { name: 'Twitter', picture: '', user_name: '', isConnected: false, id: 'twitter', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'LinkedIn', picture: '', user_name: '', isConnected: false, id: 'linkedin', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Tumblr', picture: '', user_name: '', isConnected: false, id: 'tumblr', user_id: null, pages: [], selectedProfiles: null },
                { name: 'Tiktok', picture: '', user_name: '', isConnected: false, id: 'tiktok', user_id: null, pages: [], selectedProfiles: null },
                { name: 'BlueSky', picture: '', user_name: '', isConnected: false, id: 'bluesky', user_id: null, pages: [], selectedProfiles: null },
                { name: 'Mastodon', picture: '', user_name: '', isConnected: false, id: 'mastodon', user_id: null, pages: [], selectedProfiles: null },
                // { name: 'Reddit', picture: '', user_name: '', isConnected: false, id: 'reddit', user_id: null, pages: [], selectedProfiles: null },
                // { name: 'Snapchat', picture: '', user_name: '', isConnected: false, id: 'snapchat', user_id: null, pages: [], selectedProfiles: null },
            ],
            isAuthenticated: null,
            checkIsConnected: false,
            primary_platform: null,
            maxDisplay: 1,
            selectedPage: null,
            singlePagePlatforms: ['tumblr', 'reddit', 'pinterest', 'youtube'],
            primary_platpages: [],
            primary_pagename: '',
            igPosts: [],
            ytCategories: [
                { name: "Cars & Vehicles", value: 2 },
                { name: "Comedy", value: 23 },
                { name: "Education", value: 27 },
                { name: "Entertainment", value: 24 },
                { name: "Film & Animation", value: 1 },
                { name: "Gaming", value: 20 },
                { name: "How-to & Style", value: 20 },
                { name: "Music", value: 10 },
                { name: "News & Politics", value: 25 },
                { name: "Non-profits Activism", value: 29 },
                { name: "People & Blogs", value: 22 },
                { name: "Pets & Animals", value: 15 },
                { name: "Science & Technology", value: 29 },
                { name: "Sports", value: 17 },
                { name: "Travel & Events", value: 19 },
            ],
            newConnection: false,
            igPostDialog: false,
            multiProfilePlatforms: [],
            singleAccountPlatforms: [],
            hashtags: [],
            newHashtag: '',
            unsubscribePlan: null,
            showHashtagInput: true,
            errorMessage: '',
            checkIfTestBtnValid: true,
            is_test_user: false,
        };
    },
    watch: {
        isAuthenticated() {
            this.fetchUserPhoneNumber()
        },
        checkAuth() {
            this.fetchUserContactInfo();
        },
        primary_platform(val) {
            this.fetchConnectedApps();
            this.temp_platform = val;
        },
        'hashtags.length'(newLength) {
            if (newLength > 0) {
                this.errorMessage = '';
            }
        }
    },
    computed: {
        filteredPlatforms() {
            return this.platforms.filter((item) => item.id !== this.primary_platform);
        },
        indicatorStyles1() {
            return { transform: this.isActive1 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        indicatorStyles2() {
            return { transform: this.isActive2 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        indicatorStyles3() {
            return { transform: this.isActive3 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        indicatorStyles4() {
            return { transform: this.isActive4 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        authUserName() {
            return auth.currentUser ? auth.currentUser.displayName || "User" : "User";
        },
        dynamicGridClass() {
            const length = this.primary_platpages.length;
            if (length === 4) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4';
            } else if (length === 3) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3';
            } else if (length === 2) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2';
            } else if (length === 1) {
                return 'grid grid-cols-1';
            } else {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5'; // Default for more than 4 items
            }
        },
    },
    mixins: [connectedAppsMixins],
    methods: {
        changePrimaryPlatform() {
            if (this.checkIsConnected) {
                this.disconnectPrimary(this.primary_platform);
            }
            this.primary_platform = this.temp_platform;
            this.confirmPrimaryChange = false;
            const userRef = doc(db, "users", auth.currentUser.uid);
            updateDoc(userRef, {
                lite_primary_platform: this.temp_platform,
            })
        },
        checkTrial(val) {
            this.validPlan = val;
        },
        checkPlan(val) {
            this.validPlan = val;
            this.annualSubs = true;
            return;
        },
        triggerPlatformChange(newPlatform) {
            if (newPlatform !== this.primary_platform) {
                // Prompt for confirmation
                if (this.checkIsConnected) {
                    this.confirmPrimaryChange = true;
                }
                else {
                    this.changePrimaryPlatform();
                }
            }
        },
        async fetchUserContactInfo() {
            try {
                if (!this.checkAuth) return;
                const userRef = doc(db, "users", auth.currentUser.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    this.email = userData.email || "";
                    this.phone_number = userData.phone_number || "";
                    this.newPhoneNumber = this.phone_number;
                    this.newEmail = this.email;
                }
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        },
        async saveContactInfo() {
            if (!this.newPhoneNumber || !this.newEmail) {
                alert("Please enter a valid phone number and email.");
                return;
            }

            try {
                const userRef = doc(db, "users", auth.currentUser.uid);

                // Update both phone number and email in Firestore
                await updateDoc(userRef, {
                    phone_number: this.newPhoneNumber,
                    email: this.newEmail,
                });

                this.phone_number = this.newPhoneNumber;
                this.email = this.newEmail;
                this.$snackbar.show("Contact information updated successfully!");

            } catch (error) {
                console.error("Error saving contact info:", error);
            }
        },
        checkScreenSize() {
            this.isDesktop = window.innerWidth > 768; // Adjust the breakpoint as needed
        },
        async checkIfPlatformConnected(platform) {
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            let platform_crosspost = (await getDoc(docRef)).data() || {};
            const platformData = platform_crosspost.platforms.find(p => p.name === platform);
            switch (platform) {
                case 'facebook':
                case 'linkedin':
                case 'tumblr':
                case 'pinterest':
                case 'instagram':
                case 'reddit':
                    if (platformData?.pages && platformData?.pages?.length > 0) {
                        return true;
                    } else return false;
            }
        },
        async showTestCrossPostBtn() {
            const userDocRef = doc(colUsers, this.uid);
            let userDoc = (await getDoc(userDocRef)).data() || {};
            if(userDoc.is_test_user) {
                this.checkIfTestBtnValid = true;
                this.is_test_user = true;
                return;
            }
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            let created_at = userDoc?.created_time;
            if (created_at) {
                let createdAtTimestamp = new Date(created_at).getTime();
                if (created_at.seconds) {
                    createdAtTimestamp = new Date(created_at.seconds * 1000).getTime();
                }
                const currentTime = new Date().getTime();
                const hoursDifference = (currentTime - createdAtTimestamp) / (1000 * 60 * 60);
                if (hoursDifference > 48) {
                    this.checkIfTestBtnValid = false;
                    return false;
                }
            }
            const platformCheckDocRef = doc(db, 'fly_cross_post_platform_checks', 'platforms');
            let platformCheckDoc = (await getDoc(platformCheckDocRef)).data() || {};
            const docSnap = await getDoc(docRef);
            let platform_crosspost = (await getDoc(docRef)).data() || {};
            this.singleAccountPlatforms = platformCheckDoc.single_account_platforms;
            this.multiProfilePlatforms = platformCheckDoc.multi_profiles_platforms;

            if (!docSnap.exists()) {
                this.checkIfTestBtnValid = false;
                return;
            }
            else if (platform_crosspost) {
                if (!platform_crosspost?.configuration) {
                    this.checkIfTestBtnValid = false;
                    return;
                }
                if (platform_crosspost?.configuration && platform_crosspost?.platforms && platform_crosspost?.platforms?.length) {
                    let user_platforms = [];
                    platform_crosspost?.platforms?.map((platform) => {
                        user_platforms.push(platform.name);
                    })
                    const atleastOneSingleAccountConnected = user_platforms.some(platform => this.singleAccountPlatforms?.includes(platform));
                    const multi_profiles_user_platforms = user_platforms.filter(platform => this.multiProfilePlatforms?.includes(platform));
                    if (atleastOneSingleAccountConnected) {
                        this.checkIfTestBtnValid = true;
                        return;
                    }
                    else {
                        for (let platform of multi_profiles_user_platforms) {
                            let isConnected = await this.checkIfPlatformConnected(platform);
                            if (isConnected) {
                                this.checkIfTestBtnValid = true;
                                return;
                            }
                        }
                    }
                } else {
                    this.checkIfTestBtnValid = false;
                    return;
                }
            }
            this.checkIfTestBtnValid = false;
            return;
        },
        async fetchInstaPost() {
            const vm = this;
            vm.$loader.show('Fetching your Instagram posts');
            let currentUID = this.currentUID || vm.uid;
            if (!vm.currentUID && !vm.WID) return;
            try {
                vm.isLoading = true;
                const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
                let platform_crosspost = (await getDoc(docRef)).data() || {};
                let fetchPostss = httpsCallable(functions, "instagramCrossPostsFetch");
                let result = (
                    await fetchPostss({
                        uid: this.uid,
                        wid: 'default',
                        since: "test_user",
                        user_id: platform_crosspost.configuration.user_id
                    })
                ).data;
                let posts = result;
                vm.igPosts = [...posts];
                vm.igPostDialog = true;
                vm.$set(igPostDialog, true)
                vm.$loader.hide();
                vm.isLoading = false;
            } catch (error) {
                vm.$loader.hide();
                // vm.handleError(error);
            }
        },
        async fetchUserPhoneNumber() {
            try {

                const userRef = doc(db, "users", auth.currentUser.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    this.email = userData.email || "";
                    this.phone_number = userData.phone_number || null;
                    // console.log(this.email, this.phone_number)
                }
            } catch (error) {
                console.error("Error fetching phone number:", error);
            }
        },
        fetchLabel(name) {
            switch (name) {
                case 'facebook':
                case 'linkedin':
                    return "Pages";
                case 'instagram':
                    return "Profiles";
                case 'pinterest':
                    return "Boards";
                case 'reddit':
                    return "Sub Reddits";
                case 'tumblr':
                    return "Blogs";
                case 'google_business_profile':
                    return "Locations";
                case 'youtube':
                    return "Category";
                default:
                    return "Profiles";
            }
        },
        setDefaultImage(event) {
            event.target.src = require('@/assets/img/fly-lite/manager.png'); // Path to your default image
        },
        trimText(text, length = 20) {
            if (!text || typeof text !== 'string') {
                return '';
            }
            return text.length > length ? text.slice(0, length - 3) + '...' : text;
        },
        // Method to get trimmed text based on platform ID
        getTrimmedText(item, platformId) {
            // For 'google_business_profile' use 'title', otherwise 'name'
            const text = platformId === 'google_business_profile' ? item.title : item.name;
            return this.trimText(text);
        },
        selectPage(user_id, name) {
            if (this.selectedPage == user_id) return;
            this.selectedPage = user_id; // Set the selected page by user_id
            this.primary_pagename = name;
            this.updatePrimaryProfile();
        },
        async pauseCrossPosting(checkPaused) {
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            await setDoc(docRef, {
                paused: checkPaused,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
            }, { merge: true });
            if (this.primary_platform == 'youtube') {
                this.subscribeToYoutubeWebhook(!checkPaused)
            }
        },
        async subscribeToYoutubeWebhook(status) {
            const userRef = doc(db, 'users', this.uid);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();
            const currentDate = new Date();
            const liteTrial = userData?.fly_trial_plan_valid_until?.toDate();
            const checkTrial = liteTrial && liteTrial > currentDate;
            console.log(userData?.fly_lite_plan, "trial pack", checkTrial);
            if ((userData?.fly_lite_plan == 'trial' && checkTrial) || userData?.fly_lite_plan == 'annual') {
                const subscribetoYoutube = httpsCallable(functions, "youtubeCrossPosting");
                subscribetoYoutube({ subscription: status, uid: this.uid, wid: 'default' });
            }
            else {
                console.log("subscription expired");
            }

        },
        async updatePrimaryProfile() {
            let page = null;
            this.primary_platpages.forEach((item) => {
                if (item.user_id == this.selectedPage) {
                    page = item;
                }
            });
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            let action = null;
            if (this.isActive1) {
                action = "all_posts";
            } else if (this.isActive2) {
                action = this.primary_platform == 'instagram' ? "reels" : 'shorts';
            } else if (this.isActive3) {
                action = "hashatags";
            } else if (this.isActive4) {
                action = "single_image";
            } else {
                action = "all_posts";
            }
            const profiles = [];
            // console.log("entering updateCrossplat");
            this.platforms.forEach((item) => {
                if (item.isConnected && item.id !== this.primary_platform) {
                    profiles.push({
                        name: item.id,
                        pages: Array.isArray(item.selectedProfiles) ? item.selectedProfiles : item.selectedProfiles ? [item.selectedProfiles] : [],
                        user_id: item.user_id,
                    });
                }
            });
            await setDoc(docRef, {
                action: {
                    type: action
                },
                paused: this.isPaused,
                configuration: page,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
                platforms: profiles,
                uid: this.uid,
                wid: 'default',
            }, { merge: true });
            if (this.primary_platform == 'youtube' && this.validPlan) {
                this.subscribeToYoutubeWebhook(true)
            }

        },
        async updateCrossSetting() {
            let action = null;
            if (this.isActive1) {
                action = "all_posts";
            } else if (this.isActive2) {
                action = this.primary_platform == 'instagram' ? "reels" : 'shorts';
            } else if (this.isActive3) {
                action = "hashtags";
            }
            else if (this.isActive4) {
                action = "single_image";
            } else {
                action = "all_posts";
                this.isActive1 = true;
            }
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            await setDoc(docRef, {
                action: {
                    type: action
                },
                created_at: (new Date()).toISOString(),
                last_post_time: "",
            }, { merge: true });
            this.$snackbar.show("Updated cross platform post settings");
        },
        async updateCrossPlatforms() {
            const profiles = [];
            if (!this.selectedPage) {
                return;
            }
            this.platforms.forEach((item) => {
                if (item.isConnected && item.id !== this.primary_platform) {
                    profiles.push({
                        name: item.id,
                        pages: Array.isArray(item.selectedProfiles) ? item.selectedProfiles : item.selectedProfiles ? [item.selectedProfiles] : [],
                        user_id: item.user_id,
                    });
                }
            });
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            await setDoc(docRef, {
                platforms: profiles,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
                uid: this.uid,
                wid: 'default'
            }, { merge: true });
            this.$snackbar.show("Updated cross platform post settings");
            this.showTestCrossPostBtn();
        },
        isSelected(user_id) {
            // console.log(this.selectedPage,"enteredin into isSelected",user_id);
            return this.selectedPage === user_id; // Return true if the page is selected
        },
        checkMultiPlatform(name) {
            return this.singlePagePlatforms.includes(name);
        },
        async fetchConnectedApps() {
            const collectionRef = collection(db, 'users', this.uid, 'fly_workspaces', 'default', 'connected-apps');
            // Set up a real-time listener
            if (this.unsubscribeFetching) {
                console.log("unsubscribing from fetchConnected Onsnapshot")
                this.unsubscribeFetching();
            }
            this.unsubscribeFetching = onSnapshot(collectionRef, async (querySnapshot) => {
                let docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
                const docSnap = await getDoc(docRef);
                const connectedAppsMap = {}; // Use a map for easier access by ID
                // Iterate over each document to gather connected apps
                querySnapshot.forEach((doc) => {
                    let childrenArray = doc.data()?.pages ||
                        doc.data()?.profiles ||
                        doc.data()?.boards ||
                        doc.data()["sub-reddits"] ||
                        doc.data()?.blogs ||
                        (Array.isArray(doc.data()?.accounts) && doc.data().accounts[0]?.locations) ||
                        doc.data()?.users || [];

                    // Special handling for LinkedIn to include user_id
                    if (doc.id === 'linkedin') {
                        childrenArray = childrenArray.map((d) => ({
                            ...d,
                            user_id: doc.data().user_id
                        }));
                    }
                    if (doc.id === 'youtube' && doc.data().channel ) {
                        const userName = (doc.data()?.channel?.snippet?.customUrl).replace("@", "");
                        if (this.primary_platform == 'youtube') {
                            childrenArray = [
                                {
                                    user_id: doc.data()?.channel?.id,
                                    user_name: userName,
                                    picture: doc.data()?.channel?.snippet?.thumbnails?.high?.url
                                }
                            ];
                        }
                        else {
                            childrenArray = this.ytCategories
                        }
                    }
                    if (doc.id === this.primary_platform) {
                        this.primary_platpages = childrenArray;
                    }
                    // Add connected app to connectedApps array
                    const connectedAppData = {
                        id: doc.id,
                        ...doc.data(),
                        pages: childrenArray // Store childrenArray in pages field
                    };

                    // Add the connected app to the map for faster lookup
                    connectedAppsMap[doc.id] = connectedAppData;
                });

                // Update platforms based on connected apps
                this.platforms = this.platforms.map((platform) => ({
                    ...platform,
                    isConnected: !!connectedAppsMap[platform.id], // Use the map for fast lookup
                    pages: connectedAppsMap[platform.id]?.pages || [], // Assign pages if connected, otherwise empty array
                    user_id: connectedAppsMap[platform.id]?.user_id || connectedAppsMap[platform.id]?.name,
                    picture: connectedAppsMap[platform.id]?.picture || '',
                    user_name: connectedAppsMap[platform.id]?.name || 'Admin',
                    selectedProfiles: !connectedAppsMap[platform.id] && !connectedAppsMap[this.primary_platform]
                        ? platform.selectedProfiles
                        : (['tumblr', 'pinterest','youtube'].includes(platform.id) ? null : []),
                }));
                // Check if Instagram is connected
                this.checkIsConnected = !!connectedAppsMap[this.primary_platform]; // Direct lookup from map
                this.$emit('checkInsta', this.checkIsConnected);
                this.$emit('primary_platform', this.primary_platform);
                if (!this.checkIsConnected) {
                    this.setDataDefault();
                    this.setDefaultPlatSetting();
                    const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
                    await deleteDoc(docRef);
                }
                if (docSnap.exists() && this.checkIsConnected) {
                    const docData = docSnap.data();
                    this.isPaused = docData?.paused;
                    this.selectedPage = docData?.configuration?.user_id;
                    this.primary_pagename = docData?.configuration?.user_name;
                    if (docData?.action?.type == "single_image") this.isActive4 = true;
                    else if (docData?.action?.type == "reels" || docData?.action?.type == "shorts") this.isActive2 = true;
                    else if (docData?.action?.type == "all_posts") this.isActive1 = true;
                    else if (docData?.action?.type == "hashtags") {
                        this.isActive3 = true;
                        this.hashtags = docData?.action?.value || [];
                    }
                    docData?.platforms?.forEach((item) => {
                        this.platforms.some((platform) => {
                            if (platform.id === item.name) {
                                platform.selectedProfiles = ["pinterest", "reddit", "tumblr","youtube"].includes(item.name)
                                    ? item?.pages[0] || null  // Single selection for certain platforms
                                    : item?.pages || [];     // Multiple selections for others
                                return true;  // Exiting once a match is found
                            }
                        });
                    });
                    if (this.newConnection) {
                        const isConnected = this.platforms.some(platform =>
                            platform.id === this.newConnection && platform.isConnected
                        );

                        if (isConnected) {
                            this.newConnection = false;
                            this.updateCrossPlatforms();
                        }
                    }
                    if (this.hashtags.length > 0) {
                        this.showHashtagInput = false;
                    }
                } else {
                    this.isActive1 = true;
                    this.selectedPage = null;
                    this.primary_pagename = '';
                }
            }, (error) => {
                console.error('Error fetching connected apps in real-time:', error);
            });
        },
        async disconnectPrimary(itemAlias) {
            // this.primary_platform = confirmChange
            this.changingPrimary = false;
            this.messageDialog = false;
            this.confirmPrimaryChange = false;
            this.disconnectConnection(itemAlias, 'personal', 'default');
            this.setDataDefault();
            this.setDefaultPlatSetting();
            if (itemAlias == 'youtube') {
                const subscribetoYoutube = httpsCallable(functions, "youtubeCrossPosting");
                subscribetoYoutube({ subscription: false, uid: this.uid, wid: 'default' });
                console.log("unsubscribed successfully")
            }
            const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
            await deleteDoc(docRef);
            this.showTestCrossPostBtn();
        },
        setDataDefault() {
            // Reset all the data to its default state
            this.isPaused = false,
                this.isActive1 = true;
            this.isActive1 = true;
            this.isActive2 = false;
            this.isActive3 = false;
            this.isActive4 = false;
            this.hashtags = [];
            this.selectedPage = null;
            this.primary_platpages = [];
            this.isAuthenticated = null;
            this.checkIsConnected = false;
            this.primary_pagename = '';
        },
        loginHandle() {
            this.handleLogin();
        },
        toggle(index) {
            if (index === 1) {
                this.isActive1 = !this.isActive1;
                this.isActive2 = false;
                this.isActive3 = false;
                this.isActive4 = false;
            } else if (index === 2) {
                this.isActive2 = !this.isActive2;
                this.isActive1 = false;
                this.isActive3 = false;
                this.isActive4 = false;
            } else if (index === 3) {
                this.isActive3 = !this.isActive3;
                this.isActive2 = false;
                this.isActive1 = false;
                this.isActive4 = false;
            }
            else if (index === 4) {
                this.isActive4 = !this.isActive4;
                this.isActive1 = false;
                this.isActive2 = false;
                this.isActive3 = false;
            }
            this.updateCrossSetting();
        },
        addHashtag() {
            let cleanedHashtag = this.newHashtag.trim().toLowerCase().replace(/\s+/g, '');
            if (cleanedHashtag && cleanedHashtag[0] !== '#') {
                cleanedHashtag = `#${cleanedHashtag}`;
            }
            if (cleanedHashtag && !this.hashtags.includes(cleanedHashtag)) {
                this.hashtags.push(cleanedHashtag);
                this.newHashtag = '';
            }
        },
        removeHashtag(index) {
            this.hashtags.splice(index, 1);
        },
        async confirmHashtags() {
            if (this.hashtags.length === 0) {
                this.errorMessage = 'Please add at least one hashtag';
                return;
            }
            this.errorMessage = '';
            try {
                const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
                await setDoc(docRef, {
                    action: {
                        type: 'hashtags',
                        value: this.hashtags
                    },
                    created_at: new Date().toISOString()
                }, { merge: true });
                this.showHashtagInput = false;
                this.$snackbar.show("Hashtags saved successfully!");

            } catch (error) {
                console.error("Error saving hashtags to Firebase:", error);
            }
        },
        editHashtags() {
            this.isActive3 = true;
        },
        toggleHashtagEdit() {
            this.showHashtagInput = true;
        },
        getPlatformIcon(id) {
            return require(`@/assets/img/social-logo/${id}.png`);
        },
        connectingHandle(itemAlias) {
            if(itemAlias == 'mastodon'||itemAlias=='bluesky'){
                this.showDialogConnection = true;
                this.mastodonBlueskyConnection = itemAlias;
            }
            else{
                this.initConnection(itemAlias, "personal", 'default');
            }
            const docRef = doc(db, 'users', this.uid, 'fly_workspaces', 'default', 'connected-apps', itemAlias);
            const unsubscribeConnection = onSnapshot(docRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    // Document data available
                    const data = docSnapshot.data();
                    if (data.connection_status == 'connected') {
                        unsubscribeConnection();
                        this.$loader.hide();
                    }
                    else {
                        this.$loader.show(`Fetching details for ${itemAlias}`);
                        setTimeout(() => {
                            this.$loader.hide();
                        }, 40000);
                    }
                    // Perform actions based on the updated data
                    // this.handleDocumentUpdate(data);
                } else {
                    console.log('No such document!');
                }
            }, (error) => {
                console.log('Error listening to document:', error);
            });
        },
        setDefaultPlatSetting(itemAlias) {
            this.platforms = this.platforms.map(platform => {
                if (platform.id === itemAlias) {
                    return {
                        ...platform,
                        picture: '',
                        user_name: '',
                        isConnected: false,
                        user_id: null,
                        pages: [],
                        selectedProfiles: itemAlias == "pinterest" || "tumblr"||"youtube" ? null : []
                    };
                }
                return platform;
            });
        },
        async manageConnection(profile) {
            if (profile.isConnected) {
                const docRef = doc(db, `fly_crossplatform_${this.primary_platform}`, `${this.uid}__default__${this.primary_platform}`);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const platforms = docSnap.data().platforms || [];
                    // Filter out the object with matching name
                    const updatedPlatforms = platforms.filter(platform => platform.name !== profile.id);
                    // Update the document with the filtered platforms array
                    await updateDoc(docRef, {
                        platforms: updatedPlatforms
                    });
                }
                this.disconnectConnection(profile.id, "personal");
                this.setDefaultPlatSetting(profile.id);

            }
            else {
                this.connectingHandle(profile.id, "personal");
                const docRef = doc(db, 'users', this.uid, 'fly_workspaces', 'default', 'connected-apps', profile.id);
                const unsubscribeConnection = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        // Document data available
                        const data = docSnapshot.data();
                        if (data.connection_status == 'connected') {
                            this.platforms.forEach((item) => {
                                if (item.id === profile.id) {
                                    item.isConnected = true;
                                }
                            });
                            this.newConnection = profile.id
                            unsubscribeConnection();
                        }
                        // Perform actions based on the updated data
                        // this.handleDocumentUpdate(data);
                    } else {
                        console.log('No such document!');
                    }
                }, (error) => {
                    console.log('Error listening to document:', error);
                });

            }
        },
    },
    async mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        console.log("The lenght before", this.hashtags.length);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    beforeMount() {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, "users", auth.currentUser.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    this.primary_platform = userDoc.data()?.lite_primary_platform ;
                    if(!this.primary_platform){
                        this.primary_platform = 'instagram'
                    }
                    this.temp_platform = this.primary_platform || 'instagram';
                    const userData = userDoc.data();
                    const currentDate = new Date();
                    const liteTrial = userData?.fly_trial_plan_valid_until?.toDate();
                    const checkTrial = liteTrial && liteTrial > currentDate;
                    if ((userData?.fly_lite_plan == 'trial' && checkTrial) || userData?.fly_lite_plan == 'annual') {
                        this.validPlan = true;
                        if (userData?.fly_lite_plan == 'annual') {
                            this.annualSubs = true;
                        }
                    }
                    else this.validPlan = false;
                }
                if (window.location.hash === '#subscription') {
                    setTimeout(() => {
                    const subscriptionSection = document.querySelector('.scroll-to-subs');
                    if (subscriptionSection) {
                        subscriptionSection.scrollIntoView({ behavior: 'smooth' });
                    }
                    }, 500);
                }
                this.fetchUserPhoneNumber();
                this.fetchUserContactInfo();
                this.fetchConnectedApps();
                this.showTestCrossPostBtn();
            }
            else {
                if (this.unsubscribeFetching) {
                    this.unsubscribeFetching();
                }
            }
        });
    },
};
</script>

<style scoped>
.linear-gradient {
    border-radius: 2rem;
    border: 2px solid #FB8C8C;
    background: linear-gradient(145deg, #FB8C8C 13.58%, #FF1D1D 86.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.flex-d {
    display: flex;
    flex-direction: column;
}

.flex-d1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fly-lite-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    border: 1px solid #E6E8EC;
    border-radius: 1rem;
    padding: 1rem 3rem;
    background: #FFF;
}

.gold-mid {
    background-color: #8056DD;
}

.gray-lighter {
    background-color: #CBD5E1;
}

.toggle-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 4.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    transition: all 0.4s ease;
}

@media (max-width: 700px) {
    .toggle-wrapper {
        width: 3.6rem;
        height: 1.7rem;
    }
}

@media (max-width: 320px) {
    .toggle-wrapper {
        width: 3rem;
        height: 1.75rem;
    }

    .fly-lite-setting {
        font-size: 1rem;
        padding: 0.75rem 2rem;
    }
}

.toggle-wrapper:focus {
    outline: 0;
}

.toggle-background {
    display: inline-block;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.4s ease;
}

.toggle-indicator {
    position: absolute;
    height: 2rem;
    width: 2rem;
    left: 0.3rem;
    bottom: 0.25rem;
    background-color: #ffffff;
    border-radius: 9999px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease;
}

@media (max-width: 700px) {
    .toggle-indicator {
        height: 1.3rem;
        width: 1.3rem;
        left: 0.2rem;
    }
}

@media (max-width: 320px) {
    .toggle-indicator {
        height: 1.5rem;
        width: 1.5rem;
        left: 0rem;
    }
}

.flylite-connected {
    width: 14rem;
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid rgb(255 103 103);
    color: rgb(255 103 103);
}

.flylite-update {
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid rgb(5, 151, 0);
    color: rgb(5, 151, 0);
}

.flylite-not-connected {
    width: 14rem;
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid #9abcf5;
    color: #0847b5;
}

.plat-icon {
    background: white;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    /* Adjust as per your design */
    right: -5px;
    /* Adjust as per your design */
    z-index: 1;
    /* Ensure it appears above the rounded div */

}

.green-check {
    background-color: #00C853;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* Ensure it's above other elements */
}

.green-check v-icon {
    font-size: 1rem;
    /* Adjust the size of the check icon */
}

.disabled {
    pointer-events: none;
    /* Prevent interaction */
    position: relative;
    opacity: 0.4;
    z-index: 1;
    /* Make it look disabled */
}

.disabled-card {
    /* Prevent interaction */
    position: relative;
    opacity: 0.5;
    z-index: 1;
    /* Make it look disabled */
}

.disabled_class {
    z-index: 50;
    opacity: 100 !important;
    /* Add a semi-transparent white background */
    /* Ensure it appears on top */
}

.selected-card {
    border: 2px solid #8056DD;
    /* Add a border to highlight the selected card */
    transform: scale(1.05);
    /* Slightly scale up the selected card */
    transition: transform 0.2s ease, border 0.2s ease;
}

.selected-card:hover {
    cursor: pointer;
    /* Make it clear the card is clickable */
}

.bg-white {
    transition: transform 0.2s ease, border 0.2s ease;
}

.combobox-container {
    max-width: 14rem;
    /* Set a max width for the combobox */
    /* overflow: hidden; */
    white-space: nowrap;
    /* Prevent overflow text */
    text-overflow: ellipsis;
    /* Add ellipsis for overflow text */
}

.v-combobox {
    max-width: 100%;
    /* Ensure the combobox does not overflow the div */
}

.combobox-container .v-input__control {
    max-width: 100%;
    /* Prevent the input control from overflowing */
}

.disabled_class {
    z-index: 10;
    opacity: 1;
    /* Ensure this text has full opacity */
}

.flex-d1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chip {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 10px;
    /* Add spacing below the chip */
    white-space: nowrap;
    /* Ensure the chip does not break */
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .combobox-container {
        max-width: 12rem;
        /* Adjust width for smaller screens */
    }
}

@media (max-width: 480px) {
    .combobox-container {
        max-width: 10rem;
        /* Further adjustment for very small screens */
    }
}

.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    /* Ensure it's above other elements */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-btn {
    font-size: 1rem;
    /* Default for large screens */
}

@media (max-width: 1024px) {

    /* For tablets */
    .floating-btn {
        font-size: 0.9rem;
    }
}


@media (max-width: 640px) {

    /* For smaller devices */
    .floating-btn {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {

    /* For phones */
    .floating-btn {
        font-size: 0.8rem;
    }
}


@media (max-width: 768px) {
    .floating-btn {
        bottom: 15px;
        right: 15px;
        width: auto;
        padding: 10px 20px;
    }
}
</style>
